import { Component } from '@angular/core'
import { ControlWidget } from '@delon/form'

@Component({
  selector: 'app-sf-goods-select',
  templateUrl: './goods-select.component.html',
  styleUrls: ['./goods-select.component.less']
})
export class GoodsSelectComponent extends ControlWidget {

// reset 可以更好的解决表单重置过程中所需要的新数据问题
  reset(value: string) {
    this.setValue(value)
  }

  change(value: string) {
    if (this.ui.change) {
      this.ui.change(value)
    }
    this.setValue(value)
  }

}
