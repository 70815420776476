import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth'
import { AsyncCurrentUserService } from '@scaffold/core'
import { Auth } from '@scaffold/providers'
import { timer } from 'rxjs'

@Component({
  selector: 'layout-pro-user',
  templateUrl: 'user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutProWidgetUserComponent implements OnInit {
  logouting: boolean

  constructor(
    public currentUser: AsyncCurrentUserService,
    private router: Router,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private auth: Auth,
  ) {}

  ngOnInit(): void {
  }

  async logout() {
    this.logouting = true
    await this.auth.logout()
    await timer(300).toPromise()
    this.logouting = false
  }
}
