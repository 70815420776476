import { Component } from '@angular/core'
import { ControlWidget } from '@delon/form'

@Component({
  selector: 'read-only',
  template: `
    <sf-item-wrap
      [id]="id"
      [schema]="schema"
      [ui]="ui"
      [showError]="showError"
      [error]="error"
      [showTitle]="schema.title"
    >
      <span>{{value}}</span>
    </sf-item-wrap>
  `,
  preserveWhitespaces: false,
})
// tslint:disable-next-line: component-class-suffix
export class ReadOnlyWidget extends ControlWidget {
  static readonly KEY = 'rmb'

  _change(value: string) {
    this.setValue(value)
  }
}
