import { Injectable, Injector } from '@angular/core'
import { BaseApi, BaseUrl, DELETE, GET, Path, Payload, POST, PUT } from '@delon/theme'
import { IResult, IResultWithPagination, Model, PaginationParams } from '@public/interface'
import { TreeModel } from '@public/providers/http/http-user-role.service'
import { Observable } from 'rxjs'

@BaseUrl('/code')
@Injectable({
  providedIn: 'root'
})
export class HttpCodeService extends BaseApi {

  constructor(injector: Injector) {
    super(injector)
  }

  @GET('/all')
  getAll(@Payload params: CodeModel): Observable<IResult<CodeModel[]>> { return }

  @GET('/page')
  getAllByPage(@Payload body: CodeModel & PaginationParams): Observable<IResultWithPagination<CodeModel>> { return }

  @GET('/:id')
  getById(@Path('id')id: string): Observable<IResult<CodeModel>> { return }

  @POST()
  add(@Payload body: CodeModel): Observable<IResult<CodeModel>> { return }

  @PUT()
  edit(@Payload body: CodeModel): Observable<IResult<CodeModel>> { return }

  @DELETE(':id')
  remove(@Path('id') id: string, @Payload indexId: string): Observable<IResult<CodeModel>> { return }

  @PUT('/remove')
  financialRemove(@Payload body: FinancialRemoveModel): Observable<IResult<FinancialRemoveModel>> { return }

}

export interface FinancialRemoveModel extends Model {

  id?: string

  indexId?: string

  orderNo?: number
}

export interface CodeModel extends TreeModel {
  /** 索引ID */
  indexId?: string

  /** 字典项名称 */
  name?: string

  /** 序号 */
  orderNo?: number

  /** 父ID */
  parentId?: number

  /** 拼音字头 */
  phoneticPrefix?: string

  /** 监所编号加密 */
  prisonsNo?: string

  /** 备注 */
  remarks?: string
}
