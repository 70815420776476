import { Injectable } from '@angular/core'
import { BaseApi, BaseUrl, Body, GET, Payload, PUT } from '@delon/theme'
import { IResult, Model, PaginationBody, PaginationParams } from '@public/interface'
import { Observable } from 'rxjs'
import { PrisonModel } from './http-prison.service'

@BaseUrl('/refundAccountApplication')
@Injectable({
  providedIn: 'root'
})
export class HttpRefundAccountApplication extends BaseApi {

  @GET('/page')
  getAllByPage(@Payload body): Observable<IResult<PaginationBody<RefundAccountApplicationModel>>> { return }

  @GET('/pageFinance')
  getAllByPageFinance(@Payload body): Observable<IResult<PaginationBody<RefundAccountApplicationModel>>> { return }

  @PUT()
  approve(@Body body): Observable<IResult> { return }

  @PUT('/financeConfirm')
  financeConfirm(@Body body): Observable<IResult> { return }

  @GET('prison/all')
  getPrison(@Payload body: PaginationParams): Observable<IResult<PrisonModel[]>> { return }
}

export interface RefundAccountApplicationModel extends Model {
  /** 申请人ID */
  accountId?: string

  /** 在押人姓名 */
  accountName?: string

  /** 审批人 */
  approver?: string

  /** 申请开始时间 */
  createBeginTime?: string

  /** 申请结束时间 */
  createEndTime?: string

  /** 申请时间 */
  createTime?: string

  /** id */
  id?: string

  /** 退款金额 */
  money?: string

  /** 审批意见 */
  opinions?: string

  /** 监区、监室 */
  prisonRoom?: string

  /** 监所编号 */
  prisonsNo?: string

  /** 申请理由 */
  reason?: string

  /** 状态 */
  state?: 0 | 1 | -1 | 3

  /** 收款押人ID */
  toAccountId?: string

  /** 审批时间 */
  updateTime?: string
}
