import { Component, forwardRef, Injector, Input } from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { SelectAsync } from '@public/components/select-async'
import { map } from 'rxjs/operators'
import { GoodsCategoryModel, HttpGoodCategoryService } from './goods-category.service'

@Component({
  selector: 'app-goods-category-selector',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => GoodsCategorySelectorComponent),
    multi: true
  }],
  styles: [`
    .option-prefix {
      min-width: 15px;
      text-align: center
    }

    .option-content {
      overflow: hidden;
      text-overflow: ellipsis;
    }`],
  template: `
    <nz-select class="d-inline-block" style="width: 100%;min-width: 120px"
               [nzDisabled]="disabled"
               [nzSize]="size"
               [(ngModel)]="value"
               (nzScrollToBottom)="loadMore()"
               nzPlaceHolder="请选择商品分类"
               nzDropdownMatchSelectWidth
               nzAllowClear>
      <nz-option *ngFor="let item of listOfOptions" [nzValue]="item.id" [nzLabel]="item.name" nzCustomContent>
        <div class="d-flex align-items-center">
          <span class="option-prefix"><i nz-icon [nzType]="item.icon || 'folder'" nzTheme="twotone"></i></span>
          <nz-divider nzType="vertical"></nz-divider>
          <span class="option-content flex-grow-1" [title]="item.name">{{item.name}}</span>
        </div>
      </nz-option>

      <nz-option *ngIf="loading" nzDisabled nzCustomContent>
        <i nz-icon nzType="loading" class="loading-icon"></i> 加载中...
      </nz-option>
    </nz-select>
  `,
})
export class GoodsCategorySelectorComponent extends SelectAsync<GoodsCategoryModel, GoodsCategoryModel> {

  queryParams: { type?, state? } = { state: 1 }

  /** 0所内购物，1家属商城 */
  @Input() set type(n: 0 | 1) {
    this.value = null
    this.queryParams.type = n
    this.clean()
    this.loadMore()
  }

  constructor(injector: Injector, protected httpGoodsCategory: HttpGoodCategoryService) {
    super(injector)
  }

  async getOptions(text: string): Promise<GoodsCategoryModel[]> {
    return this.httpGoodsCategory.query({ ...this.queryParams, ...this.pagination })
      .pipe(map(t => t.response?.records))
      .toPromise()
  }

}
