import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { CodeSelectComponent } from '@public/components/code-select/code-select.component'
import { NzDividerModule } from 'ng-zorro-antd/divider'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzSelectModule } from 'ng-zorro-antd/select'

@NgModule({
  declarations: [CodeSelectComponent],
  exports: [CodeSelectComponent],
  imports: [
    CommonModule,
    NzSelectModule,
    FormsModule,
    NzDividerModule,
    NzIconModule
  ]
})
export class CodeSelectModule {}
