import { Injectable, Injector } from '@angular/core'
import { BaseApi, BaseUrl, DELETE, GET, Path, Payload, POST, PUT, _HttpClient } from '@delon/theme'
import { IResult, IResultWithPagination, Model } from '@public/interface'
import { Observable } from 'rxjs'

/**
 * 律师表管理
 * Lawyer Manage Controller
 */
@BaseUrl('/user')
@Injectable({
  providedIn: 'root'
})
export class HttpWebLawyerUserService extends BaseApi {

  constructor(injector: Injector, protected http: _HttpClient) {
    super(injector)
  }
  @GET()
  getAllByPage(@Payload body: WebLawyerUserModel): Observable<IResultWithPagination<WebLawyerUserModel>> { return }

  @GET('/:id')
  getById(@Path('id') id: string): Observable<IResult<WebLawyerUserModel>> { return }

  @GET('/current')
  getCurrentUser(@Payload body: WebLawyerUserModel): Observable<IResult<WebLawyerUserModel>> { return }

  @POST()
  add(@Payload body: WebLawyerUserModel): Observable<IResult<WebLawyerUserModel>> { return }

  @PUT()
  edit(@Payload body: WebLawyerUserModel): Observable<IResult<WebLawyerUserModel>> { return }

  @DELETE('/:id')
  remove(@Path('id') id: string): Observable<IResult<WebLawyerUserModel>> { return }

  @PUT('/changePwd')
  changePwd(@Payload body: ChangePwdModel): Observable<IResult<ChangePwdModel>> { return }

}

export interface ChangePwdModel extends Model {
  // 原密码
  originPwd?: string

  // 新密码
  newPwd?: string
}

export interface WebLawyerUserModel extends Model {
  /** 律师帐号 */
  account?: string

  /** 律师个人详细地址 */
  address?: string

  /** 极光Id */
  auroraId?: string

  /** 工作地点，课绑定省市区任意一级 */
  cityCode?: string

  /** device_code 设备码 */
  deviceCode?: string

  /** 律师头像 */
  headImage?: string

  /** 证件号码 */
  idCard?: string

  /** 个人简介 */
  introduction?: string

  /** 律师资格证证号 */
  lawyerCard?: string

  /** 律师执业证号 */
  lawyerLicense?: string

  /** 律师职务 */
  lawyer_title?: string

  /** 邮箱 */
  mail?: string

  /** 名字 */
  name?: string

  /** 事务所ID */
  officeId?: string

  /** 密码 */
  password?: string

  /** 联系电话 */
  phone?: string

  /** 自定义配置 */
  profile?: string

  /** 认证状态：0：未认证；1：已认证；2被封号 */
  realState?: 0 | 1 | 2

  /** 角色ID */
  roleId?: string

  /** 性别 */
  sex?: number

  /** 状态，0为未启用，1为启用 */
  state?: 0 | 1

  /** 律师权重 */
  weight?: number

  /** 执业年数 */
  yearsPractice?: string

  /** 入驻年数 */
  yearsSettledIn?: string
}
