
<div class="alain-pro__header-item position-relative" nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click">
  <div class="alain-pro__header-item-icon mr-sm" >
    <svg class="anticon" role="img" width="18" height="18" viewBox="0 0 21 21" fill="currentColor">
      <g fill-rule="evenodd">
        <g fill-rule="nonzero">
          <path
            d="M7.02 3.635l12.518 12.518a1.863 1.863 0 010 2.635l-1.317 1.318a1.863 1.863 0 01-2.635 0L3.068 7.588A2.795 2.795 0 117.02 3.635zm2.09 14.428a.932.932 0 110 1.864.932.932 0 010-1.864zm-.043-9.747L7.75 9.635l9.154 9.153 1.318-1.317-9.154-9.155zM3.52 12.473c.514 0 .931.417.931.931v.932h.932a.932.932 0 110 1.864h-.932v.931a.932.932 0 01-1.863 0l-.001-.931h-.93a.932.932 0 010-1.864h.93v-.932c0-.514.418-.931.933-.931zm15.374-3.727a1.398 1.398 0 110 2.795 1.398 1.398 0 010-2.795zM4.385 4.953a.932.932 0 000 1.317l2.046 2.047L7.75 7 5.703 4.953a.932.932 0 00-1.318 0zM14.701.36a.932.932 0 01.931.932v.931h.932a.932.932 0 010 1.864h-.933l.001.932a.932.932 0 11-1.863 0l-.001-.932h-.93a.932.932 0 110-1.864h.93v-.931a.932.932 0 01.933-.932z"
          ></path>
        </g>
      </g>
    </svg>
  </div>

  <span>主题</span>

  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu nzSelectable>
      <li nz-menu-item (click)="onThemeChange('default')">默认主题</li>
      <li nz-menu-item (click)="onThemeChange('dark')">暗黑主题</li>
      <li nz-menu-item (click)="onThemeChange('compact')">紧凑模式</li>
    </ul>
  </nz-dropdown-menu>
</div>
