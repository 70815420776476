import { Component, forwardRef, OnInit } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.less'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ColorPickerComponent),
    multi: true
  }]
})
export class ColorPickerComponent implements OnInit, ControlValueAccessor {
  colorList = [
    { color: '#f5222d', text: '薄暮' },
    { color: '#fb541f', text: '火山' },
    { color: '#fa8d24', text: '日暮' },
    { color: '#faae2b', text: '金盏花' },
    { color: '#fadd36', text: '日出' },
    { color: '#9edb35', text: '青柠' },
    { color: '#4ec634', text: '极光绿' },
    { color: '#09c2c2', text: '明青' },
    { color: '#208dfc', text: '拂晓蓝' },
    { color: '#354ee7', text: '极客蓝' },
    { color: '#722ed1', text: '酱紫' },
    { color: '#eb2f96', text: '法式洋红' },
  ]

  get value() {
    return this._value
  }

  set value(value) {
    this._value = value
    if (typeof this._onChange === 'function') {
      this._onChange(value)
    }
  }

  private _value: string

  private _onChange: (value: string) => any

  constructor() { }

  ngOnInit(): void {
  }

  registerOnChange(fn: any): void {
    this._onChange = fn
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(value: any): void {
    this._value = value
  }
}
