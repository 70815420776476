import { Injectable, Injector } from '@angular/core'
import { BaseApi, BaseUrl, Body, GET, Path, Payload, POST, PUT, Query } from '@delon/theme'
import { Model, PaginationBody, PaginationParams } from '@public/interface'
import { IResult } from '@scaffold/interface/result.interface'
import { asyncCatch } from '@scaffold/methods'
import { Observable } from 'rxjs'

@BaseUrl('/account')
@Injectable({ providedIn: 'root' })
export class HttpAccount extends BaseApi {

  constructor(injector: Injector) { super(injector) }

  @GET('/page')
  getAllByPage(@Payload body: PaginationParams & AccountModel & { origin?: string })
    : Observable<IResult<PaginationBody<AccountModel>>> { return }

  @GET('/ydayBalance/list')
  getYdayBalance(@Payload body: any): Observable<IResult<any>> { return }

  @GET('/pageDayOutAccount')
  getDataAccountByPage(@Payload body: PaginationParams & AccountModel & { origin?: string })
    : Observable<IResult<PaginationBody<AccountModel>>> { return }

  @GET('/pageNew')
  getAllByPageNew(@Payload body: PaginationParams & AccountModel & { origin?: string })
    : Observable<IResult<PaginationBody<AccountModel>>> { return }

  @GET('/all')
  search(@Path('memberId') memberId: string)
    : Observable<IResult<PaginationBody<AccountModel>>> { return }

  @GET(':id')
  getById(@Path('id') id)
    : Observable<IResult<AccountModel>> { return }

  @GET('/getFamilyMembersAndAccountByAccountId')
  getFamilyMembersAndAccountByAccountId(@Query('id') id: number | string)
    : Observable<IResult<AccountModel>> { return }

  @POST('/addAccount')
  add(@Body prisoner: AccountModel): Observable<IResult<AccountModel>> { return }

  @POST('/addAccountList')
  addList(@Body prisoner: AccountModel[]): Observable<IResult<AccountModel>> { return }

  @PUT('/updateAccountInfo')
  editAccountInfo(@Body prisoner: AccountModel)
    : Observable<IResult<AccountModel>> { return }

  @PUT('/updateLimitAmountById')
  editLimitAccount(@Body prisoner: AccountModel)
    : Observable<IResult<AccountModel>> { return }

  async pullAllByPage(params) {
    const promise = this.getAllByPage(params).toPromise()
    const [res] = await asyncCatch(promise)
    if (!res || res.status !== 200 || !res.response) { return }
    return res.response
  }

  @POST('/bankOpenAccount')
  bankOpenAccount(@Body accountId: AccountModel): Observable<IResult<any>> { return }

  @GET('/getBalanceById/:accountId')
  getBalanceById(@Path('accountId') accountId: string): Observable<IResult<any>> { return }

  @GET('/getAllPersonBalanceExcel')
  getAllPersonBalanceExcel(@Body prisonsNo: string): Observable<IResult<any>> { return }

  @GET('/getAllPersonBalanceData')
  getAllPersonBalanceData(@Payload body: PaginationParams & PrisonNoModel): Observable<IResult<PaginationBody<PaginationParams>>> { return }

  @GET('/getBankDataByPrisonsNoOrTime')
  getBankDataByPrisonsNoOrTime(@Payload body: PaginationParams & BankDataStatisticsModel):
    Observable<IResult<PaginationBody<BankDataStatisticsModel>>> { return }


  @POST('/accountFinancialLock/edit')
  accountFinancialLock(@Body body: accountModel): Observable<IResult<accountModel>> { return }
}

export interface BankDataStatisticsModel extends Model {

  in?: string

  out?: string

  page?: {
    current?: number
    limit?: number
    offset?: number
    order?: string
    orders?: any[]
    pages?: number
    records?: any[]
    searchCount?: boolean
    size?: number
    sort?: string
    total?: number
  }
}

export interface PrisonNoModel extends Model {

  prisonsNo?: string
}

export interface AccountModel extends Model {
  /** 可用限制金额 */
  availableLimitAmount?: number

  /** 可用限制金额（生活类） */
  availableLimitAmountLife?: number

  /** 余额 */
  balance?: number

  /** 银行卡账号 */
  bankcardNo?: string

  /** 删除（拒收）时间 */
  deleteTime?: string

  /** 人员标记 */
  flag?: number

  financialLockStatus?: number

  /** 冻结金额 */
  freezingAmount?: number

  /** 身份证号 */
  idcardNo?: string

  /** 限制金额 */
  limitAmount?: number

  /** 诉讼阶段 */
  litigiousStage?: string

  /** 诉讼阶段中文 */
  litigiousStageInfo?: string

  /** 姓名 */
  name?: string

  /** 出所时间 */
  outTime?: string

  /** 拼音字头 */
  phoneticPrefix?: string

  /** 监室编号 */
  prisonRoom?: string

  /** 监室编号中文 */
  prisonRoomInfo?: string

  /** 监所编号 */
  prisonsNo?: string

  /** 监所编号中文 */
  prisonInfo?: string

  /** 记录编号 */
  recordId?: string

  /** 开户状态 */
  state?: number

  /** 家属信息 */
  list?: FamilyModel[]

  isAllowPurchase?


}

export interface FamilyModel extends Model {
  accountApproveState?: number
  accountApproveStateInfo?: string
  accountApproveTime?: string
  accountId?: string
  accountName?: string
  bankCardlist: IDCardModel[]
  idcardNo?: string
  isSelect: true
  memberId?: string
  memberIdcardNo?: string
  memberName?: string
  memberSexInfo?: string
  opinions?: string
  phoneNumber?: string
  prisonRoom?: string
  prisonRoomInfo?: string
  prisonsApproveState?: number
  prisonsApproveStateInfo?: string
  prisonsApproveTime?: string
  prisonsApprover?: string
  prisonsNo?: string
  relationship?: string
  relationshipInfo?: string
}

export interface IDCardModel extends Model {
  bank?: string
  cardholder?: string
  creditCardNumbers?: string
  memberId?: string
  phone?: string
  top: 1
}

export interface accountModel extends Model {
  id?: string
  financialLockStatus?: number
  familyTransferLockFlag?: number
}
