import { NgModule } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { HeaderStorageComponent } from './storage.component'

@NgModule({
  imports: [NzIconModule, TranslateModule],
  providers: [],
  declarations: [HeaderStorageComponent],
  exports: [HeaderStorageComponent]
})
export class StorageModule {}
