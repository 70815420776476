import { Inject, Injectable } from '@angular/core'
import { MenuService as MenuSrv, _HttpClient } from '@delon/theme'
import { Menu } from '@delon/theme/src/services/menu/interface'
import { tap } from 'rxjs/operators'
import { ENVIRONMENTS } from 'yqs-environments'

@Injectable({ providedIn: 'root' })
export class AsyncMenu {

  constructor(
    public menuService: MenuSrv,
    @Inject(ENVIRONMENTS) public env,
    protected http: _HttpClient,
  ) { }

  sync(url = 'assets/data/menus.json') {
    return this.http
      .get(url, { _quiet_: '1', _local_: 1 })
      .pipe(tap(({ menus: children }) => {
        const menus: Menu[] = [{ text: '菜单', hideInBreadcrumb: true, group: true, children, }]
        this.setMenus(menus)
      }))
  }

  setMenus(menus) {
    this.menuService.clear()
    this.menuService.add(menus)
  }

  clear() {
    this.menuService.clear()
  }
}
