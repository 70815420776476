import { Injectable, Injector } from '@angular/core'
import { BaseApi, BaseUrl, DELETE, GET, Path, Payload, POST, PUT, Query } from '@delon/theme'
import { IResult, IResultWithPagination, Model } from '@public/interface'
import { Observable } from 'rxjs'

@BaseUrl('/user')
@Injectable({
  providedIn: 'root'
})
export class HttpMenuService extends BaseApi {

  constructor(injector: Injector) { super(injector) }

  @GET('/menu/tree')
  getAllByTree(@Payload body: MenuModel & any): Observable<IResult<MenuModel>> { return }

  @GET('/menuNew/:id')
  getById(@Path('id') id: string | number): Observable<IResultWithPagination<MenuModel>> { return }

  @POST('/menuNew')
  add(@Payload body: MenuModel): Observable<IResult<MenuModel>> { return }

  @PUT('/menuNew')
  edit(@Payload body: MenuModel): Observable<IResult<MenuModel>> { return }

  @DELETE('/menuNew/:id')
  remove(@Path('id') id: string): Observable<IResult<MenuModel>> { return }

  @GET('/menu/tree/current')
  getTreeByCurrenUser(@Payload params?: { appId?, id?, [key: string]: any }): Observable<IResult<MenuModel>> { return }

  @GET('/menu/current')
  getMenusByCurrenUser(@Payload params?: { appId?, [key: string]: any }): Observable<IResult<MenuModel>> { return }

  /** 审批人查询 getUserByRoleCode */
  @GET('/getUserByRoleCode')
  getUserByRoleCode(@Payload body: { code: string }): Observable<IResult<any>> { return }
}

export interface MenuModel extends Model {
  /** 英文编码 */
  code?: string

  /** 图标图片 */
  icon?: string

  /** 等级 */
  level?: number

  /** 菜单名称 */
  name?: string

  /** 上级id，8大块系统为1级，然后每大块的主菜单为2级，然后子菜单为3级 */
  parentId?: string

  /** 备注 */
  remark?: string

  /** 排序 */
  sortOrder?: number

  /** 类型：0菜单，1按钮 */
  menuType?: number

  /** 链接 */
  url?: string

  /** 所属模块：所内网站：1，商家供货商网站：2，家属app：3，律师app：4， */
  appId?: number

  rights?: string

  children?: MenuModel[],

  expand?: boolean,

  parent?: MenuModel
}
