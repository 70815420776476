import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { NoticeIconModule } from '@delon/abc/notice-icon'
import { LayoutProWidgetNotifyComponent } from './notify.component'

@NgModule({
  declarations: [LayoutProWidgetNotifyComponent],
  exports: [LayoutProWidgetNotifyComponent],
  imports: [
    CommonModule,
    NoticeIconModule,
  ],
})
export class NotifyModule {}
