<i nz-icon nzType="search"></i>
<div class="alain-pro__header-search-input ant-select-auto-complete ant-select">
  <input
    #ipt
    [placeholder]="'menu.search.placeholder' | translate"
    nz-input
    [nzAutocomplete]="searchAuto"
    [(ngModel)]="q"
    (input)="search$.next($event.target?.value)"
    (blur)="show = false"
  />
</div>
<nz-autocomplete #searchAuto>
  <nz-auto-option *ngFor="let item of list" [nzValue]="item.no">
    {{ item.no }}
  </nz-auto-option>
</nz-autocomplete>
