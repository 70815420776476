import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { TScrollbarModule } from '@scaffold/components/scrollbar'
import { NzBadgeModule } from 'ng-zorro-antd/badge'
import { NzDropDownModule } from 'ng-zorro-antd/dropdown'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'
import { QuickChatStatusComponent } from './quick-chat-status.component'
import { QuickChatComponent } from './quick-chat.component'

@NgModule({
  declarations: [QuickChatComponent, QuickChatStatusComponent],
  exports: [QuickChatComponent, QuickChatStatusComponent],
  imports: [
    CommonModule,
    NzToolTipModule,
    NzBadgeModule,
    NzIconModule,
    NzDropDownModule,
    TScrollbarModule,
    FormsModule,
  ],
})
export class TQuickChatModule {
}
