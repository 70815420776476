<div nz-dropdown [nzDropdownMenu]="langMenu" [nzPlacement]="placement" [ngClass]="btnClass">
  <i nz-icon nzType="global" [ngClass]="btnIconClass"></i>
</div>
<nz-dropdown-menu #langMenu="nzDropdownMenu">
  <div nz-menu class="width-sm">
    <li
      nz-menu-item
      *ngFor="let item of langs"
      [nzSelected]="item.code === settings.layout.lang"
      (click)="change(item.code)"
    >
      <span role="img" [attr.aria-label]="item.text">{{ item.abbr }}</span> {{ item.text }}
    </li>
  </div>
</nz-dropdown-menu>
