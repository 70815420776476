<nz-select
  style="width: 100%;min-width: 170px;"
  [(ngModel)]="value"
  nzPlaceHolder="请输入"
  nzAllowClear
  nzShowSearch
  nzNoAnimation
  [nzServerSearch]="true"
  [nzDropdownMatchSelectWidth]="false"
  [nzDropdownStyle]="{width:'280px'}"
  (nzScrollToBottom)="loadMore()"
  (nzFocus)="handleFocus()"
>
  <ng-container *ngFor="let item of listData">
    <nz-option nzCustomContent [nzValue]="item.name" [nzLabel]="item.name">
      <div class="d-flex align-items-center">
        <div>
          <ellipsis length="8">{{item.name}}</ellipsis>
        </div>
      </div>
    </nz-option>
  </ng-container>
  <nz-option *ngIf="loading" nzDisabled nzCustomContent>
    <i nz-icon nzType="loading" class="loading-icon"></i> 加载中，请稍候...
  </nz-option>
</nz-select>
