import { Component } from '@angular/core'
import { ControlWidget } from '@delon/form'

@Component({
  selector: 'app-financial-running-water-roundup',
  templateUrl: './financial-running-water-roundup.component.html'
})

export class FinancialRunningWaterRoundupComponent extends ControlWidget {

  // reset 可以更好的解决表单重置过程中所需要的新数据问题
  reset(value: string) {
    this.setValue(value)
  }
  modalChange(value: string) {
    if (this.ui.change) {
      this.ui.change(value)
    }
    this.setValue(value)
  }
}
