import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { Nz2UploadModule } from '@scaffold/components/upload'
import { NzEmptyModule } from 'ng-zorro-antd/empty'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzModalModule } from 'ng-zorro-antd/modal'
import { ImageUploadComponent } from './image-upload.component'
import { ImageComponent } from './image.component'
import { ImagePipe } from './image.pipe'

@NgModule({
  declarations: [ImageUploadComponent, ImageComponent, ImagePipe],
  exports: [ImageUploadComponent, ImageComponent, ImagePipe],
  imports: [
    CommonModule,
    NzIconModule,
    NzEmptyModule,
    NzModalModule,
    Nz2UploadModule
  ],
})
export class ImageUploadModule {}
