import { Inject, Injectable, Injector } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth'
import { BaseApi, GET, Payload, POST, Query, _HttpClient } from '@delon/theme'
import { AlainConfig, ALAIN_CONFIG } from '@delon/util'
import { Debounce } from '@public/methods/decorators-util'
import { AsyncCurrentUserService } from '@scaffold/core/startup/async/async-current-user.service'
import { IResult } from '@scaffold/interface'
import { asyncCatch, isResultFaild, log } from '@scaffold/methods'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { ENVIRONMENTS } from 'yqs-environments'
import { authBase64Encode } from './auth-base'

@Injectable()
export class Auth extends BaseApi {

  constructor(public http: _HttpClient,
    public router: Router,
    private acRoute: ActivatedRoute,
    public currentUserService: AsyncCurrentUserService,
    public injector: Injector,
    @Inject(DA_SERVICE_TOKEN) public tokenService: ITokenService,
    @Inject(ENVIRONMENTS) public env,
    @Inject(ALAIN_CONFIG) private config: AlainConfig
  ) {
    super(injector)
  }

  async login({ username, password, authCode }: LoginBody): Promise<IResponseLogin> {
    this.http.end()
    const appid = this.env.APPID
    const yunqishang = authBase64Encode(username, password, appid, authCode)

    const promise = this._login({ yunqishang }).toPromise()
    const [res] = await asyncCatch(promise)

    if (isResultFaild(res)) {
      console.warn('登录失败', res)
      return res
    }
    // 重新获取 currentUserService 内容，我们始终认为应用信息一般都会受当前用户授权范围而影响
    log('开始 --> 重新获取 currentUserService 内容')
    await this.currentUserService.sync()
    return res
  }

  async getAuthCode() {
    const promise = this.validateGetCode({}).toPromise()
    const [res] = await asyncCatch(promise)
    return res
  }

  isLogin() {
    return !!this.tokenService.get('token')
  }

  @Debounce(500)
  async logout() {
    if (location.href.includes(this.config.auth.login_url)) {
      console.warn('己登出')
      return
    }
    await asyncCatch(
      this._logout()
        .pipe(tap((res) => console.log('服务登出成功', res)))
        .toPromise()
    )
    this.currentUserService.clean()
    await this.router.navigateByUrl('/passport/login')

    return
  }

  @POST('/login?_allow_anonymous=true')
  private _login(@Payload data: { yunqishang: string }): Observable<IResult> {
    return
  }

  @GET('/validate/getCode?_allow_anonymous=true')
  private validateGetCode(@Payload data: any): Observable<any> {
    return
  }

  @POST('/logout')
  private _logout(
    @Query('_allow_anonymous') allow = 1,
    @Query('_quiet_') quiet = 1
  ): Observable<IResult> {
    return
  }
}

/**
 * 登录请求 Body
 */
export interface LoginBody {
  username: string
  password: string
  authCode: string
}

/**
 * 登录返回体
 */
export type IResponseLogin = IResult<{
  [key: string]: any
  token: string
}>
