import { Injectable } from '@angular/core'
import { BaseApi, BaseUrl, Body, GET, Payload, POST, PUT } from '@delon/theme'
import { IResult, Model, PaginationBody, PaginationParams } from '@public/interface'
import { Observable } from 'rxjs'
import { PrisonModel } from './http-prison.service'

@BaseUrl('/limitAmountApplication')
@Injectable({ providedIn: 'root' })
export class HttpLimitAmountApplication extends BaseApi {
  @GET('/page')
  getAllByPage(@Payload body): Observable<IResult<PaginationBody<ILimitAmountApplicationModel>>> { return }

  @PUT('/approve')
  approve(@Body body): Observable<IResult> { return }

  @GET('prison/all')
  getPrison(@Payload body: PaginationParams): Observable<IResult<PrisonModel[]>> { return }

  @POST('')
  add(@Body body): Observable<IResult<ILimitAmountApplicationModel>> { return }
}

export interface ILimitAmountApplicationModel extends Model {
  /** 关联在押人id */
  accountId?: string

  /** 审批状态【0：待审批，1：同意，-1：拒绝】 */
  approveState?: 0 | 1 | -1

  /** 审批时间 */
  approveTime?: string

  /** 审批人 */
  approver?: string

  /** 申请时间 */
  createTime?: string

  /** 主键 */
  id?: string

  /** 0有效，1无效 */
  isDel?: 0 | 1

  /** 限制金额 */
  limitAmount?: string

  /** 申请人 */
  proposer?: string

  /** 申请理由 */
  reason?: string

  /** 修改时间 */
  updateTime?: string

  /** 监所编号 */
  prisonsNo?: string

  /** 审批意见 */
  opinions?: string

  /** 申请开始时间 */
  applyBeginTime?: string

  /** 申请结束时间 */
  applyEndTime?: string

  /** 申请结束时间 */
  accountName?: string
}
