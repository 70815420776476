import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { NzDividerModule } from 'ng-zorro-antd/divider'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { JshSelectComponent } from './jsh-select.component'

@NgModule({
  declarations: [JshSelectComponent],
  exports: [
    JshSelectComponent
  ],
  imports: [
    CommonModule,
    NzSelectModule,
    FormsModule,
    NzDividerModule,
    NzIconModule
  ]
})
export class JshSelectModule {}
