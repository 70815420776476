<div (click)="createTplModal(tplContent)">
  <i class="mr-sm" nz-icon nzTheme="outline" nzType="lock"></i>
  {{'app.login.change-password' | translate}}
</div>

<ng-template #tplContent>
  <form (ngSubmit)="submit()" *ngIf="!loginDone;else loginDoneTpl" [formGroup]="form" nz-form role="form">
    <nz-alert *ngIf="error" [nzMessage]="error" [nzShowIcon]="true" [nzType]="'error'" class="mb-lg"></nz-alert>
    <nz-form-item>
      <nz-form-control [nzErrorTip]="'validation.password.required' | translate">
        <nz-input-group nzPrefixIcon="lock" nzSize="large">
          <input formControlName="originPwd" nz-input placeholder="请输入原密码" type="password"/>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control>
        <nz-input-group nzPrefixIcon="lock" nzSize="large">
          <input formControlName="newPwd" nz-input placeholder="请输入新密码" type="password"/>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control>
        <nz-input-group nzPrefixIcon="lock" nzSize="large">
          <input formControlName="newConfirmPwd" nz-input placeholder="请确认新密码" type="password"/>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <button [nzLoading]="tplModalButtonLoading" nz-button nzBlock nzSize="large" nzType="primary" type="submit">
        <i nz-icon nzTheme="outline" nzType="logout"></i>
        确认修改
      </button>
    </nz-form-item>
  </form>
</ng-template>

<ng-template #loginDoneTpl>
  <nz-result nzStatus="success" nzSubTitle="正在跳转，请稍等..." nzTitle="修改成功"></nz-result>
</ng-template>

