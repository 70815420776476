import { Injectable } from '@angular/core'
import { merge } from 'lodash'
import { ModalOptions, NzModalService } from 'ng-zorro-antd/modal'
import { Observable, Observer } from 'rxjs'

export type ModalSize = 'sm' | 'md' | 'lg' | 'xl' | '' | number

export interface ModalHelperOptions {
  /** 大小；例如：lg、600，默认：`lg` */
  size?: ModalSize
  /** 对话框 [ModalOptions](https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/modal/modal-types.ts) 参数 */
  modalOptions?: ModalOptions
  /** 是否精准（默认：`true`），若返回值非空值（`null`或`undefined`）视为成功，否则视为错误 */
  exact?: boolean
  /** 是否包裹标签页，修复模态包含标签间距问题 */
  includeTabs?: boolean
}

/**
 * 对话框辅助类
 */
@Injectable({ providedIn: 'root' })
export class ModalService {
  constructor(private modal: NzModalService) { }

  /**
   * 构建一个对话框
   *
   * @param title 标题
   * @param comp 组件
   * @param params 组件参数
   * @param options 额外参数
   *
   * @example
   * this.modalHelper.create(FormEditComponent, { i }).subscribe(res => this.load());
   * // 对于组件的成功&关闭的处理说明
   * // 成功
   * this.NzModalRef.close(data);
   * this.NzModalRef.close();
   * // 关闭
   * this.NzModalRef.destroy();
   */
  create(title: string, comp: any, params?: any, options?: ModalHelperOptions): Observable<any> {
    const modalOptions: ModalOptions = { ...options?.modalOptions, nzTitle: title }
    return this._create(comp, params, { ...options, modalOptions })
  }

  /**
   * 构建静态框，点击蒙层不允许关闭
   *
   * @param title 标题
   * @param comp 组件
   * @param params 组件参数
   * @param options 额外参数
   *
   * @example
   * this.modalHelper.open(FormEditComponent, { i }).subscribe(res => this.load());
   * // 对于组件的成功&关闭的处理说明
   * // 成功
   * this.NzModalRef.close(data);
   * this.NzModalRef.close();
   * // 关闭
   * this.NzModalRef.destroy();
   */
  createStatic(title: string, comp: any, params?: any, options?: ModalHelperOptions): Observable<any> {
    const modalOptions: ModalOptions = { ...options?.modalOptions, nzTitle: title }
    return this._create(comp, params, { ...options, modalOptions })
  }

  /**
   * 打开对话框
   * @param title 标题
   * @param comp 组件
   * @param params 组件参数
   * @param options NzModal 配置
   * @example
   * this.modalHelper.open(FormEditComponent, { i }).subscribe(res => this.load());
   * // 对于组件的成功&关闭的处理说明
   * // 成功
   * this.NzModalRef.close(data);
   * this.NzModalRef.close();
   * // 关闭
   * this.NzModalRef.destroy();
   */
  open(title: string, comp: any, params?: any, options?: ModalOptions): Observable<any> {
    return this.create(title, comp, params, {
      modalOptions: options,
      exact: false,
    })
  }

  /**
   * 静态框，点击蒙层不允许关闭
   * @param title 标题
   * @param comp 组件
   * @param params 组件参数
   * @param options NzModal 配置
   *
   * @example
   * this.modalHelper.open(FormEditComponent, { i }).subscribe(res => this.load());
   * // 对于组件的成功&关闭的处理说明
   * // 成功
   * this.NzModalRef.close(data);
   * this.NzModalRef.close();
   * // 关闭
   * this.NzModalRef.destroy();
   */
  static(title: string, comp: any, params?: any, options?: ModalOptions): Observable<any> {
    return this.open(title, comp, params, {
      nzMaskClosable: false,
      ...options,
    })
  }

  private _create(comp: any, params?: any, options?: ModalHelperOptions): Observable<any> {
    options = merge(
      {
        size: 'lg',
        exact: true,
        includeTabs: false,
      },
      options,
    )
    return new Observable((observer: Observer<any>) => {
      const { size, includeTabs, modalOptions } = options as ModalHelperOptions
      let cls = ''
      let width = ''
      if (size) {
        if (typeof size === 'number') {
          width = `${size}px`
        } else {
          cls = `modal-${size}`
        }
      }
      if (includeTabs) {
        cls += ' modal-include-tabs'
      }
      if (modalOptions && modalOptions.nzWrapClassName) {
        cls += ` ${modalOptions.nzWrapClassName}`
        delete modalOptions.nzWrapClassName
      }
      const defaultOptions: ModalOptions = {
        nzWrapClassName: (width || modalOptions?.nzWidth) ? 'modal-default' : cls,
        nzContent: comp,
        nzWidth: width ? width : undefined,
        nzFooter: null,
        nzComponentParams: params,
      }
      const nzModalOptions = { ...defaultOptions, ...modalOptions }
      const subject = this.modal.create(nzModalOptions)
      const afterClose$ = subject.afterClose.subscribe((res: any) => {
        if (options.exact === true) {
          if (res != null) {
            observer.next(res)
          }
        } else {
          observer.next(res)
        }
        observer.complete()
        afterClose$.unsubscribe()
      })
    })
  }
}
