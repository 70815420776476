export function greetingTime(date: Date | string = new Date()) {
  const dt = new Date(date)
  const hours = dt.getHours()
  if (hours < 12) {
    return '早上好'
  } else if (hours === 12) {
    return '中午好'
  } else if (hours > 12 && hours < 17) {
    return '下午好'
  } else if (hours > 17 && hours < 19) {
    return '晚上好'
  } else {
    return '晚安'
  }
}
