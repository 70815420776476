import { Injectable, Injector } from '@angular/core'
import { BaseApi, BaseUrl, GET, Path, Payload, POST, PUT, Query, _HttpClient } from '@delon/theme'
import { IResult, Model, PaginationBody, PaginationParams } from '@public/interface'
import { FinanceModel } from '@public/providers/http/http-finance.service'
import { PrisonRoomModel } from '@public/providers/http/http-prison-room.service'
import { Observable } from 'rxjs'

@BaseUrl('/store')
@Injectable({
  providedIn: 'root'
})
export class HttpStore extends BaseApi {

  constructor(injector: Injector, protected http: _HttpClient) {
    super(injector)
  }

  @GET('/all')
  getAllMarket(@Payload body: PaginationParams & PrisonRoomModel)
    : Observable<PrisonRoomModel> { return }

  @GET('/:id')
  getById(@Path('id') id: string): Observable<IResult<PaginationBody<OrderModel>>> { return }

  @GET('/shoppingFeedback')
  getShopingFeedbackByPage(@Payload body): Observable<IResult<PaginationBody<ShopingFeedbackOrderModel>>> { return }

  @PUT('/confirmShipmentShoppingFeedback/:serialNumber')
  confirmShipmentShoppingFeedback(@Path('serialNumber') serialNumber): Observable<IResult> { return }

  @PUT('/confirmShipmentShoppingFamilyFeedback/:serialNumber')
  confirmShipmentShoppingFamilyFeedback(@Path('serialNumber') serialNumber): Observable<IResult> { return }

  @GET('/page')
  getAllByPage(@Payload body): Observable<IResult<PaginationBody<OrderModel>>> { return }

  @PUT('/confirmShipment/:serialNumber')
  agree(@Path('serialNumber') serialNumber: string): Observable<IResult> { return }

  @GET('/getNoConfirmBySerialNumber/:serialNumber')
  getNoConfirmBySerialNumber(@Path('serialNumber') serialNumber: string): Observable<IResult> { return }

  @GET('/getBigFinanceNoConfirmBySerialNumber/:serialNumber')
  getBigFinanceNoConfirmBySerialNumber(@Path('serialNumber') serialNumber: string): Observable<IResult> { return }

  /** 所内财务确认收物 雷 */
  @PUT('/financeConfirmDelivery/:serialNumber')
  financeConfirmDelivery(@Path('serialNumber') serialNumber): Observable<IResult> { return }

  /** 支队财务确认收物 付款到商家 雷 */
  @PUT('/bigFinanceConfirmDelivery/:serialNumber')
  bigFinanceConfirmDelivery(@Path('serialNumber') serialNumber): Observable<IResult> { return }

  // 2020-07-16 管教拒绝家属购物
  @POST('/toStatistics')
  toStatistics(): Observable<IResult> { return }

  /** 获取开关状态 */
  @GET('/getStoreOrderSumFlag')
  getStoreOrderSumFlag(): Observable<IResult> { return }

  /** 更新开关状态 */
  @PUT('/updateStoreOrderSumFlag/:state')
  updateStoreOrderSumFlag(@Path('state') state: 0 | 1): Observable<IResult> { return }

  /** 生成超市打包订单 */
  @PUT('/GeneratedOrder')
  generatedOrder(): Observable<IResult> { return }

  /** 后勤发货家属确定订单 */
  @PUT('/logisticsDeliveryFamily')
  LogisticsOrderFamilyConfirm(): Observable<IResult> { return }

  /** 后勤发货所内确定订单 */
  logisticsDeliveryForPrison(serialNumbers): Observable<IResult> {
    return this.http.put('/store/logisticsDeliveryForPrison', { serialNumber: serialNumbers }) as Observable<IResult>
  }

  /** 管教 家属购物收货 */
  @PUT('/logisticsDisciplineDeliveryFamily')
  logisticsDisciplineDeliveryFamily(): Observable<IResult<FinanceModel>> { return }

  @POST('/familyRefund/edit')
  refundFlag(@Payload body: any): Observable<IResult> { return }

  @GET('/familyRefund/list')
  familyRefund(@Payload body: any): Observable<IResult<PaginationBody<any>>> { return }


  @GET('/familyRefund/marketGetList')
  marketGetList(@Payload body: any): Observable<IResult<PaginationBody<any>>> { return }

  @POST('/familyRefund/marketConfirm')
  marketConfirm(@Payload body: any): Observable<IResult> { return }
}

export interface OrderModel extends Model {

  /** 发货时间 */
  deliveryTime?: string

  /** 发货状态 0未发，1已发 */
  deliveryType?: number

  /** 商品id */
  goodId?: string

  /** 名字 */
  goodName?: string

  /** 单价 */
  goodPrice?: number

  /** 数量 */
  num?: number

  /** 收货单位 */
  prisonsNo?: string

  /** 统计时间 */
  statisticsTime?: string

  /** 商家id */
  storeId?: string
}

export interface SerialNumbersModel extends Model {
  /** 大财务 */
  bigFinanceConfirm?: number
  bigFinanceConfirmTime?: string
  bigFinanceName?: string
  createTime?: string

  /** 发货确认人 */
  deliveryConfirmedPerson?: string
  /** 收货人 */
  deliveryName?: string
  deliveryTime?: string
  deliveryType: number
  /** 财务确认 */
  financeConfirm: number
  financeConfirmTime?: string
  financeName?: string
  goodId?: string
  goodName?: string
  goodPrice?: string
  id?: string
  imgs?: string
  /** 家属是否付款 */
  isPay?: 0 | 1
  /** 商家是否付款 */
  isPayForStore: number
  num?: string
  orderFrom?: string
  /** 监管付款 */
  payForPrisons: number
  payLines?: string
  pid: number
  prisonRoom?: string
  prisonRoomName?: string
  prisonsName?: string
  prisonsNo?: string
  /** 内勤确认人 */
  receiptConfirmedPerson?: string
  receiptTime?: string
  serialNumber?: string
  sort?: string
  statisticsTime?: string
  storeDeliveryDOList?: string
  storeId?: string
  /** 总价 */
  total?: number
  unit?: string
  updateTime?: string
}

export interface SuperMarketModel extends Model {
  account?: string
  address?: string
  bankcardNo?: number
  info?: string
  num?: number
  logo?: string
  name?: string
  remark?: string
  password?: string
  tel?: string
}

export interface ShopingFeedbackOrderModel extends Model {
  bigFinanceConfirm: number
  bigFinanceConfirmTime: string
  bigFinanceName: string
  createTime: string
  deliveryConfirmedPerson: string
  deliveryName: string
  deliveryTime: string
  deliveryType: number
  financeConfirm: number
  financeConfirmTime: string
  financeName: string
  goodId: string
  goodName: string
  goodPrice: string
  imgs: string
  isPay: number
  isPayForStore: number
  num: string
  orderFrom: string
  payForPrisons: number
  payLines: string
  pid: number
  prisonRoom: string
  prisonRoomName: string
  prisonsName: string
  prisonsNo: string
  receiptConfirmedPerson: string
  receiptTime: string
  serialNumber: string
  statisticsTime: string
  storeDeliveryDOList: Model[]
  storeId: string
  total: number
  unit: string
  updateTime: string
}
