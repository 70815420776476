import { Injectable } from '@angular/core'
import { CacheService } from '@delon/cache'

@Injectable({ providedIn: 'root' })
export class SettingService {

  controls: Array<SettingItem | { type: 'divider', title: string }> = [
    { type: 'divider', title: '测试' },
    { name: 'test', type: 'boolean', lable: '测试', decr: '这是一条描述' },
    {
      name: 'test1',
      type: 'select',
      lable: '下拉框',
      decr: '这是一条描述',
      enum: [{ lable: '选项一', value: '1' }, { lable: '选项二', value: '2' }],
    },
    { name: 'text', type: 'text', lable: '输入框', decr: '这是一条描述' },
    // { type: 'divider', title: '基本设置' },
    // { name: 'qinwu', type: 'boolean', lable: '补录勤务', decr: '【勤务录入】时是否允许选择过去的日期？' },
    { type: 'divider', title: '开发模式' },
    { name: 'dev', type: 'boolean', lable: '开发者模式', decr: '这将开启开发者调试，请勿打开' },
  ]

  constructor(protected cache: CacheService) {
  }

  set(obj: { [key: string]: string })
  set(key: string | object, value: string)
  set(key: string | object, value?: string) {

    if (typeof key === 'object') {
      Object.keys(key).forEach(t => this.set(t, key[t]))
    } else {
      this.cache.set(key, value)
    }
  }

  get(key): any {
    return this.cache.getNone(key) || null
  }
}

interface SettingItem {
  name,
  lable,
  decr,
  type: 'boolean' | 'text' | 'number' | 'select',
  enum?: { lable, value }[]
}
