import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { EllipsisModule } from '@delon/abc'
import { DelonFormModule } from '@delon/form'
import { NzNoAnimationModule } from 'ng-zorro-antd/core/no-animation'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { FinancialRunningWaterComponent } from './financial-running-water.component'

@NgModule({
  declarations: [ FinancialRunningWaterComponent ],
  exports: [ FinancialRunningWaterComponent ],
  imports: [
    CommonModule,
    NzSelectModule,
    DelonFormModule,
    FormsModule,
    EllipsisModule,
    NzIconModule,
    NzNoAnimationModule
  ]
})
export class FinancialRunningWaterModule {}
