import { Component, forwardRef, Injector, Input } from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { SelectDm } from '@public/components/select-dm'
import { asyncCatch, isResultFaild } from '@scaffold/methods'
import { uniqBy } from 'lodash'

@Component({
  selector: 'app-dm-select',
  templateUrl: './code-select.component.html',
  styles: [`
      .dm-option-prefix {
          min-width: 15px;
          text-align: center
      }

      .dm-option-content {
          overflow: hidden;
          text-overflow: ellipsis;
      }
  `],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CodeSelectComponent),
    multi: true
  }]
})
export class CodeSelectComponent extends SelectDm<string> {

  /**
   * 唯一键值
   */
  @Input()
  key = 'id'

  constructor(injector: Injector) {
    super(injector)
  }

  async updateValue(key: string) {
    if (!key) {
      this._value = null
      return
    }
    const cItitem = this.listOfOptions.find(l => l[this.key] === key)
    if (cItitem) {
      return
    }
    const promise = this.httpCode.getById(key).toPromise()
    const [res] = await asyncCatch(promise)
    if (isResultFaild(res) || !res.response) {
      return
    }

    const listOfDm = [res.response, ...this.listOfOptions]
    this.listOfOptions = uniqBy(listOfDm, this.key)
  }
}
