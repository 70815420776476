import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { CacheService } from '@delon/cache'
import { Observable } from 'rxjs'
import { ENVIRONMENTS } from 'yqs-environments'

@Injectable()
export class UnifyApiInterceptorService implements HttpInterceptor {
  constructor(
    protected cache: CacheService,
    @Inject(ENVIRONMENTS) protected environments,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // 统一加上服务端前缀
    let url = req.url
    const { params } = req
    const isLocal = params.get('_local_')
    if (
      !url.startsWith('https://')
      && !url.startsWith('http://')
      && +isLocal !== 1
    ) {
      const prefix = this.cache.getNone('SERVER_URL') || this.environments.SERVER_URL
      url = prefix + url
    }

    params.delete('_local_')
    const newReq = req.clone({ url, params })

    return next.handle(newReq)
  }
}
