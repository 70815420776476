import { Injectable, Injector } from '@angular/core'
import { BaseApi, BaseUrl, GET, Payload, POST, Body } from '@delon/theme'
import { IResult, Model } from '@public/interface'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class HttpSinkageService extends BaseApi {

  constructor(injector: Injector) { super(injector) }

  @GET('/newFamilyMeeting/list')
  newFamilyMeetingList(@Payload body: any): Observable<IResult<any>> { return }

  @GET('/newFamilyMeeting/getTerminalList')
  getTerminalList(@Payload body: any): Observable<IResult<any>> { return }

  @POST('/newFamilyMeeting/in/confirm')
  confirm(@Body body): Observable<IResult> { return }

  @POST('/newFamilyMeeting/in/updateStatus')
  updateStatus(@Body body): Observable<IResult> { return }

  @GET('/newFamilyMeeting/getFamilyMeetingByTerminal')
  getFamilyMeetingByTerminal(@Payload body: any): Observable<IResult<any>> { return }

  @GET('/newFamilyMeeting/getMeetingFlagList')
  getMeetingFlagList(@Payload body: any): Observable<IResult<any>> { return }

  @POST('/newFamilyMeeting/editMeetingFlag')
  editMeetingFlag(@Body body): Observable<IResult> { return }
}