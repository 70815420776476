import { Injectable, Injector } from '@angular/core'
import { BaseApi, BaseUrl, Body, DELETE, GET, Path, Payload, POST, PUT, _HttpClient } from '@delon/theme'
import { IResult, Model, PaginationBody } from '@public/interface'
import { Observable } from 'rxjs'

@BaseUrl('/giveupillgottengains')
@Injectable({
  providedIn: 'root'
})
export class HttpGiveupillgottengains extends BaseApi {

  constructor(injector: Injector, protected http: _HttpClient) {
    super(injector)
  }

  @GET()
  getAllByPage(@Payload body): Observable<IResult<PaginationBody<GiveupillgottengainsModel>>> { return }

  @PUT('/approve')
  Approval(@Body body): Observable<IResult> { return }

  @POST()
  add(@Body body: GiveupillgottengainsModel): Observable<IResult> { return }

  @PUT()
  edit(@Body body: GiveupillgottengainsModel): Observable<IResult> { return }

  @PUT('/check')
  check(@Body body: GiveupillgottengainsModel): Observable<IResult> { return }

  @DELETE(':id')
  remove(@Path('id') id: string): Observable<IResult<GiveupillgottengainsModel>> { return }
}

export interface GiveupillgottengainsModel extends Model {
  /** 关联在押人id */
  accountId?: string

  /** 审批状态 0未审批 1审批 */
  approvalStatus?: 0 | 1

  /** 创建时间 */
  createTime?: string

  /** 查询结束时间 */
  endDate?: string

  /** 交易时间 */
  exchangeHour?: string

  /** 办案单位 */
  handlingUnit?: string

  /** id */
  id?: string

  /** 金额 */
  money?: string

  /** 备注 */
  remark?: string

  /** 查询开始时间 */
  startDate?: string

  /** 交易号码 */
  transactionNumber?: string

  /** 修改时间 */
  updateTime?: string
}
