import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core'
import { Router } from '@angular/router'
import { MessageService as NzMessageService } from '@public/providers'
import { NzModalService } from 'ng-zorro-antd/modal'

@Component({
  selector: 'header-storage',
  template: `
    <i class="mr-sm" nz-icon nzType="issues-close" nzTheme="outline"></i>
    {{'menu.clear.local.storage' | translate}}
  `,
  styles: [`:host {
    display: block
  }`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderStorageComponent {
  constructor(private modalSrv: NzModalService,
              private router: Router,
              private messageSrv: NzMessageService) {
  }

  @HostListener('click')
  _click() {
    this.modalSrv.confirm({
      nzOkText: '清理并退出',
      nzTitle: '是否清理所有本地存储？',
      nzContent: '个人配置、登录信息、缓存将被清理，操作后你必须重新登录。',
      nzOnOk: () => {
        localStorage.clear()
        this.messageSrv.success('清理完毕!')
        this.router.navigateByUrl('/')
      },
    })
  }
}
