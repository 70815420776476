import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { ImageUploadModule } from '@public/components/image-upload'
import { NzDividerModule } from 'ng-zorro-antd/divider'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { GoodsSelectComponent } from './goods-select.component'

@NgModule({
  declarations: [GoodsSelectComponent],
  exports: [GoodsSelectComponent],
  imports: [
    CommonModule,
    NzSelectModule,
    FormsModule,
    NzDividerModule,
    NzIconModule,
    ImageUploadModule
  ]
})
export class GoodsSelectModule {}
