import { Injectable, Injector } from '@angular/core'
import { BaseApi, BaseUrl, GET, Payload, POST, PUT, Query } from '@delon/theme'
import { IResult, IResultWithPagination, Model } from '@public/interface'
import { Observable } from 'rxjs'

@BaseUrl('/accountPurchaseLimitApplication')
@Injectable({
  providedIn: 'root'
})
export class HttpAccountPurchaseLimitApplicationService extends BaseApi {

  constructor(injector: Injector) { super(injector) }

  @GET('/page')
  getAllByPage(@Payload body: AccountLimitModel): Observable<IResultWithPagination<AccountLimitModel>> { return }

  @POST()
  add(@Payload body: AccountLimitModel): Observable<IResult<string>> { return }

  @PUT('/updateRemoveState')
  edit(@Query('id') id: string, @Query('removeApplyReason') removeApplyReason: string): Observable<IResult<AccountLimitModel>> { return }

  /** 审批在押人购物权限申请记录 */
  @PUT()
  approve(@Payload body: AccountLimitModel): Observable<IResult<AccountLimitModel>> { return }

  /** 审批在押人购物权限解除记录 */
  @PUT('/removeAccountPurchaseLimitApplication')
  remove(@Payload body: AccountLimitModel)
    : Observable<IResult<AccountLimitModel>> { return }
}

export interface AccountLimitModel extends Model {
  /** 在押人ID */
  accountId?: string

  /** 在押人姓名 */
  accountName?: string

  /** 申请理由 */
  applyReason?: string

  /** 申请人 */
  applyer?: string

  /** 审批理由 */
  approveReason?: string

  /** 审批状态（0：待审批，1同意，-1拒绝，3待编辑） */
  approveState?: number

  /** 审批时间 */
  approveTime?: string

  /** 审批人 */
  approver?: string

  /** 申请开始时间 */
  createBeginTime?: string

  /** 申请结束时间 */
  createEndTime?: string

  /** 监区、监室 */
  prisonRoom?: string

  /** 监所编号 */
  prisonsNo?: string

  /** 申请人 */
  proposer?: string

  /** 申请理由 */
  reason?: string

  /** 解除申请理由 */
  removeApplyReason?: string

  /** 解除申请时间 */
  removeApplyTime?: string

  /** 解除限制审批理由 */
  removeApproveReason?: string

  /** 解除购物权限限制状态（空值：未申请，0待审批，1通过，-1拒绝） */
  removeApproveState?: number

  /** 解除限制审批时间 */
  removeApproveTime?: string

  /** 解除限制审批人 */
  removeApprover?: string

  /** 解除申请人 */
  removeProposer?: string
}
