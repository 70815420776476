<ng-template #list>
  <nz2-upload-list
    *ngIf="locale && !nzFileListRender"
    #listComp
    [style.display]="nzShowUploadList ? '' : 'none'"
    [locale]="locale"
    [listType]="nzListType"
    [items]="nzFileList || []"
    [icons]="$any(nzShowUploadList)"
    [iconRender]="nzIconRender"
    [previewFile]="nzPreviewFile"
    [onPreview]="nzPreview"
    [onRemove]="onRemove"
    [onDownload]="nzDownload"
  ></nz2-upload-list>
  <ng-container *ngIf="nzFileListRender">
    <ng-container
      *ngTemplateOutlet="nzFileListRender; context: { $implicit: nzFileList }"
    ></ng-container>
  </ng-container>
</ng-template>
<ng-template #con>
  <ng-content></ng-content>
</ng-template>
<ng-template #btn>
  <div [ngClass]="classList" [style.display]="nzShowButton ? '' : 'none'">
    <div nz2-upload-btn #uploadComp [options]="_btnOptions!">
      <ng-template [ngTemplateOutlet]="con"></ng-template>
    </div>
  </div>
</ng-template>
<ng-container *ngIf="nzType === 'drag'; else select">
  <div
    [ngClass]="classList"
    (drop)="fileDrop($event)"
    (dragover)="fileDrop($event)"
    (dragleave)="fileDrop($event)"
  >
    <div nz2-upload-btn #uploadComp [options]="_btnOptions!" class="ant-upload-btn">
      <div class="ant-upload-drag-container">
        <ng-template [ngTemplateOutlet]="con"></ng-template>
      </div>
    </div>
  </div>
  <ng-template [ngTemplateOutlet]="list"></ng-template>
</ng-container>
<ng-template #select>
  <ng-container *ngIf="nzListType === 'picture-card'; else pic">
    <ng-template [ngTemplateOutlet]="list"></ng-template>
    <ng-template [ngTemplateOutlet]="btn"></ng-template>
  </ng-container>
</ng-template>
<ng-template #pic>
  <ng-template [ngTemplateOutlet]="btn"></ng-template>
  <ng-template [ngTemplateOutlet]="list"></ng-template>
</ng-template>
