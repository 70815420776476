import { Component, HostBinding, Input } from '@angular/core'
import { InputNumber } from 'ng-zorro-antd/core/util'
import { ImageUploadService } from './image-upload.service'

@Component({
  selector: 'app-image',
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    '[class.d-inline-flex]': 'true',
    '[class.position-relative]': 'true',
    '[class.align-content-center]': 'true',
    '[class.align-items-center]': 'true',
    '[class.justify-content-center]': 'true',
    '[class.border]': '!uid',
    '[style.overflow]': '"hidden"',
    '[style.vertical-align]': '"middle"',
  },
  template: `
    <img *ngIf="uid;else emptyTpl" [src]="imageUrl" width="100%" height="100%" alt="">
    <ng-template #emptyTpl>
      <nz-empty class="d-block h-100 w-100" nzNotFoundImage="simple" [nzNotFoundContent]="null"></nz-empty>
    </ng-template>`,
  styles: [`img {
    display: inline-block;
  }`]
})
export class ImageComponent {

  @Input() uid: string
  @Input() @InputNumber() height = 80
  @Input() @InputNumber() width = 80

  @HostBinding('style.height')
  get heightPx() {
    return `${this.height}px`
  }

  @HostBinding('style.width')
  get widthPx() {
    return `${this.width}px`
  }

  get imageUrl() {
    return this.srv.getImageUrl(this.uid)
  }

  constructor(private srv: ImageUploadService) { }
}
