import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { ChangePasswordModule } from '@scaffold/layout/components/change-password'
import { FullscreenModule } from '@scaffold/layout/components/fullscreen'
import { StorageModule } from '@scaffold/layout/components/storage'
import { NzAvatarModule } from 'ng-zorro-antd/avatar'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzDropDownModule } from 'ng-zorro-antd/dropdown'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzTagModule } from 'ng-zorro-antd/tag'
import { LayoutProWidgetUserComponent } from './user.component'

@NgModule({
  declarations: [LayoutProWidgetUserComponent],
  exports: [LayoutProWidgetUserComponent],
  imports: [
    CommonModule,
    NzDropDownModule,
    NzAvatarModule,
    NzIconModule,
    RouterModule,
    TranslateModule,
    FullscreenModule,
    StorageModule,
    NzTagModule,
    NzButtonModule,
    ChangePasswordModule,
  ],
})
export class UserModule {}
