import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
  HttpResponse, HttpResponseBase,
} from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { DA_SERVICE_TOKEN, TokenService } from '@delon/auth'
import { _HttpClient } from '@delon/theme'
import { AlainConfig, ALAIN_CONFIG } from '@delon/util'
import { MessageService } from '@public/providers'
import { Auth } from '@scaffold/providers'
import { Observable, of, throwError } from 'rxjs'
import { catchError, mergeMap } from 'rxjs/operators'

const CODEMESSAGE = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
}

/**
 * 默认HTTP拦截器，其注册细节见 `app.component.ts`
 */
@Injectable()
export class HandleErrorInterceptorService implements HttpInterceptor {
  constructor(
    @Inject(DA_SERVICE_TOKEN) protected tokenService: TokenService,
    protected router: Router,
    protected notification: MessageService,
    protected http: _HttpClient,
    protected auth: Auth,
    @Inject(ALAIN_CONFIG) protected config: AlainConfig
  ) {
  }

  private goTo(url: string) {
    setTimeout(() => this.router.navigateByUrl(url))
  }

  private checkoutHttpStatus(ev: HttpResponseBase | any = {}, quiet = false) {
    switch (true) {
      case (ev.status >= 200 && ev.status < 300):
        return true
      case !ev || !ev.status:
        if (!quiet) {
          this.notification.error('网络超时', '请检查网络链接，或联系管理员')
        }
        return false
      default:
        const errortext = CODEMESSAGE[ev.status] || ev.statusText
        if (!quiet) {
          this.notification.error(`请求错误 ${ev.status}`, errortext)
        }
        console.warn(`请求错误 :${ev.url} ----> ${errortext}`)
        return false
    }
  }

  private handleData(ev: HttpResponseBase, quiet): Observable<any> {

    // 检查 Http 状态是否正常
    if (!this.checkoutHttpStatus(ev, quiet)) {
      return throwError(ev)
    }

    const { status, body } = ev as HttpResponse<any>

    // 可能会因为 `throw` 导至无法执行 `_HttpClient` 的 `end()` 操作
    if (status > 0) {
      this.http.end()
    }

    // 业务处理：一些通用操作
    const { status: code, response } = (body || { status: -1 }) as any
    switch (code) {
      case 200:
        return of(ev)
      case 401:
        const errortext = body.msg || CODEMESSAGE[code] || '未登录或登录已过期，请重新登录。'
        console.warn(ev.url, errortext)
        if (!quiet) {
          this.notification.error(errortext, response)
        }

        // 清空 token 信息
        if (!ev.url.includes('_allow_anonymous=true')) {
          this.auth.logout()
        }
        break
      case 403:
      case 404:
      case 500:
      case 600:
        const errorText3 = body?.msg || response
        if (!quiet) {
          this.notification.warning(`${CODEMESSAGE[code] || '无效操作'}`, errorText3)
          console.warn(`无效操作 :${ev.url} ----> ${JSON.stringify(body)}`)
        }
        break
      default:
        switch (true) {
          case (ev instanceof HttpHeaderResponse):
            break

          case (ev instanceof HttpErrorResponse):
            const errorText2 = '未可知错误，大部分是由于后端不支持CORS或无效配置引起'
            console.warn(errorText2, ev)
            this.notification.error(`${CODEMESSAGE[code] || '操作失败'} \n错误代码：${code}`, `${CODEMESSAGE[code] || '操作失败'} ${code}`)
            return throwError(ev)

          case (ev instanceof HttpResponse):
          default:
            const errorText1 = body?.msg || response
            if (!quiet) {
              this.notification.error(`${CODEMESSAGE[code] || '操作失败'} \n错误代码：${code}`, errorText1)
              console.warn(`操作失败 :${ev.url} ----> ${JSON.stringify(body)}`)
            }
        }
    }
    return of(ev)
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const _params: HttpParams = req.params
    const quiet = _params.get('_quiet_') || location.href.includes(this.config.auth.login_url)

    const params = _params.delete('_quiet_')
    const newReq = req.clone({ params })

    return next.handle(newReq).pipe(
      mergeMap((event: any) => {
        // 允许统一对请求错误处理
        return (event instanceof HttpResponseBase)
          ? this.handleData(event, quiet)
          : of(event)  // 若一切都正常，则后续操作
      }),
      catchError((err: HttpErrorResponse) => this.handleData(err, quiet)),
    )
  }
}
