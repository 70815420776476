import { debounce, throttle } from 'lodash'

/**
 * 函数节流装饰器
 * @param wait number  节流的毫秒
 * @param options Object 节流选项对象
 * @param options.leading=true boolean 指定调用在节流开始前。
 * @param options.trailing=true boolean 指定调用在节流结束后。
 */
export const Throttle = (wait, options = {}) => {
  return (target, name, descriptor) => {
    descriptor.value = throttle(descriptor.value, wait, options)
  }
}

/**
 * 函数防抖装饰器
 * @param wait number  需要延迟的毫秒数。
 * @param options Object  选项对象
 * [options.leading=false] (boolean): 指定在延迟开始前调用。
 * [options.maxWait] (number): 设置 func 允许被延迟的最大值。
 * [options.trailing=true] (boolean): 指定在延迟结束后调用。
 */
export const Debounce = (wait, options = {}) => {
  return (target, name, descriptor) => {
    descriptor.value = debounce(descriptor.value, wait, options)
  }
}
