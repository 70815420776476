import { Injectable } from '@angular/core'
import { SettingsService } from '@delon/theme'
import { ArrayService } from '@delon/util'
import { asyncCatch, isResultFaild } from '@scaffold/methods'
import { cloneDeep } from 'lodash'
import { HttpRoleService, RoleTreeModel } from './http-role.service'

@Injectable({
  providedIn: 'root'
})
export class RoleTreeService {

  get listOfTree() {
    return this._listOfTree
  }

  private loading = false
  private _listOfTree: RoleTreeModel[] = []

  constructor(
    private setting: SettingsService,
    private httpMenu: HttpRoleService,
    private arraySrv: ArrayService
  ) { }

  async pullRoleTree(params) {
    const promise = this.httpMenu.getAllByTree(params).toPromise()
    this.loading = false
    const [res] = await asyncCatch(promise)
    this.loading = false
    const list = isResultFaild(res) ? null : res.response

    this.arraySrv.visitTree(list, (node: RoleTreeModel) => {
      Object.assign(node, { isLeaf: !node?.children?.length })
    })
    this._listOfTree = cloneDeep(list)
  }
}
