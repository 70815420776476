import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { MenuTreeSelectComponent } from '@public/components/menu-tree/menu-tree-select.component'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzTreeModule } from 'ng-zorro-antd/tree'
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select'
import { MenuTreeComponent } from './menu-tree.component'

@NgModule({
  declarations: [MenuTreeComponent, MenuTreeSelectComponent],
  exports: [MenuTreeComponent, MenuTreeSelectComponent],
  imports: [
    CommonModule,
    NzTreeSelectModule,
    FormsModule,
    NzTreeModule,
    NzInputModule,
    NzIconModule
  ]
})
export class MenuTreeModule {}
