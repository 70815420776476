import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { AlainThemeModule } from '@delon/theme'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzWaveModule } from 'ng-zorro-antd/core/wave'
import { NzDropDownModule } from 'ng-zorro-antd/dropdown'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzSpinModule } from 'ng-zorro-antd/spin'
import { NzSwitchModule } from 'ng-zorro-antd/switch'
import { NzTimelineModule } from 'ng-zorro-antd/timeline'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'
import { LayoutProWidgetQuickPanelComponent } from './quick-panel.component'
import { LayoutProWidgetQuickComponent } from './quick.component'

@NgModule({
  declarations: [LayoutProWidgetQuickComponent, LayoutProWidgetQuickPanelComponent],
  exports: [LayoutProWidgetQuickComponent, LayoutProWidgetQuickPanelComponent],
  imports: [
    CommonModule,
    NzToolTipModule,
    NzIconModule,
    NzWaveModule,
    NzSpinModule,
    NzTimelineModule,
    NzDropDownModule,
    FormsModule,
    NzSwitchModule,
    AlainThemeModule,
    NzButtonModule,
  ],
})
export class QuickModule {}
