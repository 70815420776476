import { Inject, Injectable } from '@angular/core'
import { ALAIN_I18N_TOKEN, _HttpClient } from '@delon/theme'
import { TranslateService } from '@ngx-translate/core'
import { asyncCatch } from '@scaffold/methods'
import { I18NService } from '@scaffold/providers/i18n'
import { AsyncClass } from './async.class'

@Injectable({ providedIn: 'root' })
export class AsyncI18n extends AsyncClass<any> {
  data: any

  constructor(
    @Inject(ALAIN_I18N_TOKEN) public i18n: I18NService,
    public httpClient: _HttpClient,
    public translate: TranslateService,
  ) {
    super()
  }

  async sync(): Promise<any> {
    const [langData] = await asyncCatch(this.httpClient.get(
      `assets/i18n/app.i18n.json`,
      { _quiet_: 1, _local_: 1 }
    ).toPromise())

    this.translate.setTranslation(this.i18n.defaultLang, langData)
    this.translate.setDefaultLang(this.i18n.defaultLang)

    return langData
  }
}
