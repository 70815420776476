import { Injectable, Injector } from '@angular/core'
import { BaseApi, BaseUrl, Body, DELETE, GET, Path, Payload, POST, PUT, _HttpClient } from '@delon/theme'
import { IResult, IResultWithPagination, Model } from '@public/interface'
import { Observable } from 'rxjs'

@BaseUrl('/storeGood')
@Injectable({ providedIn: 'root' })
export class HttpStoreGood extends BaseApi {

  constructor(injector: Injector, private http: _HttpClient) {
    super(injector)
  }

  @GET('/allDetail')
  getAllByPage(@Payload body): Observable<IResultWithPagination<GoodsModel>> { return }

  @POST()
  add(@Body body: GoodsModel): Observable<IResult<GoodsModel>> { return }

  @PUT()
  edit(@Body body: GoodsModel): Observable<IResult<GoodsModel>> { return }

  @PUT('/toApproval')
  approval(@Body body): Observable<IResult> { return }

  @PUT('/toOnline') changeStatus(@Payload body): Observable<IResult<GoodsModel>> { return }

  remove(body: string[]): Observable<IResult> {
    return this.http.request('DELETE', '/storeGood', { body }) as Observable<any>
  }

  @GET(':id')
  getById(@Path(':id') id: string): Observable<IResult<GoodsModel>> { return }

  @PUT('/toLimitNumber')
  toLimitNumber(@Payload body: GoodsModel): Observable<IResult<GoodsModel>> { return }

}

export interface GoodsModel extends Model {

  /** 上架时间 */
  onlineTime?: string

  /** 申请时间 */
  applyTime?: string

  /** 审批人id */
  approvalId?: string

  /** 审批人名称 */
  approvalName?: string

  /** 审批时间 */
  approvalTime?: string

  /** 商品分类id */
  cateId?: string

  /** 商品分类名称 */
  cateName?: string

  /** 详情 */
  detail?: string

  /** 图片，图片统一为图片链接 */
  imgs?: string

  /** 是否上线，0为否，1为是 */
  isOnline?: boolean

  /** 商品名称 */
  name?: string

  /** 供应位置，0所内在押人员，1家属购物 */
  position?: boolean

  /** 单价 */
  price?: number

  /** 监所名称 */
  prisonName?: string

  /** 监所编号 */
  prisonsNo?: string

  /** 审批备注 */
  reason?: string

  /** 备注 */
  remark?: string

  /** 销量 */
  sellCount?: number

  /** 排序 */
  sortOrder?: number

  /** 状态，0为待审核，1为审核通过，2为审核不通过，3为待编辑 */
  state?: number

  /** 状态，0为待审核，1为审核通过，2为审核不通过，3为待编辑 */
  stateStr?: string

  /** 库存 */
  stock?: number

  /** 商家id */
  storeId?: string

  /** 商家名称 */
  storeName?: string

  /** 商品分类供应位置 */
  type?: string

  /** 商品分类供应位置中文:所内购物，家属商城 */
  typeName?: string

  /** 单位，组、瓶、个 */
  unit?: string

  /** 限购数量 */
  limitNumber?: number
}
