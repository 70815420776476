import { Injectable, Injector } from '@angular/core'
import { BaseApi, BaseUrl, GET, Payload, Query, _HttpClient } from '@delon/theme'
import { IResult, IResultWithPagination, PaginationBody } from '@scaffold/interface'
import { Observable } from 'rxjs'

@BaseUrl('/shoppingNew')
@Injectable({ providedIn: 'root' })
export class HttpFinanceSld extends BaseApi {

  constructor(injector: Injector, protected http: _HttpClient) {
    super(injector)
  }

  /** 统计报表新 查看详情 所领导审批用 雷 */
  @GET('/prisonLeaderConfirmNew')
  prisonLeaderConfirmNew(@Payload params): Observable<IResult<{ page: PaginationBody<LeaderConfirmModel>, sumTotal: number }>> { return }

  /** 统计报表新 查看详情 所领导审批用 雷 */
  @GET('/prisonLeaderConfirmNewByPerson')
  prisonLeaderConfirmNewByPerson(@Payload params: { id, state }): Observable<IResultWithPagination<any>> { return }
}

export interface LeaderConfirmModel {
  foodTotal: number
  id: string
  lifeTotal: number
  name: string
  prisonRoom: string
  total: number
  flag: number
  financialLockStatus: number
}
