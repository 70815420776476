import { Directive, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { ArrayService } from '@delon/util'
import { OriginTreeService } from '@public/components/origin-tree/origin-tree.service'
import { NzTreeNodeOptions } from 'ng-zorro-antd/core/tree'
import { InputBoolean, toArray } from 'ng-zorro-antd/core/util'
import { NzTreeComponent } from 'ng-zorro-antd/tree'

@Directive()
export abstract class OriginTreeClass implements OnInit {
  @Input() @InputBoolean() showChecked = false
  @Input() @InputBoolean() showSearch = false
  @ViewChild('nzTreeComponent', { static: false }) nzTreeComponent!: NzTreeComponent
  @Output() actived: EventEmitter<NzTreeNodeOptions | NzTreeNodeOptions[]>
    = new EventEmitter<NzTreeNodeOptions | NzTreeNodeOptions[]>()

  get listOfOriginTree() {
    return this.srv.listOfOrignTree
  }

  get listOfPrisoner() {
    return this.srv.listOfPrisoners
  }

  get value(): string | string[] {
    return this._value
  }

  set value(value) {
    this._value = value

    if (typeof this._change === 'function') {
      this._change(value)
    }
  }

  currentNodes: NzTreeNodeOptions[]
  private _value: string | string[]
  private _change: (...arg) => any
  private _touch: (...arg) => any

  constructor(public arraySrv: ArrayService, private srv: OriginTreeService) { }

  ngOnInit() {
    this.init()
  }

  async init() {
    this.srv.pullOrignTree({})
  }

  async onExpandChange(e) {
    const node = e.node
    if (node && node.getChildren().length === 0 && node.isExpanded && node?.origin?.type === 5) {
      await this.srv.getPrisonersByRoom(node?.origin?.prisonsNo, node?.origin?.code)
      node.addChildren(this.listOfPrisoner)
    }
  }

  modalChange(e: string | string[]) {
    const es = toArray<string>(e)
    this.currentNodes = []
    es?.forEach(node => {
      const { origin } = this.nzTreeComponent.getTreeNodeByKey(node)
      this.currentNodes.push(origin)
    })
    this.actived.emit(this.currentNodes)
  }

  currentData(e) {
    this.actived.emit(e.node?.origin)
  }

  registerOnChange(fn: any): void {
    this._change = fn
  }

  registerOnTouched(fn: any): void {
    this._touch = fn
  }

  writeValue(val): void {
    this.chean()
    this._value = val
  }

  chean() {
    this._value = ''
  }
}
