import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { DA_SERVICE_TOKEN, TokenService } from '@delon/auth'
import { IResult } from '@scaffold/interface'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(@Inject(DA_SERVICE_TOKEN) protected tokenService: TokenService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event) => event instanceof HttpResponse ? this.handleData(event) : null),
    )
  }

  private handleData({ headers, body }: HttpResponse<IResult>): void {
    const token = headers.get('Authorization') || body?.response?.token

    // 设置用户Token信息
    token && this.tokenService.set({ token })
  }
}
