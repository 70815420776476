import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { LogoModule } from '../logo'
import { MenuModule } from '../menu'
import { WidgetModule } from '../widget'
import { LayoutProHeaderComponent } from './header.component'

@NgModule({
  declarations: [LayoutProHeaderComponent],
  exports: [LayoutProHeaderComponent],
  imports: [
    CommonModule,
    LogoModule,
    MenuModule,
    WidgetModule,
    NzIconModule,
    RouterModule,
  ],
})
export class HeaderModule {}
