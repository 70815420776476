import { Component, forwardRef, Input } from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { PrisonModel } from '@public/providers/http'
import { OriginTreeClass } from './origin-tree.class'

@Component({
  selector: 'app-origin-tree-select',
  template: `
      <nz-tree-select
              #nzTreeComponent
              style="min-width: 250px"
              [nzMaxTagCount]="2"
              [nzMaxTagPlaceholder]="omittedPlaceHolder"
              [(ngModel)]="value"
              [nzNodes]="listOfOriginTreeSelect"
              [nzAsyncData]="true"
              (ngModelChange)="modalChange($event)"
              [nzCheckable]="showChecked"
              nzShowSearch
              nzPlaceHolder="请选择"
      >
      </nz-tree-select>
      <ng-template #omittedPlaceHolder let-omittedValues> and {{ omittedValues.length }} more...</ng-template>`,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OriginTreeSelectComponent),
    multi: true
  }]
})
export class OriginTreeSelectComponent extends OriginTreeClass {
  @Input() type

  get listOfOriginTreeSelect() {
    this.arraySrv.visitTree(this.listOfOriginTree, (node: PrisonModel) => {
      Object.assign(node, { isLeaf: this.type === node?.type || node?.children?.length === 0 })
    })
    return this.listOfOriginTree
  }
}
