import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzMenuModule } from 'ng-zorro-antd/menu'
import { LayoutProMenuComponent } from './menu.component'

@NgModule({
  declarations: [LayoutProMenuComponent],
  exports: [LayoutProMenuComponent],
  imports: [
    CommonModule,
    NzIconModule,
    RouterModule,
    NzMenuModule,
  ],
})
export class MenuModule {}
