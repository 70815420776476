import { Injectable } from '@angular/core'
import { NzIconService } from 'ng-zorro-antd/icon'
import { ICONS_ANTDESIGN } from './icons-antdesign'
import { ICONS_ICONFONTS } from './icons-iconfont'

@Injectable({ providedIn: 'root' })
export class IconService {

  constructor(
    protected iconSrv: NzIconService) {
  }

  async startup() {
    this.iconSrv.addIcon(...ICONS_ANTDESIGN)
    ICONS_ICONFONTS.forEach((scriptUrl) => this.iconSrv.fetchFromIconfont({ scriptUrl }))
  }
}
