import { ChangeDetectionStrategy, Component, forwardRef, } from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { MenuTreeClass } from '@public/components/menu-tree/menu-tree.class'

@Component({
  selector: 'app-menu-tree',
  template: `
      <nz-input-group [nzSuffix]="suffixIcon" [hidden]="!showSearch">
          <ng-template #suffixIcon>
              <i nz-icon nzType="search" nzTheme="outline"></i>
          </ng-template>
          <input type="text" nz-input placeholder="菜单名" [(ngModel)]="searchValue"/>
      </nz-input-group>
      <nz-tree #menuTree
               style="min-width: 200px"
               [nzData]="listOfMenuTree"
               [nzSearchValue]="searchValue"
               [nzCheckedKeys]="nzCheckedKeys"
               [nzCheckable]="showCheckable"
               [nzMultiple]="showCheckable"
               nzAsyncData
               (nzClick)="currentData($event)"
               (nzCheckBoxChange)="checkBoxChange()">
      </nz-tree>
  `,
  styleUrls: ['./menu-tree.component.less'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MenuTreeComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuTreeComponent extends MenuTreeClass {
  searchValue = ''

  checkBoxChange() {
    const checkedNodeList = this.menuTree?.getCheckedNodeList()
    this.value = checkedNodeList.map(t => t.key)
    this.actived.emit(this.menuTree?.getCheckedNodeList())
  }
}
