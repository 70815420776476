import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { ACLService } from '@delon/acl'
import { SettingsService } from '@delon/theme'
import { AsyncI18n } from '@scaffold/core/startup/async/async-i18n'
import { asyncCatch } from '@scaffold/methods'
import { zip } from 'rxjs'
import { AsyncAppDataService } from './async/async-app-data.service'
import { AsyncCurrentUserService } from './async/async-current-user.service'

/**
 * 用于应用启动时
 * 一般用来获取应用所需要的基础数据等
 */
@Injectable()
export class StartupService {
  constructor(
    private settingService: SettingsService,
    private aclService: ACLService,
    private currentUser: AsyncCurrentUserService,
    private appDataService: AsyncAppDataService,
    private asyncI18n: AsyncI18n,
    private router: Router
  ) {
  }

  async load() {
    (window as any).ERROR = null
    const promise = Promise.all([
      this.asyncI18n.sync(),
      this.appDataService.sync(),
      this.currentUser.sync(),
    ])

    const [p, err] = await asyncCatch(promise)
    if (err) {
      (window as any).ERROR = err
      Object.keys(err).forEach(key => {
        console.log(err[key])
      })
      return this.router.navigateByUrl('/error')
    } else {
      return true
    }
  }
}
