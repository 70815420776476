import { Injectable, Injector } from '@angular/core'
import { BaseApi, BaseUrl, Body, GET, Payload, POST, PUT, _HttpClient } from '@delon/theme'
import { IResult, IResultWithPagination, Model, PaginationParams } from '@public/interface'
import { Observable } from 'rxjs'

@BaseUrl('/goodCategory')
@Injectable({ providedIn: 'root' })
export class HttpGoodCategoryService extends BaseApi {

  constructor(injector: Injector, protected http: _HttpClient) {
    super(injector)
  }

  @GET()
  query(@Payload params: PaginationParams & { type?, state? }): Observable<IResultWithPagination<GoodsCategoryModel>> { return }

  @POST()
  add(@Body body: GoodsCategoryModel): Observable<IResult> { return }

  @PUT()
  edit(@Body body: GoodsCategoryModel): Observable<IResult> { return }

  remove(ids): Observable<IResult> {
    return this.http.request('DELETE', '/goodCategory', { body: ids }) as Observable<IResult>
  }
}

export interface GoodsCategoryModel extends Model {

  /** 分类编码 */
  code?: string

  /** 图标图片 */
  icon?: string

  /** 级别 */
  level?: string

  /** 分类名称 */
  name?: string

  /** 上级id */
  parentId?: string

  /** 监所id，接口可以为空 */
  prisonsNo?: string

  /** 排序 */
  sortOrder?: number

  /** 状态，0为未启用，1为启用 */
  state?: 0 | 1

  /** 0所内购物，1家属商城 */
  type?: number
}
