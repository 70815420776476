<nz-input-group>
  <app-jsh-select *ngIf="showPrisonRoom" [size]="size" [(ngModel)]="prisonRoom"></app-jsh-select>
  <nz-select
    style="width: 100%;min-width: 170px;"
    [(ngModel)]="value"
    nzPlaceHolder="输入姓名"
    nzAllowClear
    nzShowSearch
    nzNoAnimation
    [nzSize]="nzSize"
    [nzServerSearch]="true"
    [nzDropdownMatchSelectWidth]="false"
    [nzDropdownStyle]="{width:'280px'}"
    (nzOnSearch)="input$.next($event)"
    (nzScrollToBottom)="loadMore()"
    (nzFocus)="handleFocus()"
    [nzDisabled]="disabled"
  >
    <ng-container *ngFor="let item of listOfOptions">
      <nz-option nzCustomContent [nzValue]="item.id" [nzLabel]="item.name">
        <div class="d-flex align-items-center">
          <div>
            <ellipsis length="8">{{item.name}}</ellipsis>
          </div>
          <div class="flex-grow-1 text-right d-flex justify-content-end">
            <span style="min-width: 60px">{{item.prisonRoom ? '监室' + item.prisonRoom : '无监室'}}</span>
          </div>
        </div>
      </nz-option>
    </ng-container>
    <nz-option *ngIf="loading || firstLoading" nzDisabled nzCustomContent>
      <i nz-icon nzType="loading" class="loading-icon"></i> 加载中，请稍候...
    </nz-option>
  </nz-select>
</nz-input-group>
