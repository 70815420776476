import { Inject, Injectable } from '@angular/core'
import { ACLService } from '@delon/acl'
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth'
import { SettingsService, User, _HttpClient } from '@delon/theme'
import { AsyncMenu } from '@scaffold/core/menu'
import { IResult } from '@scaffold/interface'
import { asyncCatch, isResultFaild, log, warn } from '@scaffold/methods'
import { AsyncClass } from './async.class'

@Injectable({ providedIn: 'root' })
export class AsyncCurrentUserService extends AsyncClass<CurrentUser> {
  local = 0

  get data(): CurrentUser {
    return this._data
  }

  set data(user: CurrentUser) {
    this._data = user
    this.settingService.setUser(user)

    this.setACL()
    this.data$.next(user)
  }

  private _data: CurrentUser

  constructor(protected http: _HttpClient,
              protected settingService: SettingsService,
              protected aclService: ACLService,
              protected menuSrv: AsyncMenu,
              @Inject(DA_SERVICE_TOKEN) protected tokenService: ITokenService) {
    super()
  }

  async sync() {
    const { token } = this.tokenService.get()

    // 鉴权失败
    if (!token) {
      warn('鉴权失败', token)
      return null
    }

    const currentUser = await this.pullCurrentUser()
    this.data = currentUser
    await this.syncMenu()

    return currentUser
  }

  clean() {
    this.data = null
    this.menuSrv.clear()
    this.tokenService.clear()
  }

  private async pullCurrentUser(): Promise<CurrentUser> {
    const [res] = await asyncCatch(this.http.get('/user/current', { _quiet_: '1' }).toPromise() as Promise<IResult<CurrentUser>>)
    if (isResultFaild(res)) {
      throw new Error()
    }
    return res?.response[0]

  }

  private setACL() {
    // todo 设置权限
    // this.aclService.setFull(true)
  }

  // todo 根据当前用户同步菜单列表
  private async syncMenu() {
    await asyncCatch(this.menuSrv.sync().toPromise())
  }
}

export interface CurrentUser extends User {
  [key: string]: any
}
