import {
  ArrayWidget, AutoCompleteWidget, BooleanWidget, CascaderWidget, CheckboxWidget, CustomWidget,
  DateWidget, MentionWidget,
  NumberWidget, ObjectWidget,
  RadioWidget, RateWidget, SelectWidget, SliderWidget,
  StringWidget, TagWidget, TextareaWidget,
  TextWidget, TimeWidget, TransferWidget, TreeSelectWidget, UploadWidget,
  WidgetRegistry
} from '@delon/form'
import {
  CategoryGoodsSelectComponent,
  CodeSelectComponent,
  ColorPickerComponent,
  FinancialRunningWaterRoundupComponent,
  GoodsCategoryComponent,
  GoodsSelectComponent,
  IconComponent,
  ImageUploadBase64Component,
  ImageUploadComponent,
  JailSelectComponent,
  JshSelectComponent,
  MenuTreeComponent,
  OriginTreeComponent,
  PrisonSelectComponent, ReadOnlyWidget,
  RmbWidget,
  RoleTreeComponent,
  RySelectComponent,
  RySelectObjComponent,
} from './widgets'

export const CUSTOM_WIDGETS = [
  CategoryGoodsSelectComponent,
  GoodsCategoryComponent,
  GoodsSelectComponent,
  IconComponent,
  ImageUploadBase64Component,
  ImageUploadComponent,
  MenuTreeComponent,
  OriginTreeComponent,
  RoleTreeComponent,
  RySelectComponent,
  RySelectObjComponent,
  JailSelectComponent,
  RmbWidget,
  ColorPickerComponent,
  PrisonSelectComponent,
  CodeSelectComponent,
  JshSelectComponent,
  ReadOnlyWidget,
  FinancialRunningWaterRoundupComponent
]

export class NzWidgetRegistry extends WidgetRegistry {
  constructor() {
    super()

    this.register('object', ObjectWidget)
    this.register('array', ArrayWidget)

    this.register('text', TextWidget)
    this.register('string', StringWidget)
    this.register('number', NumberWidget)
    this.register('integer', NumberWidget)
    this.register('date', DateWidget)
    this.register('time', TimeWidget)
    this.register('radio', RadioWidget)
    this.register('checkbox', CheckboxWidget)
    this.register('boolean', BooleanWidget)
    this.register('textarea', TextareaWidget)
    this.register('select', SelectWidget)
    this.register('tree-select', TreeSelectWidget)
    this.register('tag', TagWidget)
    this.register('upload', UploadWidget)
    this.register('transfer', TransferWidget)
    this.register('slider', SliderWidget)
    this.register('rate', RateWidget)
    this.register('autocomplete', AutoCompleteWidget)
    this.register('cascader', CascaderWidget)
    this.register('mention', MentionWidget)
    this.register('custom', CustomWidget)

    this.register('menu-tree-select', MenuTreeComponent)
    this.register('icon', IconComponent)
    this.register('role-tree-select', RoleTreeComponent)
    this.register('goods-category', GoodsCategoryComponent)
    this.register('origin-tree', OriginTreeComponent)
    this.register('image-upload', ImageUploadComponent)
    this.register('image-upload-base64', ImageUploadBase64Component)
    this.register('jsh-select', JshSelectComponent)
    this.register('financial-running-water-roundup', FinancialRunningWaterRoundupComponent)
    this.register('ry-select', RySelectComponent)
    this.register('ry-select-obj', RySelectObjComponent)
    this.register('goods-select', GoodsSelectComponent)
    this.register('jail-select', JailSelectComponent)
    this.register('prison-select', PrisonSelectComponent)
    this.register('code-select', CodeSelectComponent)
    this.register('read-only', ReadOnlyWidget)
    this.register(RmbWidget.KEY, RmbWidget)
    this.register(ColorPickerComponent.KEY, ColorPickerComponent)

    this.setDefault(StringWidget)
  }
}
