import { Routes } from '@angular/router'
import { SimpleGuard } from '@delon/auth'
import { SCAFFOLD_PAGES } from '@public/pages/page-routes'
import { LayoutProComponent } from '@scaffold/layout'

const OTHERS: Routes = [

  // 演示页面
  { path: 'demo', loadChildren: () => import('@public/pages/demo-pages/routes.module').then(t => t.DemoRoutesModule) }
]

export const PAGES_ROUTES: Routes = [
  ...SCAFFOLD_PAGES,
  {
    path: 'print-spOrder',
    loadChildren: () => import('./order/components/order-jiashu/print-spOrder/print-spOrder.module').then(t => t.PrintSpOrderModule)
  },
  {
    path: 'print-onlyOrder',
    loadChildren: () => import('./order/components/order-jiashu/print-onlyOrder/print-onlyOrder.module').then(t => t.PrintOnlyOrderModule)
  },
  {
    path: '',
    canActivate: [SimpleGuard], canActivateChild: [SimpleGuard],
    children: [
      {
        path: '', component: LayoutProComponent,
        children: [

          { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
          {
            path: 'dashboard',
            loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
          },
          {
            path: 'store-goods',
            loadChildren: () => import('./store-goods/store-goods.module').then(m => m.StoreGoodsModule)
          },
          /*{
            path: 'inventory',
            loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule)
          },*/
          { path: 'order', loadChildren: () => import('./order/order.module').then(m => m.OrderModule) },

          { path: 'refund', loadChildren: () => import('./refund/refund.module').then(m => m.RefundModule) },
          {
            path: 'complaint-refund',
            loadChildren: () => import('./complaint-refund/complaint-refund.module').then(m => m.ComplaintRefundModule)
          },
          {
            path: 'manual-refund',
            loadChildren: () => import('./manual-refund/manual-refund.module').then(m => m.ManualRefundModule)
          },
          {
            path: 'refundOrder',
            loadChildren: () => import('./refundOrder/refundOrder.module').then(m => m.RefundOrderModule)
          },
          {
            path: 'shopping-refund',
            loadChildren: () => import('./shopping-refund/shopping-refund.module').then(m => m.ShoppingRefundModule)
          },
          {
            path: 'inclusive-finance',
            loadChildren: () => import('./inclusive-finance/inclusive-finance.module').then(m => m.InclusiveFinanceModule)
          },

          /** 业务子模块 */
          ...OTHERS,
          { path: 'exception', loadChildren: () => import('@public/pages/exception').then(t => t.ExceptionModule) },
          { path: '**', redirectTo: '/exception/404' }
        ]
      },
    ]
  },

]
