import { ChangeDetectorRef, Directive, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core'
import { ControlValueAccessor } from '@angular/forms'
import { PaginationParams } from '@public/interface'
import { paginationFactory } from '@scaffold/methods'
import { uniqBy } from 'lodash'
import { NzSizeLDSType } from 'ng-zorro-antd/core/types'
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

@Directive()
export abstract class SelectAsync<T, OptionModel> implements ControlValueAccessor, OnInit {

  get value(): T {
    return this._value
  }

  set value(value: T) {
    this._value = value
    this.onChanged.emit(value)

    if (typeof this._onChange === 'function') {
      this._onChange(value)
    }
  }

  _value: T

  @Input() disabled: boolean
  @Input() size: NzSizeLDSType
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onChanged: EventEmitter<T> = new EventEmitter<T>()

  @Input() prisonFlag: 0 | 1 | ''

  allPrisonList: OptionModel[] = []
  zeroPrisonList: OptionModel[] = []
  onePrisonList: OptionModel[] = []

  pagination: PaginationParams
  listOfOptions: OptionModel[] = []

  firstLoading = true
  loading = false
  allDone = false

  input$ = new Subject<string>()
  _onChange: (...arg) => any
  _onTouch: (...arg) => any
  _inputCache: string

  protected constructor(private injector: Injector) {
    this.pagination = paginationFactory()
  }

  ngOnInit(): void {
    this.input$.pipe(debounceTime(300)).subscribe(text => this.typing(text))
  }

  registerOnChange(fn: any): void {
    this._onChange = fn
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  writeValue(value: T) {
    this.updateValue(value)
  }

  async loadMore(inputText = this._inputCache) {
    if (this.allDone || this.loading) {
      return
    }
    this.firstLoading = false
    this._inputCache = inputText

    this.loading = true
    const list = (await this.getOptions(inputText)) || []
    this.loading = false

    let listOfDm = []
    if (this.prisonFlag === '') {
      listOfDm = [...this.allPrisonList, ...list]
      this.allPrisonList = uniqBy(listOfDm, this.optionsFilter)
    } else if (this.prisonFlag === 0) {
      listOfDm = [...this.zeroPrisonList, ...list]
      this.zeroPrisonList = uniqBy(listOfDm, this.optionsFilter)
    } else if (this.prisonFlag === 1) {
      listOfDm = [...this.onePrisonList, ...list]
      this.onePrisonList = uniqBy(listOfDm, this.optionsFilter)
    } else {
      listOfDm = [...this.listOfOptions, ...list]
      this.listOfOptions = uniqBy(listOfDm, this.optionsFilter)
    }
    this.listOfOptions = listOfDm

    const { limit, offset } = this.pagination
    if (list.length === limit) {
      this.pagination.offset = offset + limit
    } else {
      this.allDone = true
    }
    this.injector.get(ChangeDetectorRef).detectChanges()
    this.injector.get(ChangeDetectorRef).markForCheck()
  }

  typing(text: string) {
    this.clean()
    this.loadMore(text)
  }

  clean() {
    this.allPrisonList = []
    this.zeroPrisonList = []
    this.onePrisonList = []
    this.listOfOptions = []
    this.pagination = paginationFactory()
    this.allDone = false
  }

  async updateValue(value) {
    this._value = value
  }

  abstract async getOptions(text: string): Promise<OptionModel[]>

  optionsFilter(t: OptionModel) {
    return (t as any).id
  }

  open(isOpen) {
    if (isOpen && !this.listOfOptions?.length) {
      this.loadMore()
    }
  }
}
