import { Component } from '@angular/core'
import { ControlWidget } from '@delon/form'
import { NzTreeNodeOptions } from 'ng-zorro-antd/core/tree'

@Component({
  selector: 'app-sf-origin-tree',
  templateUrl: './origin-tree.component.html',
})
export class OriginTreeComponent extends ControlWidget {

// reset 可以更好的解决表单重置过程中所需要的新数据问题
  reset(value: string) {
    this.setValue(value)
  }

  change(value: string) {
    if (this.ui.change) {
      this.ui.change(value)
    }
    this.setValue(value)
  }

  activedChange(e: NzTreeNodeOptions | NzTreeNodeOptions[]) {
    if (this.ui.actived) {
      this.ui.actived(e)   // 可以在组件里直接调用使用ui的配置那里的方法  ui: {click: (value) => this.test(value),}
    }
  }
}
