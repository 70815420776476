import { treeFormat } from './tree-format'

/**
 * 菜单格式转换成树结构
 * @example
 * ```
 * // 输入
 * [
 *   { text: '工作台', id: 'dashboard', pid: null },
 *   { text: '在拘人员管理', id: 'zjrygl', pid: null },
 *   { text: '系统管理', id: 'setting', pid: null },
 *   { text: '所领导', id: 'sld', pid: null },
 *   { text: '拘所管理', id: 'jsgl', pid: null },
 *   { text: '用户中心', id: 'uc', pid: null },
 *   { text: '入所管理', id: 'rsgl', pid: 'zjrygl' },
 *   { text: '信息维护', id: 'xxwh', pid: 'zjrygl' }
 * ]
 * ```
 *
 */
export const menuTransform = (list: { text: string, id, pid } | Array<{ text: string, id, pid }>, MENUS_CONFIG) => {

  if (!Array.isArray(list)) { return }
  list.map(menu => {
    const menuConfig = MENUS_CONFIG[menu.id] || { link: '' }
    const link = menuConfig.link || ''
    Object.assign(menu, menuConfig, { group: !menuConfig.link, link })
  })

  return [{
    text: '菜单',
    hideInBreadcrumb: true,
    group: true,
    children: treeFormat(list),
  }]
}
