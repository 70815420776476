import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { JqSelectModule } from '@public/components/jq-select/jq-select.module'
import { PrisonSelectModule } from '@public/components/prison-select/prison-select.module'
import { NzInputModule } from 'ng-zorro-antd/input'
import { JailSelectComponent } from './jail-select.component'

@NgModule({
  declarations: [JailSelectComponent],
  exports: [
    JailSelectComponent
  ],
  imports: [
    CommonModule,
    NzInputModule,
    PrisonSelectModule,
    JqSelectModule,
    FormsModule,
  ]
})
export class JailSelectModule {}
