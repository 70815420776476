import { Component } from '@angular/core'
import { ControlWidget } from '@delon/form'

@Component({
  selector: 'app-sf-icon',
  templateUrl: './icon.component.html',
})
export class IconComponent extends ControlWidget {
// reset 可以更好的解决表单重置过程中所需要的新数据问题
  reset(value: string) {
  }

  change(value: string) {
    if (this.ui.change) {
      this.ui.change(value)
    }
    this.setValue(value)
  }

}
