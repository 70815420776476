<nz-input-group nzCompact class="d-flex" [nzSize]="nzSize">
  <div class="d-flex">
    <app-prison-select class="d-flex width-sm" *ngIf="showPrison" [(ngModel)]="prison.prisonsNo"></app-prison-select>
    <app-jq-select class="d-flex width-sm mr-md" [showPrison]="showPrison"
                   [params]="{name:'监区',prisonsNo:prison?.prisonsNo}"
                   (onChanged)="changeJQ($event)"></app-jq-select>
    <app-jq-select class="d-flex width-sm" [showPrison]="showPrison"
                   [params]="{name:'监室',prisonsNo:prison?.prisonsNo,parentCode:parentCode}"
                   (onChanged)="changeJsh($event)"></app-jq-select>
  </div>
</nz-input-group>
