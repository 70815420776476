import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { TLangsModule } from '@scaffold/components/langs'
import { TQuickChatModule } from '@scaffold/components/quick-chat'
import { SettingDrawerModule } from '@scaffold/layout/components/setting-drawer'
import { ThemeBtnModule } from '@scaffold/layout/components/theme-btn'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'
import { NotifyModule } from '../notify'
import { QuickModule } from '../quick'
import { SearchModule } from '../search'
import { UserModule } from '../user'
import { LayoutProHeaderWidgetComponent } from './widget.component'

@NgModule({
  declarations: [LayoutProHeaderWidgetComponent],
  exports: [LayoutProHeaderWidgetComponent],
  imports: [
    CommonModule,
    SearchModule,
    NzToolTipModule,
    NzIconModule,
    TQuickChatModule,
    NotifyModule,
    UserModule,
    TLangsModule,
    QuickModule,
    SettingDrawerModule,
    ThemeBtnModule,
  ],
})
export class WidgetModule {}
