import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import {
  AjvSchemaValidatorFactory,
  DelonFormModule,
  SchemaValidatorFactory,
  WidgetRegistry
} from '@delon/form'
import { CategoryGoodsSelectModule } from '@public/components/category-goods-select'
import { CodeSelectModule } from '@public/components/code-select/code-select.module'
import { ColorPickerModule } from '@public/components/color-picker'
import { FinancialRunningWaterModule } from '@public/components/financial-running-water/financial-running-water.module'
import { GoodsCategorySelectorModule } from '@public/components/goods-category'
import { GoodsSelectModule } from '@public/components/goods-select'
import { IconSelectorModule } from '@public/components/icon-selector'
import { ImageUploadModule } from '@public/components/image-upload'
import { ImageUploadBase64Module } from '@public/components/image-upload-base64'
import { InputRmbModule } from '@public/components/input-rmb'
import { JailSelectModule } from '@public/components/jail-select/jail-select.module'
import { JshSelectModule } from '@public/components/jsh-select'
import { MenuTreeModule } from '@public/components/menu-tree/menu-tree.module'
import { OriginTreeModule } from '@public/components/origin-tree'
import { PrisonSelectModule } from '@public/components/prison-select/prison-select.module'
import { RoleTreeModule } from '@public/components/role-tree/role-tree.module'
import { RySelectModule } from '@public/components/ry-select'
import { RySelectObjModule } from '@public/components/ry-select-obj'
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete'
import { NzGridModule } from 'ng-zorro-antd/grid'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select'
import { CUSTOM_WIDGETS, NzWidgetRegistry } from './widget-registry'

@NgModule({
  imports: [
    DelonFormModule,
    FormsModule,
    CommonModule,
    NzGridModule,
    NzInputModule,
    NzAutocompleteModule,
    NzIconModule,
    MenuTreeModule,
    IconSelectorModule,
    NzTreeSelectModule,
    RoleTreeModule,
    GoodsCategorySelectorModule,
    OriginTreeModule,
    ImageUploadModule,
    RySelectModule,
    GoodsSelectModule,
    RySelectObjModule,
    JailSelectModule,
    CategoryGoodsSelectModule,
    InputRmbModule,
    ColorPickerModule,
    PrisonSelectModule,
    CodeSelectModule,
    ImageUploadBase64Module,
    JshSelectModule,
    FinancialRunningWaterModule,
  ],
  declarations: [...CUSTOM_WIDGETS],
  entryComponents: [...CUSTOM_WIDGETS],
  exports: [...CUSTOM_WIDGETS, DelonFormModule],
})
export class SchemaModule {
  static forRoot(): ModuleWithProviders<SchemaModule> {
    return {
      ngModule: SchemaModule,
      providers: [
        {
          provide: SchemaValidatorFactory,
          useClass: AjvSchemaValidatorFactory,
        },
        { provide: WidgetRegistry, useClass: NzWidgetRegistry },
      ],
    }
  }
}
