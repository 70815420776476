import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { ReuseTabModule } from '@delon/abc/reuse-tab'
import { TQuickChatModule } from '@scaffold/components/quick-chat'
import { NzDrawerModule } from 'ng-zorro-antd/drawer'
import { NzLayoutModule } from 'ng-zorro-antd/layout'
import { NzSpinModule } from 'ng-zorro-antd/spin'
import { FooterModule } from './components/footer'
import { HeaderModule } from './components/header'
import { LogoModule } from './components/logo'
import { MenuModule } from './components/menu'

import { LayoutProComponent } from './layout.component'

@NgModule({
  exports: [LayoutProComponent],
  declarations: [LayoutProComponent],
  imports: [
    NzLayoutModule,
    CommonModule,
    LogoModule,
    MenuModule,
    NzDrawerModule,
    HeaderModule,
    NzSpinModule,
    RouterModule,
    FooterModule,
    TQuickChatModule,
    ReuseTabModule,
  ],
})
export class LayoutProModule {}
