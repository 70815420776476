import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzInputModule } from 'ng-zorro-antd/input'
import { LayoutProWidgetSearchComponent } from './search.component'

@NgModule({
  declarations: [LayoutProWidgetSearchComponent],
  exports: [LayoutProWidgetSearchComponent],
  imports: [
    CommonModule,
    NzIconModule,
    NzInputModule,
    NzAutocompleteModule,
    FormsModule,
    TranslateModule,
  ],
})
export class SearchModule {}
