import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { NgxNotificationMsgComponent } from './ngx-notification-msg.component'

@NgModule({
  entryComponents: [NgxNotificationMsgComponent],
  declarations: [NgxNotificationMsgComponent],
  imports: [CommonModule],
  exports: [NgxNotificationMsgComponent]
})
export class NgxNotificationMsgModule {
}
