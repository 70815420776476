import { Injectable, Injector } from '@angular/core'
import { BaseApi, BaseUrl, Body, DELETE, GET, Path, Payload, POST, PUT, _HttpClient } from '@delon/theme'
import { IResult, IResultWithPagination, Model, PaginationParams } from '@public/interface'
import { Observable } from 'rxjs'

@BaseUrl('/refundAccountApplication')
@Injectable({
  providedIn: 'root'
})
export class HttpTransferService extends BaseApi {

  constructor(injector: Injector, protected http: _HttpClient) {
    super(injector)
  }

  @GET('/page')
  getAllByPage(@Payload body: PaginationParams)
    : Observable<IResultWithPagination<TransferModel>> { return }

  @GET(':id')
  get(@Path('id')id: string): Observable<IResult<TransferModel>> { return }

  @POST()
  add(@Body body: TransferModel): Observable<IResult<TransferModel>> { return }

  @PUT()
  edit(@Body body: TransferModel): Observable<IResult<TransferModel>> { return }

  @DELETE(':id')
  delete(@Path('id')id: string): Observable<IResult<TransferModel>> { return }
}

export interface TransferModel extends Model {
  /** 申请人ID */
  accountId?: string

  /** 在押人姓名 */
  accountName?: string

  /** 审批人 */
  approver?: string

  /** 申请开始时间 */
  createBeginTime?: string

  /** 申请结束时间 */
  createEndTime?: string

  /** 退款金额 */
  money?: number

  /** 审批意见 */
  opinions?: string

  /** 监区、监室 */
  prisonRoom?: string

  /** 监所编号 */
  prisonsNo?: string

  /** 申请理由 */
  reason?: string

  /** 状态（0待审核，1同意，-1拒绝，3待编辑） */
  state?: number

  /** 收款人ID */
  toAccountId?: string

  /** 收款人 */
  toAccountName?: string

  /** 监区、监室 */
  toPrisonRoom?: string

  /** 监区、监室 */
  toPrisonRoomInfo?: string

}
