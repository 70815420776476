import { Component } from '@angular/core'
import { OriginTreeClass } from './origin-tree.class'

@Component({
  selector: 'app-origin-tree',
  template: `
    <nz-tree #nzTreeComponent
      style="width: 240px"
      [nzData]="listOfOriginTree"
      [nzAsyncData]="true"
      (nzClick)="currentData($event)"
      (nzExpandChange)="onExpandChange($event)">
    </nz-tree>
  `,
})
export class OriginTreeComponent extends OriginTreeClass {
}
