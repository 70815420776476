import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { DelonFormModule, WidgetRegistry } from '@delon/form'
import { TImgModule } from '@scaffold/components/img'
import { NzButtonModule } from 'ng-zorro-antd/button'

import { AddressWidget } from './widgets/address/address.widget'
import { ImgWidget } from './widgets/img/img.widget'

export const SCHEMA_THIRDS_COMPONENTS = [ImgWidget, AddressWidget]

@NgModule({
  declarations: SCHEMA_THIRDS_COMPONENTS,
  entryComponents: SCHEMA_THIRDS_COMPONENTS,
  imports: [DelonFormModule.forRoot(), FormsModule, CommonModule, NzButtonModule, TImgModule],
  exports: [...SCHEMA_THIRDS_COMPONENTS],
})
export class JsonSchemaModule {
  constructor(widgetRegistry: WidgetRegistry) {
    widgetRegistry.register(ImgWidget.KEY, ImgWidget)
    widgetRegistry.register(AddressWidget.KEY, AddressWidget)
  }
}
