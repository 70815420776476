import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core'
import { throwIfAlreadyLoaded } from '@scaffold/methods/module-import-guard'
import { Auth } from '@scaffold/providers'
import { StartupService } from './startup.service'

// #region Startup Service
export function StartupServiceFactory(startupService: StartupService) {
  return () => startupService.load()
}

export const APPINIT_PROVIDES = [
  StartupService,
  Auth,
  {
    provide: APP_INITIALIZER,
    useFactory: StartupServiceFactory,
    deps: [StartupService],
    multi: true,
  },
]

// #endregion

@NgModule({ providers: APPINIT_PROVIDES })
export class StartupModule {
  constructor(@Optional() @SkipSelf() parentModule: StartupModule) {
    throwIfAlreadyLoaded(parentModule, 'StartupModule')
  }
}
