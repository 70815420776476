import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core'
import { RouteReuseStrategy } from '@angular/router'
import { ReuseTabService, ReuseTabStrategy } from '@delon/abc/reuse-tab'
import { DelonACLModule } from '@delon/acl'
import { DelonMockModule } from '@delon/mock'
import { AlainThemeModule } from '@delon/theme'
import { AlainConfig, ALAIN_CONFIG } from '@delon/util'
import { throwIfAlreadyLoaded } from '@scaffold/methods/module-import-guard'
import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config'

const alainConfig: AlainConfig = {
  st: { modal: { size: 'md' } },
  sf: {
    errors: { required: null },
    uiDateStringFormat: 'YYYY/MM/DD HH:mm:ss',
  },
  pageHeader: {
    homeI18n: '首页',
  },
  auth: {
    login_url: '/passport/login',
    token_send_place: 'header',
    token_send_key: 'Authorization',
  },
  themeHttp: {
    nullValueHandling: 'ignore',
    dateValueHandling: 'ignore',
  },
  acl: {
    guard_url: '/exception/403',
    preCan: (roleOrAbility) => {
      const str = (roleOrAbility || '').toString()
      return str.startsWith('/') ? { ability: [str] } : null
    }
  }
}

const alainModules = [AlainThemeModule.forRoot(), DelonACLModule.forRoot(), DelonMockModule.forRoot()]
const alainProvides = [
  { provide: ALAIN_CONFIG, useValue: alainConfig },
  // #region reuse-tab
  {
    provide: RouteReuseStrategy,
    useClass: ReuseTabStrategy,
    deps: [ReuseTabService],
  },
  // #endregion
]

const ngZorroConfig: NzConfig = {
  card: {
    nzBordered: false
  }
}
const zorroProvides = [{ provide: NZ_CONFIG, useValue: ngZorroConfig }]

// #endregion

@NgModule({
  imports: [...alainModules],
})
export class GlobalConfigModule {
  constructor(@Optional() @SkipSelf() parentModule: GlobalConfigModule) {
    throwIfAlreadyLoaded(parentModule, 'GlobalConfigModule')
  }

  static forRoot(): ModuleWithProviders<GlobalConfigModule> {
    return {
      ngModule: GlobalConfigModule,
      providers: [...alainProvides, ...zorroProvides],
    }
  }
}
