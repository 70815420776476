/**
 * 树转换
 */
export function treeFormat(
  sNodes: any[],
  setting: { idKey?: string, parentKey?: string, childKey?: string } = {
    idKey: 'id',
    parentKey: 'pid',
    childKey: 'children'
  }
): any[] {
  const key = setting.idKey || 'id'
  const parentKey = setting.parentKey || 'pid'
  const childKey = setting.childKey || 'children'

  if (!key || key === '' || !sNodes) {
    return []
  }

  let index
  const r = []
  const tmpMap = {}
  index = 0
  for (length = sNodes.length; index < length; index++) {
    tmpMap[sNodes[index][key]] = sNodes[index]
  }
  index = 0
  for (length = sNodes.length; index < length; index++) {
    if (tmpMap[sNodes[index][parentKey]] && sNodes[index][key] !== sNodes[index][parentKey]) {
      if (!tmpMap[sNodes[index][parentKey]][childKey]) {
        tmpMap[sNodes[index][parentKey]][childKey] = []
      }
      tmpMap[sNodes[index][parentKey]][childKey].push(sNodes[index])
    } else {
      r.push(sNodes[index])
    }
  }
  return r

}
