<!--Search-->
<!--<layout-pro-search class="hidden-xs"></layout-pro-search>-->
<!--Link-->
<!--<a-->
<!--  nz-tooltip-->
<!--  nzTooltipTitle="使用文档"-->
<!--  nzTooltipPlacement="bottom"-->
<!--  target="_blank"-->
<!--  rel="noopener noreferrer"-->
<!--  class="alain-pro__header-item"-->
<!--&gt;-->
<!--  <i nz-icon nzType="question-circle"></i>-->
<!--</a>-->
<!--Quick chat status-->
<!--<quick-chat-status class="hidden-xs"></quick-chat-status>-->
<!--Notify-->
<!--<layout-pro-notify class="hidden-xs"></layout-pro-notify>-->
<!--User-->
<layout-pro-user></layout-pro-user>
<!--Languages-->
<!--<pro-langs></pro-langs>-->
<!--Quick panel-->
<!--<layout-pro-quick class="hidden-xs"></layout-pro-quick>-->
<!--<layout-theme-btn></layout-theme-btn>-->
<!--<pro-setting-drawer></pro-setting-drawer>-->
