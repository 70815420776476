import { AES } from 'crypto-js'
import * as Utf8 from 'crypto-js/enc-utf8'
import * as ECB from 'crypto-js/mode-ecb'
import * as Pkcs7 from 'crypto-js/pad-pkcs7'

export function aesDecode(text: string): string {
  const keyHex = Utf8.parse('yunqishang.1988@')
  const bytes = AES.decrypt(text, keyHex, {
    mode: ECB,
    padding: Pkcs7
  })
  return bytes.toString(Utf8)
}

export function aesEncode(text: string): string {
  // utf8字符串—>WordArray对象，WordArray是一个保存32位整数的数组，相当于转成了二进制
  const keyHex = Utf8.parse('yunqishang.1988@') //
  const messageHex = Utf8.parse(text)
  const encrypted = AES.encrypt(messageHex, keyHex, {
    mode: ECB,
    padding: Pkcs7
  })
  return encrypted.toString()// base64结果
}
