import { NgModule } from '@angular/core'

import { CommonModule } from '@angular/common'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzDividerModule } from 'ng-zorro-antd/divider'
import { NzGridModule } from 'ng-zorro-antd/grid'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzPopoverModule } from 'ng-zorro-antd/popover'
import { IconSelectorComponent } from './icon-selector.component'

@NgModule({
  imports: [
    NzButtonModule,
    CommonModule,
    NzIconModule,
    NzPopoverModule,
    NzDividerModule,
    NzGridModule
  ],
  exports: [IconSelectorComponent],
  declarations: [IconSelectorComponent],
  providers: [],
})
export class IconSelectorModule {}
