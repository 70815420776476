import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'
import { NzAlertModule } from 'ng-zorro-antd/alert'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzWaveModule } from 'ng-zorro-antd/core/wave'
import { NzDividerModule } from 'ng-zorro-antd/divider'
import { NzDrawerModule } from 'ng-zorro-antd/drawer'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { NzSwitchModule } from 'ng-zorro-antd/switch'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'

import { ProSettingDrawerComponent } from './setting-drawer.component'

@NgModule({
  exports: [ProSettingDrawerComponent],
  declarations: [ProSettingDrawerComponent],
  imports: [
    NzDrawerModule,
    TranslateModule,
    NzToolTipModule,
    CommonModule,
    NzIconModule,
    NzSelectModule,
    FormsModule,
    NzSwitchModule,
    NzDividerModule,
    NzAlertModule,
    NzWaveModule,
    NzButtonModule,
  ],
})
export class SettingDrawerModule {}
