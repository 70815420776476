import { Injectable, Injector } from '@angular/core'
import { BaseApi, BaseUrl, DELETE, GET, Path, Payload, POST, PUT, Query } from '@delon/theme'
import { IResult, IResultWithPagination, Model, PaginationParams } from '@public/interface'
import { Observable } from 'rxjs'

@BaseUrl('/accountGoodsLimit')
@Injectable({
  providedIn: 'root'
})
export class HttpGoodsLimitService extends BaseApi {

  constructor(injector: Injector) { super(injector) }

  @GET('/page')
  getAllByPage(@Payload body: PaginationParams & GoodsLimitModel)
    : Observable<IResultWithPagination<GoodsLimitModel>> { return }

  @GET('/all')
  getAllOfTreeData(@Payload body: PaginationParams & GoodsLimitModel)
    : Observable<IResultWithPagination<GoodsLimitModel>> { return }

  @GET('/pageDiscipline')
  getAllByPageDiscipline(@Payload body: PaginationParams & GoodsLimitModel)
    : Observable<IResultWithPagination<GoodsLimitModel>> { return }

  @GET(':id')
  getById(@Path('id')id): Observable<IResult<GoodsLimitModel>> { return }

  @POST()
  addGoodsLimit(@Payload body: GoodsLimitModel)
    : Observable<IResult<string>> { return }

  @PUT()
  editGoodsLimit(@Payload body: GoodsLimitModel)
    : Observable<IResult<GoodsLimitModel>> { return }

  @PUT('/removeAccountGoodsLimit')
  removeAccountGoodsLimit(@Payload body: GoodsLimitModel)
    : Observable<IResult<GoodsLimitModel>> { return }

  @PUT('/updateRemoveState')
  updateRemoveState(
    @Query('id') id: string,
    @Query('goodsId') goodsId: string,
    @Query('accountId') accountId: string,
    @Query('removeApplyReason') removeApplyReason: string,
  )
    : Observable<IResult<string>> { return }

  @DELETE(':id')
  delete(@Path('id')id): Observable<IResult<GoodsLimitModel>> { return }

}

export interface GoodsLimitModel extends Model {
  /** 在押人ID */
  accountId?: string

  /** 在押人姓名 */
  accountName?: string

  /** 审批状态（0待审批，1同意，-1拒绝） */
  approveState?: number

  /** 审批人 */
  approver?: string

  /** 商品种类ID */
  cateId?: string

  /** 申请开始时间 */
  createBeginTime?: string

  /** 申请结束时间 */
  createEndTime?: string

  /** 商品ID */
  goodsId?: string

  /** 审批意见 */
  opinions?: string

  /** 监区、监室 */
  prisonRoom?: string

  /** 监所编号 */
  prisonsNo?: string

  /** 申请人 */
  proposer?: string

  /** 申请理由 */
  reason?: string

  /** 解除限制申请理由 */
  removeApplyReason?: string

  /** 解除限制申请时间 */
  removeApplyTime?: string

  /** 解除限制审批理由 */
  removeApproveReason?: string

  /** 解除限制审批状态 */
  removeApproveState?: number

  /** 解除限制审批时间 */
  removeApproveTime?: string

  /** 解除限制审批人 */
  removeApprover?: string

  /** 解除限制申请人 */
  removeProposer?: string
}
