import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { InputRmbComponent } from './input-rmb.component'

@NgModule({
  declarations: [InputRmbComponent],
  exports: [InputRmbComponent],
  imports: [
    CommonModule,
    NzIconModule,
    FormsModule
  ]
})
export class InputRmbModule {}
