import { Injectable } from '@angular/core'
import { BaseApi, BaseUrl, Body, GET, Payload, PUT, POST } from '@delon/theme'
import { IResult, Model, PaginationBody, PaginationParams } from '@public/interface'
import { Observable } from 'rxjs'
import { PrisonModel } from './http-prison.service'

@BaseUrl('/refundApplication')
@Injectable({
  providedIn: 'root'
})
export class HttpRefundApplication extends BaseApi {

  @GET('/page')
  getAllByPage(@Payload body): Observable<IResult<PaginationBody<RefundApplicationModel>>> { return }

  @PUT()
  approve(@Body body): Observable<IResult> { return }

  @GET('prison/all')
  getPrison(@Payload body: PaginationParams): Observable<IResult<PrisonModel[]>> { return }

  @POST('/refundableAmount/add')
  refundableAmountAdd(@Payload body: any): Observable<IResult<any>> { return }

  @POST('/refundableAmount/update')
  refundableAmountUpdate(@Payload body: any): Observable<IResult<any>> { return }

  @GET('refundableAmount/list')
  refundableAmountList(@Payload body: any): Observable<IResult<any>> { return }
}

export interface RefundApplicationModel extends Model {
  /** 关联在押人id */
  accountId?: string

  /** 在押人姓名 */
  accountName?: string

  /** 审批人 */
  approver?: string

  /** 家属银行卡号 */
  bankcardNo?: string

  /** 申请开始时间 */
  createBeginTime?: string

  /** 申请结束时间 */
  createEndTime?: string

  /** 申请时间 */
  createTime?: string

  /** id */
  id?: string

  /** 家属ID */
  memberId?: string

  /** 原路返回金额 */
  money?: string

  /** 审批意见 */
  opinions?: string

  /** 监区、监室 */
  prisonRoom?: string

  /** 监所编号 */
  prisonsNo?: string

  /** 申请理由 */
  reason?: string

  /** 状态（0待审核，1同意，-1拒绝） */
  state?: 0 | 1 | -1

  /** 审批时间 */
  updateTime?: string

}
