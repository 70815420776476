import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select'
import { RoleTreeComponent } from './role-tree.component'

@NgModule({
  declarations: [RoleTreeComponent],
  exports: [
    RoleTreeComponent
  ],
  imports: [
    CommonModule,
    NzTreeSelectModule,
    FormsModule
  ]
})
export class RoleTreeModule {}
