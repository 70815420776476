import { Injectable, Injector } from '@angular/core'
import { BaseApi, BaseUrl, Body, GET, Path, Payload, POST, PUT, _HttpClient, } from '@delon/theme'
import { IResult, IResultWithPagination, Model, PaginationBody, PaginationParams } from '@public/interface'
import { OrderModel } from '@public/providers/http/http-store.service'
import { Observable } from 'rxjs'

@BaseUrl('/finance')
@Injectable({ providedIn: 'root' })
export class HttpFinance extends BaseApi {

  constructor(injector: Injector, protected http: _HttpClient) {
    super(injector)
  }

  @POST('/partialReturn/approve')
  getApprove(@Body body): Observable<IResult> { return }

  @GET('/sum')
  getSumOfDetachment(@Payload body: PaginationParams & FinanceModel)
    : Observable<IResultWithPagination<FinanceModel>> { return }

  @GET('/sumByPrisonNo')
  getSumOfJail(@Payload body: PaginationParams & FinanceModel)
    : Observable<IResultWithPagination<FinanceModel>> { return }

  @GET('/sumByRegional')
  getSumOfPrison(@Payload body: PaginationParams & FinanceModel)
    : Observable<IResultWithPagination<FinanceModel>> { return }

  @GET('/sumByPrisonRoom')
  getSumOfRoom(@Payload body: PaginationParams & FinanceModel)
    : Observable<IResultWithPagination<FinanceModel>> { return }

  @GET('/sumByPrison')
  getSumOfPrisoners(@Payload body: PaginationParams & FinanceModel)
    : Observable<IResultWithPagination<FinanceModel>> { return }

  @GET('/sumAll')
  getSumOfAll(@Payload body: { prisonsNo: string })
    : Observable<IResult<FinanceModel>> { return }

  @PUT('/approverPay')
  approverPay(@Payload body: FinanceModel)
    : Observable<IResult<FinanceModel>> { return }

  // 2020-07-16 查询所内购物
  @GET('/getDisciplineNoConfirmOrderList')
  getAllByInside(@Payload body): Observable<IResult<PaginationBody<FinanceModel>>> { return }

  @GET('/sumOrderNum')
  getAllNum(@Payload body): Observable<IResult> { return }

  // 2020-07-16 查询家属购物
  @GET('/getDisciplineNoConfirmFamilyOrderList')
  getAllByFamily(@Payload body): Observable<IResult<PaginationBody<FinanceModel>>> { return }

  // 2020-07-16 管教审批所内购物
  @PUT('/disciplineConfirm/:id')
  createBasicMessageIn(@Path('id') id): Observable<IResult> { return }

  // 2020-07-16 管教审批家属购物
  @PUT('/disciplineNoConfirmFamilyOrder/:ids')
  createBasicMessage(@Path('ids') id): Observable<IResult> { return }

  // 2020-07-16 管教拒绝所内购物
  @POST('/cancel')
  approvalRejectIn(@Body body): Observable<IResult> { return }

  // 2020-07-16 管教拒绝家属购物
  @POST('/cancelFamilyOrder')
  approvalReject(@Body body): Observable<IResult> { return }

  @GET('/pageAllOrder')
  getAllByPage(
    @Payload body: PaginationParams & FinanceModel & { beginTime: string, endTime: string })
    : Observable<IResultWithPagination<FinanceModel>> { return }

  @GET('/sumByPrison')
  getSumByPrison(
    @Payload body: PaginationParams & FinanceModel & { beginTime: Date, endTime: Date })
    : Observable<IResultWithPagination<FinanceModel>> { return }

  @GET('/orderDetail')
  getOrderDetail(@Payload body: FinanceModel & PaginationParams): Observable<IResult<FinanceDetailModel[]>> { return }

  @GET('/dateil/:id')
  getById(@Path('id') id)
    : Observable<IResultWithPagination<FinanceModel> | IResult<FinanceModel[]>> { return }

  @GET('/getOnePersonList')
  getShoppingList(
    @Payload body: PaginationParams & FinanceModel & { beginTime: Date, endTime: Date })
    : Observable<IResultWithPagination<FinanceModel>> { return }

  @GET('/getPreDeliveryConfirmation')
  getPreDeliveryConfirmation(
    @Payload body: PaginationParams): Observable<IResultWithPagination<FinanceModel>> { return }

  @PUT('/preDeliveryConfirmation')
  edit(@Payload body: PaginationParams): Observable<IResultWithPagination<FinanceModel>> { return }

  @GET('/getAlikeCateGoodsOrder')
  getAlikeCateGoodsOrder(@Payload body: PaginationParams & FinanceDetailModel)
    : Observable<IResult<FinanceDetailModel[]>> { return }

  @POST('/cancelFamilyOrderGoods')
  cancelFamilyOrderGoods(@Payload body: FinanceModel): Observable<IResult<FinanceModel[]>> { return }

  @GET('/getFinanceConfirmData')
  getFinanceConfirmData(@Payload queryParams: FinanceCWSPQueryParams)
    : Observable<IResult<FinanceCWSPModel[]>> { return }

  @GET('/getFinanceConfirmDataNew')
  getFinanceConfirmDataNew(@Payload queryParams: FinanceCWSPQueryParams)
    : Observable<IResult<FinanceCWSPModel[]>> { return }

  /** 管教调整在押人购物数量 雷 */
  @PUT('/disciplineChangeConfirm')
  disciplineChangeConfirm(@Payload body: { id, bfinanceDetailDOS: { id, num }[] }): Observable<IResult> { return }

  /** 所内财务确认购物 雷 */
  @PUT('/financeConfirm')
  financeConfirm(): Observable<IResult> { return }

  /** 支队财务确认购物 雷 */
  @PUT('/bigFinanceConfirm')
  bigFinanceConfirm(): Observable<IResult> { return }

  /** 根据条件统计导出 超市监管共用 雷 */
  @GET('/getGoodOrdersExcel')
  getGoodOrdersExcel(@Payload params: { prisonArea?, prisonRoom?, prisonsNo?, type?, yearMonth?})
    : Observable<IResult<GoodOrdersExcelDataModel>> { return }

  // /** 根据条件统计 超市监管共用 雷 */
  // @GET('/getGoodOrdersExcelData')
  // getGoodOrdersExcelData(@Payload params: { prisonArea?, prisonRoom?, prisonsNo?, type?, yearMonth? })
  //   : Observable<IResult<GoodOrdersExcelDataModel>> { return }

  /** 获取3天未收货订单 雷 */
  @GET('/getNoDeliveryOrder')
  getNoDeliveryOrder(@Payload params: { days?, payer?, prisonRoom?}): Observable<IResult> { return }

  /** 获取管教提交的收货确认 所领导审批 雷 */
  @GET('/getLeaderConfirmDeliveryOrder')
  getLeaderConfirmDeliveryOrder(@Payload params: { payer?, prisonRoom, state?}): Observable<IResult> { return }

  /** 未收货订单管教发起收货 雷 */
  @PUT('/disciplineDeliveryOrder')
  disciplineDeliveryOrder(@Payload body: { id, type }[]): Observable<IResult> { return }

  /** 未收货订单所领导审批收货 雷 */
  @PUT('/leaderDeliveryOrder')
  leaderDeliveryOrder(@Payload body): Observable<IResult> { return }

  /** 根据id获取购物订单 雷 */
  @GET('/getOrderById/:id')
  getOrderById(@Path('id') id): Observable<IResult<OrderDetailModel>> { return }


  /** 根据id获取购物订单 雷 */
  @GET('/partialReturn/getOrderDetailById')
  getOrderDetailById(@Payload body): Observable<IResult<OrderDetailModel>> { return }

  /** 根据条件获取所内购物记录 雷 */
  @GET('/getOrderBySome')
  getOrderBySome(@Payload params: { beginTime?, endTime?, goodsId?, goodsName?, name?, payer?, prisonRoom?, prisonsNo?, state?}
    & PaginationParams): Observable<IResultWithPagination<OrderModel>> { return }

  /** 根据条件获取所内购物记录 雷 */
  @GET('/getOrderBySome2')
  getOrderBySome2(@Payload params: {
    beginTime?
    endTime?
    goodsId?
    goodsName?
    name?
    payer?
    prisonRoom?
    prisonsNo?
    state?
  } & PaginationParams): Observable<IResultWithPagination<OrderModel>> {
    return
  }

  @GET('/partialReturn/list')
  getPartialReturn(@Payload body: any): Observable<IResultWithPagination<any>> { return }

  /** 获取购物变动信息 雷 */
  @GET('/getShoppingOrderChangeData')
  getShoppingOrderChangeData(@Payload body): Observable<IResultWithPagination<OrderModel>> { return }

  /** 后勤发货家属获取订单 雷 */
  @GET('/getGoodOrdersExcelDataForFamily')
  LogisticsOrderFamily(@Payload body): Observable<IResult<FinanceModel>> { return }

  /** 后勤发货所内属获取订单 雷 */
  @GET('/getGoodOrdersData')
  getGoodOrdersData(@Payload body): Observable<IResult> { return }

  /** 后勤 订单详情 家属购物 申诉 雷 */
  @GET('/mobile/dateil/:id')
  getDetailByJiaShu(@Path('id') id: string): Observable<IResult<OrderDetailModel[]>> { return }

  /** 后勤 订单详情 所内购物 申诉 雷 */
  @GET('/dateil/:id')
  getDetailBySuoNei(@Path('id') id: string): Observable<IResult<OrderDetailModel[]>> { return }

}

export interface FinanceModel extends Model {
  /** 财务表id */
  financeId?: string

  /** 商品id */
  goodId?: string

  /** 商品名称 */
  goodName?: string

  /** 商品单价 */
  goodPrice?: boolean

  /** 数量 */
  num?: number

  /** 总价 */
  total?: string

  /** 单位 */
  unit?: number

  /** 审批人 */
  approver?: string

  /** 审批时间 */
  approverTime?: string

  /** 购物订单详情 */
  bfinanceDetailDOS?: FinanceDetailModel[]

  /** 收货人 */
  consignee?: string

  /** 送达时间 */
  deliveredTime?: string

  /** 送收货状态 100下单，101待送达，102已送达，103已完成 */
  deliveredType?: number

  /** 财务类型（字典） */
  financeType?: string

  /** 金额  */
  money?: number

  /** 名字 */
  name?: string

  /** 付款时间 */
  payTime?: string

  /** 付款方式 */
  payType?: string

  /** 收款人 */
  payee?: string

  /** 付款人 */
  payer?: string

  /** 实付金额 */
  practicalMoney?: number

  prisonName?: string

  prisonRoom?: string

  /** 付款时间 */
  prisonRoomName?: string

  /** 在押监所编号 */
  prisonsNo?: string

  /** 退款原因 */
  refundDetail?: string

  /** 退款时间 */
  refundTime?: string

  /** 流水号 */
  serialNumber?: string

  /** 购物记录 0待付款，1已付款，-1取消，2退款，3为已确认，4订单超时 */
  /** 账务流水 1所内购物，2家属购物，3代购，4家属转账，5退赃，6所内转账出,7所内转账入 */
  state?: string

  /** 管教确认 0未审批，1确认购物，-1拒绝 */
  disciplineConfirm?: number

}

export interface FinanceDetailModel extends Model {
  /** 财务表id */
  financeId?: string

  /** 商品id */
  goodId?: string

  /** 商品名称 */
  goodName?: string

  /** 商品单价 */
  goodPrice?: number

  /** 商品图片 */
  imgs?: string

  /** 数量 */
  num?: number

  /** 监所编号 */
  prisonsNo?: string

  /** 总价 */
  total?: number

  /** 单位 */
  unit?: string

}

export interface FinanceCWSPModel {
  imgs: string
  goodsId: string
  goodsName: string
  goodsPrice: number
  num: number
  total: number
  unit: string
}

export interface FinanceCWSPQueryParams extends PaginationParams {
  type?: FinanceCWSPType
}

export type FinanceCWSPType =
  /** 所内财务 */
  1
  /** 支队财务 */
  | 2

export interface GoodOrdersExcelDataModel {
  goodName?: string[]
  goodPrice?: number[]
  name?: string[]
  prisonRoom?: string
  prisonRooms?: string[]
  result?: { key, value: any }
  resultData?: { name: string, goodsData: { goodsName: string, num: number }[] }[]
  resultGoodsDatas?: { name: string, total: number, goodsData: { goodsName: string, num: number }[] }[]
  total?: number[]
  yearMonth?: string
  goods?: { goodsName?: string, price?: number }[]
}

export interface OrderDetailModel extends Model {
  payer: string
  payee: string
  consignee: string
  payType: string
  money: number
  practicalMoney: string
  payTime: string
  state: number
  approver: string
  approverTime: string
  createTime: string
  updateTime: string
  serialNumber: string
  prisonsNo: string
  financeType: string
  deliveredType: number
  deliveredTime: string
  refundTime: string
  refundDetail: string
  deliveredNumber: string
  disciplineConfirm: number
  disciplineConfirmTime: string
  disciplineConfirmDetail: string
  consigneeConfirm: number
  consigneeConfirmTime: string
  leaderConfirm: number
  leaderConfirmTime: string
  paymentRecordId: string
  financeConfirm: number
  financeConfirmTime: string
  bigFinanceConfirm: number
  bigFinanceConfirmTime: string
  disciplineName: string
  leaderName: string
  financeName: string
  bigFinanceName: string
  name: string
  prisonRoom: string
  prisonRoomName: string
  prisonName: string
  bfinanceDetailDOS: OrderModel[]
  shoppingFamilyDetailDOS: OrderModel[]
}

/** 订单状态 */
export type OrderState =
  /** 冻结 */
  0 |
  /** 已付款 */
  1 |
  /** 已取消 */
  -1 |
  /** 已退款 */
  2 |
  /** 已确认 */
  3
