<sf-item-wrap [id]="id" [schema]="schema" [ui]="ui" [showError]="showError" [error]="error"
              [showTitle]="!!schema.title">
  <!-- 开始自定义控件区域 -->
  <app-jail-select [ngModel]="value"
                   (ngModelChange)="change($event)"
                   [disabled]="schema?.readOnly"
                   [showPrison]="ui?.params"
  ></app-jail-select>
  <!-- 结束自定义控件区域 -->
</sf-item-wrap>
