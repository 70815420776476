import { Injectable, Injector } from '@angular/core'
import { BaseApi, BaseUrl, DELETE, GET, Path, Payload, POST, PUT } from '@delon/theme'
import { IResult, IResultWithPagination, Model, PaginationParams } from '@public/interface'
import { Observable } from 'rxjs'

@BaseUrl('/refundApplication')
@Injectable({
  providedIn: 'root'
})
export class HttpRefundService extends BaseApi {

  constructor(injector: Injector) { super(injector) }

  @GET('/page')
  getAllByPage(@Payload body: PaginationParams & RefundModel)
    : Observable<IResultWithPagination<RefundModel>> { return }

  @GET(':id')
  getById(@Path('id')id): Observable<IResult<RefundModel>> { return }

  @POST()
  add(@Payload body: RefundModel): Observable<IResult<RefundModel>> { return }

  @PUT()
  edit(@Payload body: RefundModel): Observable<IResult<RefundModel>> { return }

  @DELETE(':id')
  delete(@Path('id')id): Observable<IResult<RefundModel>> { return }

}

export interface RefundModel extends Model {
  /** 在押人ID */
  accountId?: string

  /** 在押人姓名 */
  accountName?: string

  /** 审批人 */
  approver?: string

  /** 家属银行卡号 */
  bankcardNo?: string

  /** 申请开始时间 */
  createBeginTime?: string

  /** 申请结束时间 */
  createEndTime?: string

  /** 家属ID */
  memberId?: string

  /** 原路返回金额 */
  money?: number

  /** 审批意见 */
  opinions?: string

  /** 监区、监室 */
  prisonRoom?: string

  /** 监所编号 */
  prisonsNo?: string

  /** 申请理由 */
  reason?: string

  /** 状态（0待审核，1同意，-1拒绝） */
  state?: number
}
