import { Component, forwardRef, OnInit, ViewChild } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { manifest, ThemeType } from '@ant-design/icons-angular'
import { categories, ICON_ZH_CN } from '@public/components/icon-selector/icons'
import { NzPopoverDirective } from 'ng-zorro-antd/popover'

@Component({
  selector: 'app-icon-selector',
  templateUrl: './icon-selector.component.html',
  styles: [`
    .icon-item {
      color: #8C8C8C
    }

    .icon-item:hover {
      color: #1064bf;
      background-color: rgba(72, 80, 113, 0.1);
    }`],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => IconSelectorComponent),
    multi: true
  }]
})
export class IconSelectorComponent implements ControlValueAccessor, OnInit {
  @ViewChild('popoverCom', { static: false, read: NzPopoverDirective }) popoverCom: NzPopoverDirective

  get value() {
    return this._value
  }

  set value(value) {
    this._value = value
    typeof this._change === 'function' && this._change(value)
  }

  private _value

  _change: (...t: any) => void
  _touch: (...t: any) => void

  iconTheme: ThemeType = 'outline'

  icons: { name, icons, text }[] = []

  registerOnChange(fn): void {
    this._change = fn
  }

  registerOnTouched(fn): void {
    this._touch = fn
  }

  writeValue(value: string): void {
    this._value = value
  }

  setValue(value: string) {
    this.value = value
    this.popoverCom.hide()
  }

  ngOnInit(): void {
    const { iconTheme } = this
    const currentThemeIcons = (manifest[iconTheme] as string[]).filter((name: string) => !['interation', 'canlendar'].includes(name))

    let notEmptyCategories = Object.keys(categories).map(category => ({
      name: category,
      text: ICON_ZH_CN[category],
      icons: categories[category].filter((name: string) => currentThemeIcons.indexOf(name) > -1)
    }))

    const otherIcons = currentThemeIcons
      .filter(icon => {
        return notEmptyCategories.filter(({ name }) => name !== 'all').every(item => !item.icons.includes(icon))
      })

    notEmptyCategories.push({ name: 'other', text: ICON_ZH_CN.other, icons: otherIcons })
    notEmptyCategories = notEmptyCategories.filter(({ icons }) => Boolean(icons.length))

    this.icons = notEmptyCategories
  }
}
