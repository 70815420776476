import { GLOBAL_ENVIRONMENT } from '@environments'

export const environment = {
  ...GLOBAL_ENVIRONMENT,
  FILE_URL: 'http://139.224.58.168:9008/capitalSupervisionSystem',
  SERVER_URL: 'http://139.224.58.168:9008/capitalSupervisionSystem',
  production: false,
  useHash: true,
  APPID: 2,
  passportBackground: '#ff736a',
  passportBanner: '/assets/images/supplier.png'
}
