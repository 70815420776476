import { Injectable, Injector } from '@angular/core'
import { BaseApi, BaseUrl, Body, DELETE, GET, Path, Payload, PUT } from '@delon/theme'
import { IResult, Model, PaginationBody, PaginationParams } from '@public/interface'
import { Observable } from 'rxjs'
import { PrisonModel } from './http-prison.service'

@BaseUrl('/memberPrisoners')
@Injectable({
  providedIn: 'root'
})
export class HttpMemberPrisoners extends BaseApi {

  constructor(injector: Injector) {
    super(injector)
  }

  @GET('/page')
  getAllByPage(@Payload body): Observable<IResult<PaginationBody<MemberPrisonerModel>>> { return }

  @PUT()
  approve(@Body body): Observable<IResult> { return }

  @GET('prison/all')
  getPrison(@Payload body: PaginationParams): Observable<IResult<PrisonModel[]>> { return }

  @DELETE(':id')
  removeById(@Path('id')id: number | string)
    : Observable<IResult<MemberPrisonerModel>> { return }
}

export interface MemberPrisonerModel extends Model {
  /** 在押人审批状态：0待审批，1同意，-1拒绝 */
  accountApproveState?: 0 | 1 | -1,

  /** 在押人审批时间 */
  accountApproveTime?: string

  /** 关联在押人id */
  accountId?: string

  /** 在押人姓名 */
  accountName ?: string

  /** 申请开始时间 */
  createBeginTime ?: string

  /** 申请结束时间 */
  createEndTime ?: string

  /** 创建时间 */
  createTime?: string

  /** id */
  id?: string

  /** 审批意见 */
  opinions?: string

  /** 监区、监室 */
  prisonRoom?: string

  /** 关联家属id */
  memberId?: string

  /** 监所（管教）审批状态：0待审批，1同意，-1拒绝 */
  prisonsApproveState?: 0 | 1 | -1

  /** 监所（管教）审批时间 */
  prisonsApproveTime?: string

  /** 审批人 */
  prisonsApprover?: string

  /** 监所编号 */
  prisonsNo?: string

  /** 与在押人关系 */
  relationship?: string
}
