import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { DelayDirective } from './delay.directive'

@NgModule({
  declarations: [DelayDirective],
  exports: [DelayDirective],
  imports: [CommonModule],
})
export class TDelayModule {
}
