<!--<input nz-input [(ngModel)]="value">-->
<nz-button-group class="d-inline-flex">
  <button type="button" nz-button style="min-width: 60px">
    <i *ngIf="value;else empty" nz-icon [nzType]="value" [nzTheme]="iconTheme"></i>
    <ng-template #empty><span class="op-6">请选择</span></ng-template>
  </button>
  <button #popoverCom type="button" nz-button nz-popover [nzPopoverContent]="popper" nzPopoverTrigger="click"
          nzPopoverPlacement="bottom">
    <i nz-icon nzType="caret-down" nzTheme="fill"></i>
  </button>
</nz-button-group>

<ng-template #popper>
  <div style="width: 316px;height: 400px;" class="overflow-auto">

    <ng-container *ngFor="let item of icons">
      <nz-divider [nzText]="item.text"></nz-divider>
      <div class="d-flex align-items-center flex-wrap">
        <a class="icon-item" nz-col nzSpan="6" *ngFor="let icon of item.icons" (click)="setValue(icon)"
           style=" text-align: center;font-size: 18px;width: 50px;padding: 10px;">
          <i nz-icon [nzType]="icon" [nzTheme]="iconTheme"></i>
        </a>
      </div>
    </ng-container>
  </div>
</ng-template>
