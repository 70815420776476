import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { GoodsCategorySelectorModule } from '@public/components/goods-category'
import { ImageUploadModule } from '@public/components/image-upload'
import { NzDividerModule } from 'ng-zorro-antd/divider'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { CategoryGoodsSelectComponent } from './category-goods-select.component'

@NgModule({
  declarations: [CategoryGoodsSelectComponent],
  exports: [CategoryGoodsSelectComponent],
  imports: [
    CommonModule,
    NzInputModule,
    GoodsCategorySelectorModule,
    NzSelectModule,
    FormsModule,
    ImageUploadModule,
    NzDividerModule
  ]
})
export class CategoryGoodsSelectModule {}
