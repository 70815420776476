import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { SelectAsync } from '@public/components/select-async'
import { HttpPrisonRoom, PrisonRoomModel } from '@public/providers/http'
import { map } from 'rxjs/operators'

@Component({
  selector: 'app-jq-select',
  templateUrl: './jq-select.component.html',
  styleUrls: ['./jq-select.component.less'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => JqSelectComponent),
    multi: true
  }]
})
export class JqSelectComponent extends SelectAsync<string, PrisonRoomModel> implements OnInit {
  @Input() showPrison

  @Input() set params(params) {
    if (params?.name === '监区') {
      this._params = params
      this.clean()
      this.value = null
    }
  }

  get params() { return this._params }

  _params

  constructor(inject: Injector, private httpJshSelect: HttpPrisonRoom) {
    super(inject)
  }

  async getOptions(text: string): Promise<PrisonRoomModel[]> {
    return this.httpJshSelect.getAllByPage({
      ...this.params,
      ...this.pagination,
    })
      .pipe(map(t => t.response?.records))
      .toPromise()
  }

  async updateValue(value): Promise<void> {
    super.updateValue(value)
  }

  nzScrollToBottom() {
    super.loadMore()
  }
}
