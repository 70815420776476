import { Injectable } from '@angular/core'
import { SettingsService } from '@delon/theme'
import { ArrayService } from '@delon/util'
import { TranslateService } from '@ngx-translate/core'
import { HttpMenuService, MenuModel } from '@public/providers/http/http-menu.service'
import { asyncCatch, isResultFaild } from '@scaffold/methods'
import { cloneDeep, sortBy } from 'lodash'

@Injectable({
  providedIn: 'root'
})
export class MenuTreeService {

  currentName: string

  get listOfMenuTree() {
    return this._listOfMenuTree
  }

  private loading = false
  private _listOfMenuTree: MenuModel[] = []

  constructor(
    private setting: SettingsService,
    private httpMenu: HttpMenuService,
    private arraySrv: ArrayService,
    public translate: TranslateService
  ) { }

  async pullMenuTree(params, keys?: string[]) {
    const promise = this.httpMenu.getAllByTree(params).toPromise()
    this.loading = false
    const [res] = await asyncCatch(promise)
    this.loading = false
    const list = isResultFaild(res) ? null : [res.response][0]?.children
    this.arraySrv.visitTree(list, (node: MenuModel) => {
      this.translateName(node?.name)
      Object.assign(node, {
        title: this.currentName,
        key: node?.id,
        expanded: true,
        children: sortBy(node.children, t => t.sortOrder),
        checked: keys?.length ? keys.find(key => key === node.id) : false,
        isLeaf: !node?.children?.length
      })
    })
    this._listOfMenuTree = cloneDeep(list)
  }

  translateName(name) {
    this.translate.get(name).subscribe((res: string) => {
      this.currentName = res
    })
  }
}
