import { NgModule } from '@angular/core'
import { NzDropDownModule } from 'ng-zorro-antd/dropdown'

import { LayoutThemeBtnComponent } from './theme-btn.component'

@NgModule({
  exports: [LayoutThemeBtnComponent],
  declarations: [LayoutThemeBtnComponent],
  imports: [
    NzDropDownModule,
  ],
})
export class ThemeBtnModule {}
