import { Component, forwardRef, Injector, Input } from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { _HttpClient } from '@delon/theme'
import { SelectAsync } from '@public/components/select-async'
import { AccountModel, HttpAccount } from '@public/providers/http'
import { asyncCatch, deepGet, isResultFaild } from '@scaffold/methods'
import { differenceWith, uniqBy } from 'lodash'
import { NzSizeLDSType } from 'ng-zorro-antd/core/types'
import { InputBoolean } from 'ng-zorro-antd/core/util'

@Component({
  selector: 'app-ry-select',
  templateUrl: './ry-select.component.html',
  styleUrls: ['./ry-select.component.less'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RySelectComponent),
    multi: true
  }]
})
export class RySelectComponent extends SelectAsync<AccountModel, AccountModel> {

  /**
   * 显示监室号
   */
  @Input() @InputBoolean() showPrisonRoom = false

  /**
   * 查询人员参数
   */
  @Input() params

  /**
   * 排除 人员id集合
   */
  @Input() excludeIds: string[]

  @Input() set prisonRoom(prisonRoom) {
    this._prisonRoom = prisonRoom
    this.clean()
    this.loadMore()
    this.value = null
  }

  get prisonRoom() { return this._prisonRoom }

  _prisonRoom: string

  @Input() nzSize: NzSizeLDSType = 'default'

  constructor(
    injector: Injector,
    protected httpPrisoner: HttpAccount,
    protected http: _HttpClient
  ) { super(injector) }

  async updateValue(val: string | AccountModel) {
    // 如果传入的是id
    if (val !== '' && typeof val === 'string') {
      const _value: AccountModel = this.listOfOptions.find(ry => ry.id === val)
      val = _value || await this.getRyxxTotal(val)
    }

    // 查无此人
    if (!val) {
      this._value = null
      return
    }

    const list = [val, ...this.listOfOptions]
    this.listOfOptions = uniqBy(list, (ry: AccountModel) => ry.id)
  }

  private async getRyxxTotal(id: string) {
    const promise = this.httpPrisoner.getById(id).toPromise()
    const [res] = await asyncCatch(promise)
    return (isResultFaild(res) || !res.response)
      ? null
      : res.response
  }

  private getParams() {
    const { prisonRoom } = this
    return { prisonRoom, ...this.params }
  }

  handleFocus() {
    if (!this.listOfOptions) {
      this.loadMore()
    }
  }

  async getOptions(text: string): Promise<AccountModel[]> {
    this.http.end()
    this.loading = true
    const params = this.getParams()
    const promise = this.httpPrisoner.getAllByPage({ flag: 0, name: text, ...params, ...this.pagination }).toPromise()
    const [res] = await asyncCatch(promise)
    this.loading = false

    return isResultFaild(res)
      ? null
      : deepGet(res, 'response.records') || null
  }

  async loadMore(inputText = this._inputCache) {
    await super.loadMore(inputText)
    this.listOfOptions = differenceWith(this.listOfOptions, this.excludeIds, (({ id }, exId) => id === exId))
  }
}
