import { Injectable, Injector } from '@angular/core'
import { BaseApi, BaseUrl, Body, GET, Path, Payload, POST, PUT } from '@delon/theme'
import { IResult, IResultWithPagination, Model, PaginationParams } from '@public/interface'
import { Observable } from 'rxjs'

@BaseUrl('/limitAmountApplication')
@Injectable({
  providedIn: 'root'
})
export class HttpLimitAmountService extends BaseApi {

  constructor(injector: Injector) {
    super(injector)
  }

  @GET('/page')
  getAllByPage(@Payload body: PaginationParams & HttpLimitModel): Observable<IResultWithPagination<HttpLimitModel>> { return }

  @GET(':id')
  getById(@Path('id')id: string): Observable<IResult<HttpLimitModel>> { return }

  @POST()
  addLimitAccount(@Body limit: HttpLimitModel)
    : Observable<IResult<string>> { return }

  @PUT()
  editLimitAccount(@Body limit: HttpLimitModel)
    : Observable<IResult<HttpLimitModel>> { return }
}

export interface HttpLimitModel extends Model {
  /** 关联在押人id */
  accountId?: string

  /** 审批状态【0：待审批，1：同意，-1：拒绝】 */
  approveState?: 0 | 1 | -1

  /** 审批时间 */
  approveTime?: string

  /** 审批人 */
  approver?: string

  /** 申请时间 */
  createTime?: string

  /** 主键 */
  id?: string

  /** 0有效，1无效 */
  isDel?: 0 | 1

  /** 限制金额 */
  limitAmount?: string

  /** 申请人 */
  proposer?: string

  /** 申请理由 */
  reason?: string

  /** 修改时间 */
  updateTime?: string

  /** 监所编号 */
  prisonsNo?: string

  /** 审批意见 */
  opinions?: string
}
