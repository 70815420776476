import { Injectable } from '@angular/core'
import {
  NgxNotificationDirection,
  NgxNotificationMsgService,
  NgxNotificationStatusMsg
} from '@public/components/notification-msg'

@Injectable({ providedIn: 'root' })
export class MessageService extends NgxNotificationMsgService {
  success(title: string, content = '') {
    this._open(NgxNotificationStatusMsg.SUCCESS, title, content)
  }

  error(title: string, content = '') {
    this._open(NgxNotificationStatusMsg.FAILURE, title, content)
  }

  warning(title: string, content = '') {
    this._open(NgxNotificationStatusMsg.WARNING, title, content)
  }

  info(title: string, content = '') {
    this._open(NgxNotificationStatusMsg.INFO, title, content)
  }

  private _open(status, header, msg) {
    super.open({ status, header, msg, closeable: true, direction: NgxNotificationDirection.TOP_RIGHT, delay: 10000 })
  }
}
