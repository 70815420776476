import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { NgModule, Optional, Provider, SkipSelf } from '@angular/core'
import { SimpleInterceptor } from '@delon/auth'
import { throwIfAlreadyLoaded } from '@scaffold/methods/module-import-guard'
import { AuthInterceptor } from './auth-interceptor.service'
import { HandleErrorInterceptorService } from './handle-error-interceptor.service'
import { UnifyApiInterceptorService } from './unify-api-interceptor.service'

export const INTERCEPTOR_PROVIDES: Provider[] = [
  { provide: HTTP_INTERCEPTORS, useClass: SimpleInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HandleErrorInterceptorService, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: UnifyApiInterceptorService, multi: true },
]

@NgModule({
  providers: [
    ...INTERCEPTOR_PROVIDES,
  ],
})
export class HttpInterceptorModule {
  constructor(@Optional() @SkipSelf() parentModule: HttpInterceptorModule) {
    throwIfAlreadyLoaded(parentModule, 'HttpInterceptorModule')
  }
}
