import { concat, Observable, of, Subject } from 'rxjs'

export abstract class AsyncClass<T> {
  data$: Subject<T> = new Subject<T>()
  local = 1
  abstract data: T

  abstract sync(): Promise<T>

  getData$(): Observable<T> {
    return concat(
      ...(this.data ? [of(this.data)] : []),
      this.data$.asObservable(),
    )
  }
}
