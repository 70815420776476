import { Component, forwardRef, Injector, Input } from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { SelectAsync } from '@public/components/select-async'
import { GoodsModel, HttpStoreGood } from '@public/providers/http'
import { map } from 'rxjs/operators'

@Component({
  selector: 'app-goods-select',
  templateUrl: './goods-select.component.html',
  styleUrls: ['./goods-select.component.less'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => GoodsSelectComponent),
    multi: true
  }],
})
export class GoodsSelectComponent extends SelectAsync<GoodsModel, GoodsModel> {
  queryParams: { type?, state? } = { state: 1 }

  /** 0所内购物，1家属商城 */
  @Input() set type(n: 0 | 1) {
    this.value = null
    this.queryParams.type = n
    this.clean()
    this.loadMore()
  }

  constructor(injector: Injector, private httpStoreGood: HttpStoreGood) {
    super(injector)
  }

  async getOptions(text: string): Promise<GoodsModel[]> {
    return this.httpStoreGood.getAllByPage({ ...this.queryParams, ...this.pagination })
      .pipe(map(t => t.response?.records))
      .toPromise()
  }
}
