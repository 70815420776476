
export interface Categories {
  direction: string[]
  suggestion: string[]
  logo: string[]
  data: string[]
  edit: string[]
  other?: string[]
}

export const categories: Categories = {
  direction: [
    'step-backward',
    'step-forward',
    'fast-backward',
    'fast-forward',
    'shrink',
    'arrows-alt',
    'down',
    'up',
    'left',
    'right',
    'caret-up',
    'caret-down',
    'caret-left',
    'caret-right',
    'up-circle',
    'down-circle',
    'left-circle',
    'right-circle',
    'double-right',
    'double-left',
    'vertical-left',
    'vertical-right',
    'vertical-align-top',
    'vertical-align-middle',
    'vertical-align-bottom',
    'forward',
    'backward',
    'rollback',
    'enter',
    'retweet',
    'swap',
    'swap-left',
    'swap-right',
    'arrow-up',
    'arrow-down',
    'arrow-left',
    'arrow-right',
    'play-circle',
    'up-square',
    'down-square',
    'left-square',
    'right-square',
    'login',
    'logout',
    'menu-fold',
    'menu-unfold',
    'border-bottom',
    'border-horizontal',
    'border-inner',
    'border-outer',
    'border-left',
    'border-right',
    'border-top',
    'border-verticle',
    'pic-center',
    'pic-left',
    'pic-right',
    'radius-bottomleft',
    'radius-bottomright',
    'radius-upleft',
    'radius-upright',
    'fullscreen',
    'fullscreen-exit'
  ],
  suggestion: [
    'question',
    'question-circle',
    'plus',
    'plus-circle',
    'pause',
    'pause-circle',
    'minus',
    'minus-circle',
    'plus-square',
    'minus-square',
    'info',
    'info-circle',
    'exclamation',
    'exclamation-circle',
    'close',
    'close-circle',
    'close-square',
    'check',
    'check-circle',
    'check-square',
    'clock-circle',
    'warning',
    'issues-close',
    'stop'
  ],
  edit: [
    'edit',
    'form',
    'copy',
    'scissor',
    'delete',
    'snippets',
    'diff',
    'highlight',
    'align-center',
    'align-left',
    'align-right',
    'bg-colors',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'redo',
    'undo',
    'zoom-in',
    'zoom-out',
    'font-colors',
    'font-size',
    'line-height',
    'colum-height',
    'colum-width',
    'dash',
    'small-dash',
    'sort-ascending',
    'sort-descending',
    'drag',
    'ordered-list',
    'unordered-list',
    'radius-setting',
    'column-width'
  ],
  data: [
    'area-chart',
    'pie-chart',
    'bar-chart',
    'dot-chart',
    'line-chart',
    'radar-chart',
    'heat-map',
    'fall',
    'rise',
    'stock',
    'box-plot',
    'fund',
    'sliders'
  ],
  logo: [
    'android',
    'apple',
    'windows',
    'ie',
    'chrome',
    'github',
    'aliwangwang',
    'dingding',
    'weibo-square',
    'weibo-circle',
    'taobao-circle',
    'html5',
    'weibo',
    'twitter',
    'wechat',
    'youtube',
    'alipay-circle',
    'taobao',
    'skype',
    'qq',
    'medium-workmark',
    'gitlab',
    'medium',
    'linkedin',
    'google-plus',
    'dropbox',
    'facebook',
    'codepen',
    'code-sandbox',
    'code-sandbox-circle',
    'amazon',
    'google',
    'codepen-circle',
    'alipay',
    'ant-design',
    'ant-cloud',
    'aliyun',
    'zhihu',
    'slack',
    'slack-square',
    'behance',
    'behance-square',
    'dribbble',
    'dribbble-square',
    'instagram',
    'yuque',
    'alibaba',
    'yahoo',
    'reddit',
    'sketch'
  ]
}
export const ICON_ZH_CN = {
  chooseTheme: '选择图标主题风格',
  direction: '方向性图标',
  suggestion: '提示建议性图标',
  edit: '编辑类图标',
  data: '数据类图标',
  other: '网站通用图标',
  logo: '品牌和标识',
  search: '在此搜索图标，点击图标可复制代码'
}
