<nz-select class="d-inline-block" style="width: 100%;min-width: 120px"
           [nzDisabled]="disabled"
           [nzSize]="size"
           [nzMode]="model"
           nzShowSearch
           [(ngModel)]="value"
           (nzOnSearch)="input$.next($event)"
           [nzServerSearch]="true"
           (nzScrollToBottom)="loadMore()"
           nzPlaceHolder="监室号"
           nzDropdownMatchSelectWidth
           nzAllowClear>
  <nz-option *ngFor="let item of listOfOptions" [nzValue]="item.code" [nzLabel]="item.name" nzCustomContent>
    <div class="d-flex align-items-center">
      <span class="dm-option-prefix">{{item.code}}</span>
      <nz-divider nzType="vertical"></nz-divider>
      <span class="dm-option-content flex-grow-1" [title]="item.name">{{item.name}}</span>
    </div>
  </nz-option>
  <nz-option *ngIf="loading || firstLoading" nzDisabled nzCustomContent>
    <i nz-icon nzType="loading" class="loading-icon"></i> 加载中...
  </nz-option>
</nz-select>
