import { ChangeDetectorRef, Directive, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { ControlValueAccessor } from '@angular/forms'
import { MenuTreeService } from '@public/components/menu-tree/menu-tree.service'
import { NzTreeNodeOptions } from 'ng-zorro-antd/core/tree'
import { NzSizeLDSType } from 'ng-zorro-antd/core/types'
import { InputBoolean, toArray } from 'ng-zorro-antd/core/util'
import { NzTreeComponent } from 'ng-zorro-antd/tree'

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class MenuTreeClass implements OnInit, ControlValueAccessor {

  @Input() size: NzSizeLDSType
  @Input() params = ''
  @Input() menuIds: string[] = []
  @Input() @InputBoolean() showCheckable = false
  @Input() @InputBoolean() showExpandAll = false
  @Input() @InputBoolean() showSearch = false
  @ViewChild('menuTree', { static: false }) menuTree!: NzTreeComponent
  @Output() actived: EventEmitter<NzTreeNodeOptions | NzTreeNodeOptions[]>
    = new EventEmitter<NzTreeNodeOptions | NzTreeNodeOptions[]>()
  currentNodes: NzTreeNodeOptions | NzTreeNodeOptions[]

  get listOfMenuTree() {
    return this.srv.listOfMenuTree
  }

  get value(): string | string[] {
    return this._value
  }

  set value(value: string | string[]) {
    this._value = value

    if (typeof this._change === 'function') {
      this._change(value)
    }
  }

  get nzCheckedKeys(): string[] {
    return this._nzCheckedKeys
  }

  set nzCheckedKeys(keys) {
    this._nzCheckedKeys = keys
  }

  private _nzCheckedKeys: string[] = []

  private _value: string | string[]
  private _change: (...arg) => any
  private _touch: (...arg) => any

  constructor(private cdr: ChangeDetectorRef, private srv: MenuTreeService) { }

  ngOnInit() {
    this.init()
  }

  async init() {
    await this.srv.pullMenuTree({ appId: this.params }, this.menuIds ? this.menuIds : [])
    this.nzCheckedKeys = toArray(this.menuIds)
    this.cdr.markForCheck()
  }

  modalChange(e: string | string[]) {
    const nodes = toArray<string>(e)
    this.currentNodes = []
    nodes.forEach(
      node => {
        const { origin } = this.menuTree.getTreeNodeByKey(node)
        this.currentNodes.push(origin)
      }
    )
    this.actived.emit(this.currentNodes)
  }

  currentData(e) {
    this.actived.emit(e.node?.origin)
  }

  registerOnChange(fn: any): void {
    this._change = fn
  }

  registerOnTouched(fn: any): void {
    this._touch = fn
  }

  writeValue(value: string | string[]): void {
    this._value = value
  }

  chean() {
    this._value = ''
  }
}
