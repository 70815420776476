import { DragDropModule } from '@angular/cdk/drag-drop'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { TDelayModule } from '@scaffold/components/delay'
import { TFileManagerModule } from '@scaffold/components/file-manager'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzCardModule } from 'ng-zorro-antd/card'
import { NzDropDownModule } from 'ng-zorro-antd/dropdown'
import { NzGridModule } from 'ng-zorro-antd/grid'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { NzTreeModule } from 'ng-zorro-antd/tree'
import { ImgComponent } from './img.component'
import { ImgDirective } from './img.directive'

@NgModule({
  declarations: [ImgComponent, ImgDirective],
  exports: [ImgComponent, ImgDirective],
  imports: [
    CommonModule,
    FormsModule,
    TFileManagerModule,
    NzGridModule,
    NzTreeModule,
    NzDropDownModule,
    NzInputModule,
    NzSelectModule,
    TDelayModule,
    NzCardModule,
    NzButtonModule,
    DragDropModule,
    NzIconModule
  ],
})
export class TImgModule {
}
