import { Component, forwardRef, Input } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { aesEncode } from '@public/methods/aes'
import { AsyncCurrentUserService } from '@scaffold/core'
import { NzSizeLDSType } from 'ng-zorro-antd/core/types'
import { InputBoolean } from 'ng-zorro-antd/core/util'

@Component({
  selector: 'app-jail-select',
  templateUrl: './jail-select.component.html',
  styleUrls: ['./jail-select.component.less'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => JailSelectComponent),
    multi: true
  }]
})
export class JailSelectComponent implements ControlValueAccessor {

  @Input() @InputBoolean() showPrison = false
  @Input() nzSize: NzSizeLDSType = 'default'

  @Input() set prison(prison) {
    this._prison = prison
    if (typeof this._onChange === 'function') {
      this._onChange(prison)
    }
  }

  get prison() {
    return this._prison
  }

  params
  _prison: { prisonsNo: string, prisonRoom: string } = { prisonsNo: '', prisonRoom: '' }
  parentCode: string
  disabled: boolean
  _onChange: (value) => any
  _onTouched: (value) => any

  constructor(private currentUser: AsyncCurrentUserService) {
    this.prison = { prisonsNo: aesEncode(this.currentUser.data?.prisonsNo), prisonRoom: '' }
  }

  writeValue(value): void {
    this.prison = value
  }

  registerOnChange(fn: (value) => any): void {
    this._onChange = fn
  }

  registerOnTouched(fn: () => any): void {
    this._onTouched = fn
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  changeJQ(e: string) {
    this.parentCode = e
    this.prison = { prisonsNo: aesEncode(this.currentUser.data?.prisonsNo), prisonRoom: e }
  }

  changeJsh(e: string) {
    if (e) {
      this._prison.prisonRoom = e
    }
  }
}
