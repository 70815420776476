import Base64 from 'compact-base64'

export function authBase64Encode(username, password, ...ext: any[]) {
  const extText = ext ? '|' + ext.join('|') : ''
  const text = `${username}|${password}${extText}`
  const encodeText = Base64.encode(text)
  console.log(`用户登录 => ${text}`)
  console.log(`ENCODE => ${encodeText}`)
  return encodeText
}
