import { Color } from '@public/interface'

export const PASS_API_KEY = '_allow_anonymous'
export const PASS_API = { }
PASS_API[PASS_API_KEY] = true

export const COLOR: Color[] = [
  'pink',
  'red',
  'yellow',
  'orange',
  'cyan',
  'green',
  'blue',
  'purple',
  'geekblue',
  'magenta',
  'volcano',
  'gold',
  'lime',
]
