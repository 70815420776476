import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { NzDividerModule } from 'ng-zorro-antd/divider'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { GoodsCategorySelectorComponent } from './goods-category-selector.component'

@NgModule({
  declarations: [GoodsCategorySelectorComponent],
  exports: [GoodsCategorySelectorComponent],
  imports: [CommonModule, NzSelectModule, NzIconModule, FormsModule, NzDividerModule]
})
export class GoodsCategorySelectorModule {}
