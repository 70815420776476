import { Component } from '@angular/core'
import { ControlWidget } from '@delon/form'

@Component({
  selector: 'app-sf-code-select',
  templateUrl: './code-select.component.html',
})
export class CodeSelectComponent extends ControlWidget {

  reset(value: string) {
  }

  change(value: string) {
    if (this.ui.change) {
      this.ui.change(value)
    }
    this.setValue(value)
  }

}
