import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { LayoutProLogoComponent } from './logo.component'

@NgModule({
  declarations: [LayoutProLogoComponent],
  exports: [LayoutProLogoComponent],
  imports: [
    CommonModule,
    RouterModule,
  ],
})
export class LogoModule {}
