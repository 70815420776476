<nz-select style="min-width: 140px" nzDropdownMatchSelectWidth [nzCustomTemplate]="tpl" [(ngModel)]="value">
  <ng-template #tpl let-selected>
    <div class="d-flex align-items-center">
      <div class="color-box mr-md rounded-sm" style="width: 16px;height: 16px" [style.background]="selected.nzValue"></div>
      <span>{{selected.nzLabel}}</span>
    </div>
  </ng-template>
  <nz-option *ngFor="let item of colorList" nzCustomContent [nzValue]="item.color" [nzLabel]="item.text">
    <div class="d-flex align-items-center">
      <div class="color-box mr-md rounded-sm" style="width: 20px;height: 20px" [style.background]="item.color"></div>
      <span>{{item.text}}</span>
    </div>
  </nz-option>
</nz-select>
