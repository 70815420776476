export const GLOBAL_ENVIRONMENT_PROD = {

  // SERVER_URL: '',
  SERVER_URL: 'http://101.132.135.127:9001/capitalSupervisionSystem',
  SOCKET_URL: 'http://101.132.135.127:8009',
  hmr: false,
  production: false,
  useHash: true,

  layoutConfig: {
    colorWeak: false,
    theme: 'dark',
    menu: 'side',
    contentWidth: 'fluid',
    fixedHeader: false,
    autoHideHeader: true,
    fixSiderbar: true,
    onlyIcon: false,
    fixed: true,
    collapsed: false,
    boxed: false,
    lang: null
  },

  reuseConfig: {
    keepingScroll: false,
    fixTabs: [
      {
        title: { text: '工作台' },
        closable: false,
        position: null,
        url: '/dashboard',
        _snapshot: null,
        _handle: null
      }]
  }
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
