<div nz-dropdown [nzDropdownMenu]="userMenu" nzPlacement="bottomRight" class="alain-pro__header-item" nzTrigger="click">
  <nz-avatar nzIcon="user" nzSize="small" class="mr-sm"></nz-avatar>
  {{ currentUser?.data?.name }}
</div>
<nz-dropdown-menu #userMenu="nzDropdownMenu">
  <div nz-menu class="width-md">
    <div class="d-flex p-sm" style="line-height: 1">
      <nz-avatar nzIcon="user" nzSize="large" class="bg-primary"></nz-avatar>
      <div class="flex-1 ml-sm">
        <h3 class="mb-xs">{{currentUser?.data?.name}}</h3>
        <div class="d-flex flex-wrap">
          <div class="mt-xs p-xs border-1 rounded-md mr-xs" *ngFor="let role of (currentUser?.data?.roles)">{{role.name}}</div>
        </div>
      </div>
    </div>
    <div nz-menu-divider></div>
    <div nz-menu-item>
      <header-fullscreen></header-fullscreen>
    </div>

    <div nz-menu-item>
      <header-storage></header-storage>
    </div>

    <div nz-menu-item>
      <header-change-password></header-change-password>
    </div>
    <li nz-menu-divider></li>
    <div nz-menu-item>
      <button nz-button nzBlock [nzLoading]="logouting" nzType="danger" (click)="logout()">
        <i nz-icon nzType="logout" class="mr-sm"></i>
        {{ 'menu.account.logout' | translate }}
      </button>
    </div>
  </div>
</nz-dropdown-menu>
