import { HttpClient } from '@angular/common/http'
import { LOCALE_ID, NgModule } from '@angular/core'
import { ALAIN_I18N_TOKEN, DELON_LOCALE, zh_CN as delonLang } from '@delon/theme'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { I18NService } from '@scaffold/providers/i18n'

import { default as ngLang } from '@angular/common/locales/zh'
import { zhCN as dateLang } from 'date-fns/locale'
import { NZ_DATE_LOCALE, NZ_I18N, zh_CN as zorroLang } from 'ng-zorro-antd/i18n'

const LANG = {
  abbr: 'zh',
  ng: ngLang,
  zorro: zorroLang,
  date: dateLang,
  delon: delonLang,
}
// register angular
import { registerLocaleData } from '@angular/common'

registerLocaleData(LANG.ng, LANG.abbr)

const LANG_PROVIDES = [
  { provide: LOCALE_ID, useValue: LANG.abbr },
  { provide: NZ_I18N, useValue: LANG.zorro },
  { provide: NZ_DATE_LOCALE, useValue: LANG.date },
  { provide: DELON_LOCALE, useValue: LANG.delon },
]
// #endregion

// 加载i18n语言文件
export function I18nHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, `assets/i18n/`, '.json')
}

const I18N_SERVICE_MODULES = [
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: I18nHttpLoaderFactory,
      deps: [HttpClient],
    },
  }),
]

const I18N_SERVICE_PROVIDES = [{ provide: ALAIN_I18N_TOKEN, useClass: I18NService, multi: false }]

// #endregion
@NgModule({
  imports: [...I18N_SERVICE_MODULES],
  providers: [...I18N_SERVICE_PROVIDES, ...LANG_PROVIDES],
})
export class I18nModule {
}
