import { Inject, Injectable } from '@angular/core'
import { CacheService } from '@delon/cache'
import { ENVIRONMENTS } from 'yqs-environments'

@Injectable({ providedIn: 'root' })
export class ImageUploadService {

  constructor(@Inject(ENVIRONMENTS) private env,
              protected cache: CacheService) { }

  getImageUrl(uid: string) {
    const prefix = this.cache.getNone('FILE_URL') || this.env.SERVER_URL
    return `${prefix}/images/${uid}`
  }
}
