import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { EllipsisModule } from '@delon/abc'
import { JshSelectModule } from '@public/components/jsh-select/jsh-select.module'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { RySelectObjComponent } from './ry-select-obj.component'

@NgModule({
  declarations: [RySelectObjComponent],
  exports: [
    RySelectObjComponent
  ],
  imports: [
    CommonModule,
    NzInputModule,
    JshSelectModule,
    FormsModule,
    NzSelectModule,
    EllipsisModule,
    NzIconModule
  ]
})
export class RySelectObjModule {}
