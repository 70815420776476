import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { Nz2UploadModule } from '@scaffold/components/upload'
import { NzEmptyModule } from 'ng-zorro-antd/empty'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzModalModule } from 'ng-zorro-antd/modal'
import { NzUploadModule } from 'ng-zorro-antd/upload'
import { ImageUploadBase64Component } from './image-upload-base64.component'
import { ImageComponent } from './image.component'
import { ImagePipe } from './image.pipe'

@NgModule({
  declarations: [ImageUploadBase64Component, ImageComponent, ImagePipe],
  exports: [ImageUploadBase64Component, ImageComponent, ImagePipe],
  imports: [
    CommonModule,
    NzIconModule,
    NzEmptyModule,
    NzModalModule,
    Nz2UploadModule,
    NzUploadModule
  ],
})
export class ImageUploadBase64Module {}
