import { Injectable, Injector } from '@angular/core'
import { BaseApi, BaseUrl, GET, Payload } from '@delon/theme'
import { IResult, Model } from '@public/interface'
import { Observable } from 'rxjs'

@BaseUrl('/user/role')
@Injectable({
  providedIn: 'root'
})
export class HttpRoleService extends BaseApi {

  constructor(injector: Injector) { super(injector) }

  @GET('/tree/new')
  getAllByTree(@Payload body: RoleTreeModel): Observable<IResult<RoleTreeModel[]>> { return }
}

export interface RoleTreeModel {
  key?: string
  level?: number
  origin: RoleModel
  title?: string
  type?: string
  children?: RoleTreeModel[]
}

export interface RoleModel extends Model {
  /** 英文编码 */
  code?: string

  /** 等级 */
  level?: string

  /** 权限名称 */
  name?: string

  /** 上级id */
  parentId?: string

  /** 监所id */
  prisonsNo?: string

  /** 备注 */
  remark?: string

  /** 状态，0为未启用，1为启用 */
  state?: string

  children?: RoleModel[]
}
