import { Component } from '@angular/core'
import { ControlWidget } from '@delon/form'

@Component({
  selector: 'rmb',
  template: `
    <sf-item-wrap
      [id]="id"
      [schema]="schema"
      [ui]="ui"
      [showError]="showError"
      [error]="error"
      [showTitle]="schema.title"
    >
      <app-input-rmb [ngModel]="value" (ngModelChange)="_change($event)"></app-input-rmb>
    </sf-item-wrap>
  `,
  preserveWhitespaces: false,
})
// tslint:disable-next-line: component-class-suffix
export class RmbWidget extends ControlWidget {
  static readonly KEY = 'rmb'

  _change(value: string) {
    this.setValue(value)

    try {
      this.ui.change(value)
    } catch (e) {

    }
  }
}
