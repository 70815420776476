import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { SchemaModule } from '@public/json-schema'
import { CoreModule } from '@scaffold/core'
import { YqsEnvironmentsModule } from 'yqs-environments'
import { environment } from '../environments/environment'

import { PAGES_ROUTES } from '../routes/page-routes'
import { AppComponent } from './app.component'

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    SchemaModule.forRoot(),
    YqsEnvironmentsModule.forRoot(environment),
    RouterModule.forRoot(PAGES_ROUTES, {
      useHash: environment.useHash,
      scrollPositionRestoration: 'disabled',
    }),
    ReactiveFormsModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
