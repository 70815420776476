<notice-icon
  btnClass="alain-pro__header-item"
  btnIconClass="alain-pro__header-item-icon"
  [data]="data"
  [count]="count"
  [loading]="loading"
  (select)="select($event)"
  (clear)="clear($event)"
  (popoverVisibleChange)="loadData()"
></notice-icon>
