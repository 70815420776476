import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { AlainThemeModule } from '@delon/theme'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzDropDownModule } from 'ng-zorro-antd/dropdown'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzModalModule } from 'ng-zorro-antd/modal'
import { NzPaginationModule } from 'ng-zorro-antd/pagination'
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm'
import { NzSpinModule } from 'ng-zorro-antd/spin'
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select'
import { NzUploadModule } from 'ng-zorro-antd/upload'
import { FileManagerComponent } from './file-manager.component'

@NgModule({
  declarations: [FileManagerComponent],
  exports: [FileManagerComponent],
  imports: [
    CommonModule,
    NzUploadModule,
    NzButtonModule,
    NzIconModule,
    NzSpinModule,
    NzDropDownModule,
    NzPopconfirmModule,
    NzPaginationModule,
    NzModalModule,
    NzInputModule,
    FormsModule,
    NzTreeSelectModule,
    AlainThemeModule,
  ],
})
export class TFileManagerModule {
}
