import { Component, forwardRef, Injector, Input } from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { SelectAsync } from '@public/components/select-async'
import { HttpPrisonRoom, PrisonRoomModel } from '@public/providers/http'
import { NzSelectModeType } from 'ng-zorro-antd/select/select.types'
import { map } from 'rxjs/operators'

@Component({
  selector: 'app-jsh-select',
  templateUrl: './jsh-select.component.html',
  styleUrls: ['./jsh-select.component.less'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => JshSelectComponent),
    multi: true
  }]
})
export class JshSelectComponent extends SelectAsync<string, PrisonRoomModel> {

  @Input()
  model: NzSelectModeType = 'default'

  constructor(
    inject: Injector,
    private httpJshSelect: HttpPrisonRoom,
  ) {
    super(inject)
  }

  async getOptions(name: string): Promise<PrisonRoomModel[]> {
    return this.httpJshSelect.pagePrisonRoom({ ...this.pagination, name, sort: 'code', order: 'asc' })
      .pipe(map(t => t.response?.records))
      .toPromise()
  }

  async updateValue(value): Promise<void> {
    super.updateValue(value)
  }

}
