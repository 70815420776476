import { NgModule } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'

import { NzIconModule } from 'ng-zorro-antd/icon'
import { HeaderFullScreenComponent } from './fullscreen.component'

@NgModule({
  imports: [
    NzIconModule,
    TranslateModule
  ],
  exports: [HeaderFullScreenComponent],
  declarations: [HeaderFullScreenComponent],
  providers: [],
})
export class FullscreenModule {}
