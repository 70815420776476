import { Injectable } from '@angular/core'
import { SettingsService } from '@delon/theme'
import { ArrayService } from '@delon/util'
import { HttpAccount, HttpPrison, PrisonModel } from '@public/providers/http'
import { asyncCatch, isResultFaild, paginationFactory } from '@scaffold/methods'
import { cloneDeep } from 'lodash'

@Injectable({
  providedIn: 'root'
})
export class OriginTreeService {

  get listOfOrignTree() {
    return this._listOfOrignTree
  }

  get listOfPrisoners() {
    return this._listOfPrisoners
  }

  private loading = false
  private _listOfOrignTree = []
  private _listOfPrisoners

  constructor(
    private setting: SettingsService,
    private httpOrigin: HttpPrison,
    private httpAccount: HttpAccount,
    private arraySrv: ArrayService
  ) { }

  async pullOrignTree(params) {
    const promise = this.httpOrigin.getOrignTree(params).toPromise()
    this.loading = false
    const [res] = await asyncCatch(promise)
    this.loading = false
    const list = isResultFaild(res) ? null : res.response
    this.arraySrv.visitTree(list.allTree, (node: PrisonModel) => {
      Object.assign(node, { title: node?.name, key: node?.code, expanded: node.type === 3 })
    })

    this._listOfOrignTree = cloneDeep(list.allTree)
  }

  async getPrisonersByRoom(prisonsNo: string, prisonRoom: string, origin: string = '') {
    const pagination = paginationFactory()
    pagination.limit = 9999

    origin = `5^${prisonsNo},${prisonRoom}`

    const promise = this.httpAccount.getAllByPage({ origin, ...pagination }).toPromise()
    const [res] = await asyncCatch(promise)
    this._listOfPrisoners = isResultFaild(res) ? null : res.response?.records

    this._listOfPrisoners?.forEach(
      item => Object.assign(item, {
        key: item.id,
        title: item.name,
        type: 6,
        isLeaf: true
      }))
  }
}
