import { Directive, Injector, Input, OnChanges, SimpleChanges } from '@angular/core'
import { deepGet } from '@delon/util'
import { CodeModel, HttpCodeService } from '@public/providers/http'
import { asyncCatch, isResultFaild } from '@scaffold/methods'
import { SelectAsync } from './select-async'

@Directive()
export class SelectDm<T> extends SelectAsync<T, CodeModel> implements OnChanges {

  @Input() indexId

  public httpCode: HttpCodeService

  constructor(injector: Injector) {
    super(injector)
    this.httpCode = injector.get(HttpCodeService)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.indexId) {
      this.clean()
      this.loadMore()
      this.value = null
    }
  }

  public async getOptions(mhcx: string): Promise<CodeModel[]> {
    const { indexId } = this
    if (!indexId) { return }

    const promise = this.httpCode.getAllByPage({
      ...this.pagination,
      mhcx,
      indexId,
    }).toPromise()
    const [res] = await asyncCatch(promise)
    this.loading = false

    return isResultFaild(res)
      ? null
      : deepGet(res, 'response.records') || null
  }

  writeValue(value: T) {
    this._value = value
  }
}
