import { NzSafeAny } from 'ng-zorro-antd/core/types'

/**
 * 类似 `_.get`，根据 `path` 获取安全值
 * @author: jsperf https://jsperf.com/es-deep-getttps://jsperf.com/es-deep-get
 *
 * @param obj 数据源，无效时直接返回 `defaultValue` 值
 * @param path 若 `null`、`[]`、未定义及未找到时返回 `defaultValue` 值
 * @param defaultValue 默认值
 */

export function deepGet(obj: NzSafeAny | null, path: string | string[] | null | undefined, defaultValue?: NzSafeAny): NzSafeAny {
  if (!obj || path == null || path.length === 0) {
    return defaultValue
  }
  if (!Array.isArray(path)) {
    // tslint:disable-next-line:no-bitwise
    path = ~path.indexOf('.') ? path.split('.') : [path]
  }
  if (path.length === 1) {
    const checkObj = obj[path[0]]
    return typeof checkObj === 'undefined' ? defaultValue : checkObj
  }
  const res = path.reduce((o, k) => (o || {})[k], obj)
  return typeof res === 'undefined' ? defaultValue : res
}
