import { Injectable, Injector } from '@angular/core'
import { BaseApi, BaseUrl, DELETE, GET, Path, Payload, POST } from '@delon/theme'
import { IResult, IResultWithPagination, Model, PaginationParams } from '@public/interface'
import { Observable } from 'rxjs'

@BaseUrl('/withdrawal')
@Injectable({
  providedIn: 'root'
})
export class HttpWithdrawalService extends BaseApi {

  constructor(injector: Injector) { super(injector) }

  @GET('/page')
  getAllByPage(@Payload body: WithdrawalModel & PaginationParams): Observable<IResult<WithdrawalModel>> { return }

  @GET('/getCode')
  getCode(@Payload body): Observable<IResult<any>> { return }

  @GET('/listCode')
  getCodeList(@Payload body): Observable<IResult<any>> { return }

  @GET('/reconciliation/list')
  getReconciliation(@Payload body): Observable<IResult<any>> { return }

  @GET('/familyMemberTransfer/list')
  familyMemberTransfer(@Payload body): Observable<IResult<any>> { return }

  @GET('/logo')
  getLogByPage(@Payload body: WithdrawalModel & PaginationParams): Observable<IResultWithPagination<WithdrawalModel>> { return }

  @POST()
  add(@Payload body: WithdrawalModel): Observable<IResult<WithdrawalModel>> { return }

  @DELETE(':id')
  delete(@Path('id') id: string): Observable<IResult<WithdrawalModel>> { return }
}

export interface WithdrawalModel extends Model {
  /** 在押人id */
  accountId?: string

  /** 金额 */
  money?: number

  /** 二维码图片链接 */
  base64?: string

  /** 制作人id ，也就是操作人 */
  userId?: string

  time?: string

  userName?: string

  accountName?
  digitalWallet?
  digitalWalletPassword?
  failureTime?
}
