import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { InputBoolean } from '@delon/util'
import { getBase64 } from '@public/methods/get-base64'
import { MessageService as NzMessageService } from '@public/providers'
import { InputNumber } from 'ng-zorro-antd/core/util'
import { NzI18nService } from 'ng-zorro-antd/i18n'
import { NzUploadFile } from 'ng-zorro-antd/upload'
import { Observable, Observer } from 'rxjs'
import { ImageUploadService } from './image-upload.service'
import { Category } from './interface'

@Component({
  selector: 'app-image-upload',
  template: `
      <nz2-upload
              class="avatar-uploader"
              nzAction="/upload"
              nzName="file"
              nzListType="picture-card"
              [(nzFileList)]="listOfFile"
              [nzData]="extraData"
              [nzBeforeUpload]="beforeUpload"
              [nzShowButton]="listOfFile.length < multiple"
              (nzChange)="handleChange($event)"
              [nzRemove]="remove"
              [nzPreview]="handlePreview"
              [nzDownload]="download"
              [nzShowUploadList]="{showPreviewIcon:showPreview,showDownloadIcon:showDownload,showRemoveIcon:showRemove}"
      >
          <i class="upload-icon" nz-icon [nzType]="loading ? 'loading' : 'plus'"></i>
          <div class="ant-upload-text">上传图片</div>
      </nz2-upload>

      <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
                (nzOnCancel)="previewVisible = false">
          <ng-template #modalContent>
              <img [src]="previewImage" [ngStyle]="{ width: '100%' }"/>
          </ng-template>
      </nz-modal>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageUploadComponent),
      multi: true
    },
    ImageUploadService,
    NzI18nService
  ]
})
export class ImageUploadComponent implements ControlValueAccessor {

  get extraData() {
    const { cat, id } = this
    return { cat, id }
  }

  constructor(private msg: NzMessageService,
              private srv: ImageUploadService) {}

  @Input() @InputBoolean() showPreview = true
  @Input() @InputBoolean() showDownload = true
  @Input() @InputBoolean() showRemove = true
  @Input() cat: Category = 1
  @Input() id: any = null
  @Input() @InputNumber() multiple = 1
  @Output() active: EventEmitter<{ status: string, url?: any }> = new EventEmitter<{ status: string, url?: any }>()

  loading = false

  private _changeFn: (...arg: any[]) => any
  private _touchFn: (...arg: any[]) => any
  listOfFile: NzUploadFile[] = []
  previewVisible: boolean
  previewImage: string

  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]) => {
    return new Observable((observer: Observer<boolean>) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf'
      if (!isJpgOrPng) {
        this.msg.error('只能上传 jpg / png / pdf 图片！')
        observer.complete()
        return
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.msg.error('图片不能高于 2MB')
        observer.complete()
        return
      }
      observer.next(isJpgOrPng && isLt2M)
      observer.complete()
    })
  }
  remove = (file: NzUploadFile) => {
    this.listOfFile = this.listOfFile.filter(t => t.uid !== file.uid)
    this.valueChange()
    return true
  }
  handlePreview = async (file: NzUploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    this.previewImage = file.url || file.preview
    this.previewVisible = true
  }

  private getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result.toString()))
    reader.readAsDataURL(img)
  }

  handleChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.loading = true
        break
      case 'done':
        this.getBase64(info.file.originFileObj, (img: string) => {
          this.loading = false
          this.valueChange()
        })
        break
      case 'error':
        this.msg.error('网络错误，上传失败！')
        this.loading = false
        this.active.emit({ status: 'error' })
        break
    }
  }

  registerOnChange(fn): void {
    this._changeFn = fn
  }

  writeValue(value: string | string[]): void {
    if (!value) {
      this.listOfFile = []
      return
    }
    this.listOfFile = (Array.isArray(value) ? value : [value])
      .filter(Boolean)
      .map(uid => this.getUploadFileByUid(uid))
  }

  registerOnTouched(fn: any): void {
    this._touchFn = fn
  }

  getUploadFileByUid(uid): NzUploadFile {
    return uid
      ? ({
        uid,
        name: uid,
        url: this.srv.getImageUrl(uid)
      })
      : null

  }

  private valueChange() {
    const list = this.listOfFile.map(file => file?.response?.response || file.uid)
    const value = this.multiple === 1 ? list[0] : list
    if (typeof this._changeFn === 'function') {
      this._changeFn(value)
    }
    this.active.emit(value)
  }

  download = async (file: NzUploadFile) => {
    await this.getUid(file)
  }

  getUid(file) {
    const { uid } = file
    const url = this.srv.getImageUrl(uid)
    const a = document.createElement('a')         // 创建一个a节点插入的document
    const event = new MouseEvent('click')           // 模拟鼠标click点击事件
    a.download = 'mochu_img'                      // 设置a节点的download属性值
    a.href = url                                 // 将图片的src赋值给a节点的href
    a.dispatchEvent(event)
    window.URL.revokeObjectURL(a.href) // 释放内存
  }

}
