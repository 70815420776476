import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { NzDropDownModule } from 'ng-zorro-antd/dropdown'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { LangsComponent } from './langs.component'

@NgModule({
  declarations: [LangsComponent],
  exports: [LangsComponent],
  imports: [
    CommonModule,
    NzDropDownModule,
    NzIconModule,
  ],
})
export class TLangsModule {}
