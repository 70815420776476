import Base64 from 'compact-base64'

/** */
export function authEncode({ username, password }) {
  const text = `${username}|${password}`
  const encodeText = Base64.encode(text)

  console.log(`用户登录 => ${text}`)
  console.log(`ENCODE => ${encodeText}`)
  return encodeText
}
