import { AbstractControl, FormGroup } from '@angular/forms'

export function deepCheckFormControls(controls: { [key: string]: AbstractControl }) {
  Object.keys(controls).map(key => {
    const body = controls[key]
    if (body instanceof FormGroup) {
      deepCheckFormControls(body.controls)
    } else {
      controls[key].markAsDirty()
      controls[key].updateValueAndValidity()
    }
  })
}
