import { Directive, Inject, Injector, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { ReuseTabMatchMode, ReuseTabService } from '@delon/abc/reuse-tab'
import { TitleService } from '@delon/theme'
import { IEnvironments } from '@environments'
import { NzModalService } from 'ng-zorro-antd/modal'
import { filter } from 'rxjs/operators'
import { ENVIRONMENTS } from 'yqs-environments'

@Directive()
export class App implements OnInit {
  constructor(
    @Inject(Router) private router: Router,
    @Inject(TitleService) private titleSrv: TitleService,
    @Inject(NzModalService) private modalSrv: NzModalService,
    @Inject(ReuseTabService) private reuseTabService: ReuseTabService,
    @Inject(ENVIRONMENTS) private env: IEnvironments,
  ) {
    // 路由复用
    reuseTabService.mode = ReuseTabMatchMode.Menu
    reuseTabService.keepingScroll = false
    reuseTabService.items.push(...env.reuseConfig.fixTabs)
    reuseTabService.change
      .pipe(filter(t => t
        && t.active === 'add'
        && env.reuseConfig
        && env.reuseConfig.fixTabs
        && env.reuseConfig.fixTabs.some(c => (t.url === c.url))))
      .subscribe(t => t.closable = false)
    reuseTabService.init()
  }

  ngOnInit() {
    this.router.events.pipe(filter((evt) => evt instanceof NavigationEnd)).subscribe(() => {
      this.titleSrv.setTitle()
      this.modalSrv.closeAll()
    })
  }
}
