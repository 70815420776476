import { paginationFactory } from './pagination-factory'

const { limit: _limit } = paginationFactory()

export function getPageIndex(offset, limit = _limit) {
  return (offset - 1) / limit + 1
}

export function getPageOffset(pageIndex, pageSize = _limit) {
  return (pageIndex - 1) * pageSize + 1
}
