import { Component, forwardRef, Injector, OnInit } from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { SelectAsync } from '@public/components/select-async'
import { HttpPrison, PrisonModel } from '@public/providers/http'
import { map } from 'rxjs/operators'

@Component({
  selector: 'app-prison-select',
  templateUrl: './prison-select.component.html',
  styleUrls: ['./prison-select.component.less'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PrisonSelectComponent),
    multi: true
  }]
})
export class PrisonSelectComponent extends SelectAsync<PrisonModel, PrisonModel> implements OnInit {

  constructor(injector: Injector, private httpPrisonSelect: HttpPrison) { super(injector) }

  ngOnInit(): void {
    super.ngOnInit()
    this.loadMore()
  }

  async getOptions(text: string): Promise<PrisonModel[]> {
    return this.httpPrisonSelect.getAll({ name: '看守所' })
      .pipe(map(t => t.response))
      .toPromise()
  }

  async updateValue(value): Promise<void> {
    super.updateValue(value)
  }

}
