import { Injectable, Injector } from '@angular/core'
import { BaseApi, BaseUrl, GET, Payload, PUT } from '@delon/theme'
import { IResult } from '@public/interface'
import { TreeModel } from '@public/providers/http/http-user-role.service'
import { Observable } from 'rxjs'

@BaseUrl('/familymeeting')
@Injectable({
  providedIn: 'root'
})
export class HttpStateService extends BaseApi {

  constructor(injector: Injector) {
    super(injector)
  }

  @GET('/list')
  getAll(@Payload params: StateModel): Observable<IResult<StateModel[]>> { return }

  @PUT()
  modify(@Payload id: string): Observable<IResult<StateModel[]>> { return }

}

export interface StateModel extends TreeModel {
  // 1家属会见 2转赠
  type: string
  // id
  id: string
  // isDel
  isDel: 0 | 1
  // 0关闭 1 打开
  closeStatus: 0 | 1
  // 创建时间
  createTime: string
  // 监所编号
  prisonsNo: string
  // 修改时间
  updateTime: string
}
