import { Component, forwardRef, Injector, OnInit } from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { SelectAsync } from '@public/components/select-async'
import { AccountModel, HttpCodeService } from '@public/providers/http'
import { asyncCatch, isResultFaild } from '@scaffold/methods'

@Component({
  selector: 'app-financial-running-water',
  templateUrl: './financial-running-water.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FinancialRunningWaterComponent),
    multi: true
  }]
})

export class FinancialRunningWaterComponent extends SelectAsync<string, AccountModel> implements OnInit {

  listData: any[] = []

  constructor(private httpCodeService: HttpCodeService,
              injector: Injector) {
    super(injector)
  }

  ngOnInit() {}

  async getOptions(): Promise<any> {
    this.loading = true
    const promise = this.httpCodeService.getAll({ indexId: '67' }).toPromise()
    const [res] = await asyncCatch(promise)

    if (isResultFaild(res)) {
      return
    }
    this.listData = res?.response
    this.loading = false
  }

  handleFocus() {
    if (!this.listData || this.listData && this.listData.length <= 0) {
      this.loadMore().then()
    }
  }
}
