import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { MenuTreeClass } from '@public/components/menu-tree/menu-tree.class'

@Component({
  selector: 'app-menu-tree-select',
  template: `
      <nz-tree-select
              #menuTree
              nzPlaceHolder="请选择"
              [(ngModel)]="value"
              [nzNodes]="listOfMenuTree"
              [nzDefaultExpandAll]="showExpandAll"
              [nzAsyncData]="true"
              (ngModelChange)="modalChange($event)"
              [nzCheckable]="showCheckable"
              [nzShowSearch]="showSearch"
              [nzDropdownMatchSelectWidth]="true"
              [nzDropdownStyle]="{'max-height':'300px','width':'300px'}"
      >
      </nz-tree-select>

  `,
  styleUrls: ['./menu-tree.component.less'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MenuTreeSelectComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuTreeSelectComponent extends MenuTreeClass {
}
