import { enableProdMode, ViewEncapsulation } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { preloaderFinished } from '@delon/theme'

const host = (appModule) => platformBrowserDynamic()
  .bootstrapModule(appModule, {
    defaultEncapsulation: ViewEncapsulation.Emulated,
    preserveWhitespaces: false,
  })
  .then(res => {
    if ((window as any).appBootstrap) {
      (window as any).appBootstrap()
    }
    return res
  })

export function bootstrap(appModule, environment) {

  preloaderFinished()

  if (environment.production) {
    enableProdMode()
  }
  host(appModule)
}
