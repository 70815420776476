import { BreakpointObserver, MediaMatcher } from '@angular/cdk/layout'
import { DOCUMENT } from '@angular/common'
import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core'
import { NavigationEnd, NavigationError, RouteConfigLoadStart, Router } from '@angular/router'
import { ReuseTabService } from '@delon/abc/reuse-tab'
import { ScrollService } from '@delon/theme'
import { updateHostClass } from '@delon/util'
import { IEnvironments } from '@environments'
import { NzMessageService } from 'ng-zorro-antd/message'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { ENVIRONMENTS } from 'yqs-environments'

import { BrandService } from './layout.service'

@Component({
  selector: 'layout-pro',
  templateUrl: './layout.component.html',
})
export class LayoutProComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>()
  private queryCls: string

  isFetching = false

  get isMobile() {
    return this.pro.isMobile
  }

  get getLayoutStyle() {
    const { isMobile, fixSiderbar, collapsed, menu, width, widthInCollapsed } = this.pro
    if (fixSiderbar && menu !== 'top' && !isMobile) {
      return {
        paddingLeft: (collapsed ? widthInCollapsed : width) + 'px',
      }
    }
    return null
  }

  get getContentStyle() {
    const { fixedHeader, headerHeight } = this.pro
    return {
      margin: '24px 24px 0',
      'padding-top': (fixedHeader ? headerHeight : 0) + 'px',
    }
  }

  private get body(): HTMLElement {
    return this.doc.body
  }

  constructor(
    bm: BreakpointObserver,
    mediaMatcher: MediaMatcher,
    router: Router,
    msg: NzMessageService,
    scroll: ScrollService,
    reuseTabSrv: ReuseTabService,
    private resolver: ComponentFactoryResolver,
    private el: ElementRef,
    private renderer: Renderer2,
    public pro: BrandService,
    @Inject(ENVIRONMENTS) public env: IEnvironments,
    @Inject(DOCUMENT) private doc: any, // private cdr: ChangeDetectorRef
  ) {
    // scroll to top in change page
    router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((evt) => {
      if (!this.isFetching && evt instanceof RouteConfigLoadStart) {
        this.isFetching = true
        scroll.scrollToTop()
      }
      if (evt instanceof NavigationError) {
        this.isFetching = false
        msg.error(`无法加载${evt.url}路由`, { nzDuration: 1000 * 3 })
        return
      }
      if (!(evt instanceof NavigationEnd)) {
        return
      }
      this.isFetching = false
      // If have already cached router, should be don't need scroll to top
      if (!reuseTabSrv.exists(evt.url)) {
        scroll.scrollToTop()
      }
    })

    // media
    const query = {
      'screen-xs': '(max-width: 575px)',
      'screen-sm': '(min-width: 576px) and (max-width: 767px)',
      'screen-md': '(min-width: 768px) and (max-width: 991px)',
      'screen-lg': '(min-width: 992px) and (max-width: 1199px)',
      'screen-xl': '(min-width: 1200px)',
    }
    bm.observe([
      '(min-width: 1200px)',
      '(min-width: 992px) and (max-width: 1199px)',
      '(min-width: 768px) and (max-width: 991px)',
      '(min-width: 576px) and (max-width: 767px)',
      '(max-width: 575px)',
    ]).subscribe(() => {
      this.queryCls = Object.keys(query).find((key) => mediaMatcher.matchMedia(query[key]).matches)
      this.setClass()
    })
  }

  private setClass() {
    const { body, renderer, queryCls, pro } = this
    updateHostClass(body, renderer, {
      ['color-weak']: pro.layout.colorWeak,
      [`layout-fixed`]: pro.layout.fixed,
      [`aside-collapsed`]: pro.collapsed,
      ['alain-pro']: true,
      [queryCls]: true,
      [`alain-pro__content-${pro.layout.contentWidth}`]: true,
      [`alain-pro__fixed`]: pro.layout.fixedHeader,
      [`alain-pro__wide`]: pro.isFixed,
      [`alain-pro__dark`]: pro.theme === 'dark',
      [`alain-pro__light`]: pro.theme === 'light',
      [`alain-pro__menu-side`]: pro.isSideMenu,
      [`alain-pro__menu-top`]: pro.isTopMenu,
    })
  }

  ngOnInit() {
    const { pro, unsubscribe$ } = this
    pro.notify.pipe(takeUntil(unsubscribe$)).subscribe(() => {
      this.setClass()
    })
  }

  ngOnDestroy() {
    const { unsubscribe$, body, pro } = this
    unsubscribe$.next()
    unsubscribe$.complete()
    body.classList.remove(
      `alain-pro__content-${pro.layout.contentWidth}`,
      `alain-pro__fixed`,
      `alain-pro__wide`,
      `alain-pro__dark`,
      `alain-pro__light`,
    )
  }
}
