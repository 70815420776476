import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzTreeModule } from 'ng-zorro-antd/tree'
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select'
import { OriginTreeSelectComponent } from './origin-tree-select.component'
import { OriginTreeComponent } from './origin-tree.component'

@NgModule({
  declarations: [OriginTreeComponent, OriginTreeSelectComponent],
  exports: [OriginTreeComponent, OriginTreeSelectComponent],
  imports: [
    CommonModule,
    NzTreeModule,
    NzTreeSelectModule,
    FormsModule,
    NzIconModule
  ]
})
export class OriginTreeModule {}
