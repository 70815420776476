import { Injectable } from '@angular/core'
import { SettingsService, TitleService, User, _HttpClient } from '@delon/theme'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { AsyncClass } from './async.class'

@Injectable({ providedIn: 'root' })
export class AsyncAppDataService extends AsyncClass<AppData> {
  get data(): AppData {
    return this._data
  }

  set data(data: AppData) {
    this._data = data
    this.settingService.setApp(data)

    // application data
    // const res: any = appData;
    // 应用信息：包括站点名、描述、年份
    // this.settingService.setApp(APP_CONFIG);
    // 用户信息：包括姓名、头像、邮箱地址
    // this.settingService.setUser(user);
    // ACL：设置权限为全量
    // this.aclService.setFull(true);
    // 初始化菜单
    // this.menuService.add(res.menu);
    // 设置页面标题的后缀
    this.titleService.default = ''
    this.titleService.suffix = data?.name

    this.data$.next(data)
  }

  private _data: AppData

  constructor(protected http: _HttpClient,
              private titleService: TitleService,
              protected settingService: SettingsService) {
    super()
  }

  async sync(): Promise<AppData> {
    return this.http
      .get('assets/data/app-data.json', { _quiet_: 1, _local_: this.local })
      .pipe(tap(data => this.data = data))
      .toPromise()
  }
}

export interface AppData extends User {
  name: string
  supperTitle: string
  description: string
  version: string
  home: string
  login: string

  [key: string]: any
}
