import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { EllipsisModule } from '@delon/abc'
import { JshSelectModule } from '@public/components/jsh-select/jsh-select.module'
import { RySelectComponent } from '@public/components/ry-select/ry-select.component'
import { NzNoAnimationModule } from 'ng-zorro-antd/core/no-animation'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzSelectModule } from 'ng-zorro-antd/select'

@NgModule({
  declarations: [RySelectComponent],
  exports: [
    RySelectComponent
  ],
  imports: [
    CommonModule,
    NzSelectModule,
    FormsModule,
    NzInputModule,
    JshSelectModule,
    EllipsisModule,
    NzIconModule,
    NzNoAnimationModule
  ]
})
export class RySelectModule {}
