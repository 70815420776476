import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'
import { ChangePasswordComponent } from '@scaffold/layout/components/change-password/change-password.component'
import { NzAlertModule } from 'ng-zorro-antd/alert'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzFormModule } from 'ng-zorro-antd/form'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzResultModule } from 'ng-zorro-antd/result'

@NgModule({
  imports: [
    NzIconModule,
    TranslateModule,
    CommonModule,
    NzFormModule,
    NzInputModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzAlertModule,
    NzResultModule
  ],
  providers: [],
  declarations: [ ChangePasswordComponent ],
  exports: [ ChangePasswordComponent ]
})
export class ChangePasswordModule {}
