<nz-select class="d-inline-block" style="width: 100%;min-width: 120px;"
           nzShowSearch
           [nzDisabled]="disabled"
           [nzSize]="size"
           [(ngModel)]="value"
           (nzScrollToBottom)="loadMore()"
           nzPlaceHolder="请选择商品"
           nzDropdownMatchSelectWidth
           nzAllowClear>
  <nz-option *ngFor="let item of listOfOptions" [nzValue]="item.id" [nzLabel]="item.name" nzCustomContent>
    <div class="d-flex align-items-center">
      <span class="option-prefix">
        <app-image [uid]="item?.imgs" width="60" height="60"></app-image>
      </span>
      <nz-divider nzType="vertical"></nz-divider>
      <span class="option-content flex-grow-1" [title]="item.name">{{item.name}}</span>
    </div>
  </nz-option>

  <nz-option *ngIf="loading" nzDisabled nzCustomContent>
    <i nz-icon nzType="loading" class="loading-icon"></i> 加载中...
  </nz-option>
</nz-select>
