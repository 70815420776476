import { HttpClientModule } from '@angular/common/http'
import { NgModule, Optional, SkipSelf } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { StartupModule } from '@scaffold/core/startup/startup.module'
import { I18nModule } from '@scaffold/i18n'
import { IconsModule } from '@scaffold/icons/icons.module'
import { JsonSchemaModule } from '@scaffold/json-schema'
import { throwIfAlreadyLoaded } from '@scaffold/methods/module-import-guard'
import { NzDrawerModule } from 'ng-zorro-antd/drawer'
import { NzMessageModule } from 'ng-zorro-antd/message'
import { NzModalModule } from 'ng-zorro-antd/modal'
import { NzNotificationModule } from 'ng-zorro-antd/notification'
import { GlobalConfigModule } from './global-config.module'
import { HttpInterceptorModule } from './net'

const REG_MODULES = [
  BrowserModule,
  BrowserAnimationsModule,
  HttpClientModule,
  NzMessageModule,
  NzModalModule,
  NzDrawerModule,
  NzNotificationModule,

  GlobalConfigModule.forRoot(),
  HttpInterceptorModule,
  StartupModule,
  I18nModule,
  IconsModule,
  JsonSchemaModule,
]

@NgModule({
  providers: [],
  imports: [...REG_MODULES],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule')
  }
}
