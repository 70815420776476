import { Injectable, Injector } from '@angular/core'
import { BaseApi, BaseUrl, Body, GET, Path, Payload, POST, PUT, _HttpClient } from '@delon/theme'
import { IResult, IResultWithPagination, Model, PaginationParams } from '@public/interface'
import { Observable } from 'rxjs'

@BaseUrl('/prisonRoom')
@Injectable({
  providedIn: 'root'
})
export class HttpPrisonRoom extends BaseApi {

  constructor(injector: Injector, protected http: _HttpClient) {
    super(injector)
  }

  @GET('/page')
  getAllByPage(@Payload body: PaginationParams & PrisonRoomModel)
    : Observable<IResultWithPagination<PrisonRoomModel>> { return }

  @GET(':id')
  get(@Path('id') id): Observable<IResult<PrisonRoomModel>> { return }

  @POST('/add')
  add(@Body room: PrisonRoomModel): Observable<IResult<PrisonRoomModel>> { return }

  @POST('/init')
  init(@Body room: PrisonRoomModel): Observable<IResult<PrisonRoomModel>> { return }

  @PUT()
  edit(@Body room: PrisonRoomModel): Observable<IResult<PrisonRoomModel>> { return }

  delete(ids: (string | number)[]): Observable<PrisonRoomModel> {
    return this.http
      .request('DELETE', '/prisonRoom', { body: ids }) as
      Observable<PrisonRoomModel>
  }

  @GET('/pagePrisonRoom')
  pagePrisonRoom(@Payload body: PaginationParams & { name? }): Observable<IResultWithPagination<PrisonRoomModel>> { return }
}

export interface PrisonRoomModel extends Model {
  /** 监室（区）编号 */
  code?: string

  /** 监室（区）名称 */
  name?: string

  /** 拼音字头 */
  phoneticPrefix?: string

  /** 监所编号 */
  prisonsNo?: string

  /** 类型 */
  type?: string

  /** 父编号 */
  parentCode?: string
}
