import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { GlobalFooterModule } from '@delon/abc/global-footer'
import { TranslateModule } from '@ngx-translate/core'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { LayoutProFooterComponent } from './footer.component'

@NgModule({
  declarations: [LayoutProFooterComponent],
  exports: [LayoutProFooterComponent],
  imports: [
    CommonModule,
    NzIconModule,
    TranslateModule,
    GlobalFooterModule,
  ],
})
export class FooterModule {}
