import { GLOBAL_ENVIRONMENT_PROD } from './environments.prod'

export const GLOBAL_ENVIRONMENT = {
  ...GLOBAL_ENVIRONMENT_PROD,

  SERVER_URL: '//101.132.135.127:9001/capitalSupervisionSystem',
  production: false,
  useHash: true,

}
