import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter, forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { RoleTreeService } from '@public/components/role-tree/role-tree.service'
import { NzTreeNodeOptions } from 'ng-zorro-antd/core/tree'
import { NzSizeLDSType } from 'ng-zorro-antd/core/types'
import { InputBoolean, toArray } from 'ng-zorro-antd/core/util'
import { NzTreeComponent } from 'ng-zorro-antd/tree'

@Component({
  selector: 'app-role-tree',
  templateUrl: './role-tree.component.html',
  styleUrls: ['./role-tree.component.less'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RoleTreeComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleTreeComponent implements OnInit, ControlValueAccessor {
  @Input() size: NzSizeLDSType
  @Input() params = ''
  @Input() @InputBoolean() showSearch = false
  @Input() @InputBoolean() showCheckable = false
  @ViewChild('roleTree', { static: false }) roleTree!: NzTreeComponent
  @Output() active: EventEmitter<NzTreeNodeOptions | NzTreeNodeOptions[]>
    = new EventEmitter<NzTreeNodeOptions | NzTreeNodeOptions[]>()

  get listOfTree() {
    return this.srv.listOfTree
  }

  get value(): string | string[] {
    return this._value
  }

  set value(value: string | string[]) {
    /* this._value = value*/

    if (typeof this._change === 'function') {
      this._change(value)
    }
  }

  currentNodes: NzTreeNodeOptions[]
  private _value
  private _change: (...arg) => any
  private _touch: (...arg) => any

  constructor(private srv: RoleTreeService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.init()
  }

  async init() {
    await this.srv.pullRoleTree({})
    this.cdr.markForCheck()
  }

  modalChange(e: string) {
    const es = toArray<string>(e)
    this.currentNodes = []
    es?.forEach(node => {
      const { origin } = this.roleTree.getTreeNodeByKey(node)
      this.currentNodes.push(origin)
    })
    this.active.emit(this.currentNodes)
  }

  currentData(e) {
    this.active.emit(e.node)
  }

  registerOnChange(fn: any): void {
    this._change = fn
  }

  registerOnTouched(fn: any): void {
    this._touch = fn
  }

  writeValue(value: string | string[]): void {
    this.chean()
    this._value = value
  }

  chean() {
    this._value = ''
  }
}
