import { Injectable } from '@angular/core'
import { BaseApi, BaseUrl, Body, DELETE, GET, Path, Payload, POST, PUT } from '@delon/theme'
import { IResult, IResultWithPagination, Model, PaginationBody, PaginationParams } from '@public/interface'
import { Observable } from 'rxjs'
import { PrisonModel } from './http-prison.service'

/**
 * 代购申请
 * T Agency Purchase Controller
 */
@BaseUrl('/agencypurchase')
@Injectable({ providedIn: 'root' })
export class HttpAgencypurchase extends BaseApi {

  @GET()
  /** 获取所有代购申请 */
  getAllByPage(@Payload body): Observable<IResult<PaginationBody<AgencypurchaseModel>>> { return }

  @GET('/doctorList')
  getDoctorListByPage(@Payload body): Observable<IResult<PaginationBody<AgencypurchaseModel>>> { return }

  @PUT('/approve')
  approve(@Body body): Observable<IResult> { return }

  @PUT('/doctorApprove')
  doctorApprove(@Body body): Observable<IResult> { return }

  @GET('prison/all')
  getPrison(@Payload body: PaginationParams): Observable<IResult<PrisonModel[]>> { return }

  @GET(':id')
  getById(@Path('id')id): Observable<IResultWithPagination<AgencypurchaseModel>> { return }

  @POST()
  add(@Payload body: AgencypurchaseModel): Observable<IResult<AgencypurchaseModel>> { return }

  @PUT()
  edit(@Payload body: AgencypurchaseModel): Observable<IResult<AgencypurchaseModel>> { return }

  @PUT('/payApply')
  payApply(@Payload body: AgencypurchaseModel): Observable<IResult<AgencypurchaseModel>> { return }

  @PUT('/financeConfirm')
  financeConfirm(@Payload body: AgencypurchaseModel): Observable<IResult<AgencypurchaseModel>> { return }

  @DELETE('id')
  delete(@Path('id')id): Observable<IResult<AgencypurchaseModel>> { return }
}

export interface AgencypurchaseModel extends Model {
  /** 关联在押人id */
  accountId?: string

  /** 实际价 */
  actualPrice?: string

  /** 代购时间 */
  agencyTime?: string

  /** 申请详情 */
  applicationDetails?: string

  /** 申请理由 */
  applicationReason?: string

  /** 审批状态 0未审批 1审批通过 2拒绝 */
  approvalStatus?: 0 | 1 | 2

  /** 审批状态 中文 */
  approvalStatusName?: string

  /** 审批人id */
  approverId?: string

  /** 审批意见 */
  approverOpinion?: string

  /** 审批时间 */
  approverTime?: string

  /** 开始时间 */
  startDate?: string

  /** 结束时间 */
  endDate?: string

  /** 预估价 */
  futurePrices?: string

  /** 订单编号 */
  orderNumber?: string

  /** 商品名称 */
  productName?: string

  /** 商品数量 */
  productNum?: number

  /** 申请人id */
  proposerId?: string

  /** 回执状态 0未回执 1已回执 */
  receiptStatus?: number
}

export interface AgencypurchasePageParams extends PaginationParams {
  /** 代购开始时间 */
  startDate
  /** 代购结束时间 */
  endDate
  /** 商品名称 */
  productName
  /** 回执状态 0未填，1已填 */
  receiptStatus
  /** 在押人员id */
  accountId
  /** 审批状态 0未审批 1审批通过 2拒绝 */
  approvalStatus
  /** 监室号 */
  prisonRoom
  /** 监所号 */
  prisonNo
  /** 订单编号 */
  orderNumber
}
