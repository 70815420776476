import { Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { ImageUploadService } from './image-upload.service'

@Pipe({ name: 'imageUrl' })
export class ImagePipe implements PipeTransform {

  constructor(private srv: ImageUploadService, private sanitization: DomSanitizer) {}

  transform(str: string) {
    return this.srv.getImageUrl(str)
  }

}
