import { Injectable, Injector } from '@angular/core'
import { BaseApi, BaseUrl, Body, GET, Path, Payload, POST, PUT, _HttpClient } from '@delon/theme'
import { Model, PaginationParams } from '@public/interface'
import { IResult } from '@scaffold/interface/result.interface'
import { Observable } from 'rxjs'

@BaseUrl('/prison')
@Injectable({
  providedIn: 'root'
})
export class HttpPrison extends BaseApi {

  constructor(injector: Injector, protected http: _HttpClient) {
    super(injector)
  }

  @GET('/orgnizationTree')
  getOrignTree(@Payload body?: PaginationParams): Observable<IResult<TreeModel<PrisonModel>>> { return }

  @GET('/all')
  getAll(@Payload body: { name: string })
    : Observable<IResult<PrisonModel[]>> { return }

  @GET('/all')
  getAllByPage(@Payload body: PaginationParams)
    : Observable<IResult<PrisonModel[]>> { return }

  @GET(':id')
  get(@Path('id')id: number): Observable<IResult<PrisonModel>> { return }

  @POST()
  add(@Body body: PrisonModel): Observable<IResult<PrisonModel>> { return }

  @PUT()
  edit(@Body body: PrisonModel): Observable<IResult<PrisonModel>> { return }

  delete(ids: (string | number)[]): Observable<PrisonModel> {
    return this.http.request('DELETE', '/prison', { body: ids }) as any
  }
}

export interface PrisonModel extends Model {
  /** 城市编码 */
  cityCode?: string

  /** 监所编码 */
  code?: string

  /** 限制金额 */
  limitAmount?: number

  /** 监所名称 */
  name?: string

  /** 状态，0为未启用，1为启用 */
  state?: number

  type?: number

  prisonsNo?: string

  children?: PrisonModel[]
}

export interface TreeModel<T> {
  allTree?: T[]
}
