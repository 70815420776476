import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ScrollbarDirective } from './scrollbar.directive'

@NgModule({
  declarations: [ScrollbarDirective],
  exports: [ScrollbarDirective],
  imports: [
    CommonModule,
  ],
})
export class TScrollbarModule {}
