import { NgModule, Optional, SkipSelf } from '@angular/core'
import { throwIfAlreadyLoaded } from '@scaffold/methods/module-import-guard'
import { IconService } from './icon.service'

@NgModule({ providers: [] })
export class IconsModule {
  constructor(@Optional() @SkipSelf() parentModule: IconsModule, icon: IconService) {
    icon.startup()
    throwIfAlreadyLoaded(parentModule, 'IconsModule')
  }
}
