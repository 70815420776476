// Custom icon static resources
import { IconDefinition } from '@ant-design/icons-angular'

// 引入全部的图标
import * as AllIcons from '@ant-design/icons-angular/icons'

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
}
export const ICONS_ANTDESIGN: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])
