import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { HttpWebLawyerUserService } from '@public/providers/http/http-web-lawyer-user.service'
import { asyncCatch, deepCheckFormControls, isResultFaild } from '@scaffold/methods'
import { Auth } from '@scaffold/providers'
import { NzModalService } from 'ng-zorro-antd/modal'

@Component({
  selector: 'header-change-password',
  templateUrl: './change-password.component.html',
  styles: [`:host {
    display: block
  }`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ChangePasswordComponent {

  form: FormGroup
  tplModalButtonLoading = false
  loginDone = false
  error = ''

  constructor(private modalSrv: NzModalService,
              private http: HttpWebLawyerUserService,
              protected fb: FormBuilder,
              private auth: Auth) {
    this.form = this.fb.group({
      originPwd: ['', [ Validators.required ] ],
      newPwd: '',
      newConfirmPwd: ''
    })
  }

  createTplModal(tplContent: TemplateRef<{}>) {
    this.modalSrv.create({
      nzTitle: '修改密码',
      nzContent: tplContent,
      nzFooter: null,
    })
  }

  async submit() {
    const { originPwd, newPwd, newConfirmPwd } = this.form?.value

    deepCheckFormControls(this.form?.controls)

    if (!this.form?.valid) {
      return
    }

    if (originPwd && newPwd && originPwd === newPwd) {
      this.error = '新密码不能与旧密码相同'
      return
    }

    if (newPwd && newConfirmPwd && newPwd !== newConfirmPwd) {
      this.error = '新密码两次输入的不一样'
      return
    }
    this.tplModalButtonLoading = true
    const promise = this.http.changePwd({ originPwd, newPwd }).toPromise()
    this.tplModalButtonLoading = false
    const [res] = await asyncCatch(promise)

    if (isResultFaild(res)) {
      this.error = res.msg || '密码输入错误'
      return
    } else {
      this.loginDone = true
      await this.auth.logout()
      return
    }
  }
}
