import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { ColorPickerComponent } from './color-picker.component'

@NgModule({
  declarations: [ColorPickerComponent],
  exports: [
    ColorPickerComponent
  ],
  imports: [
    CommonModule,
    NzSelectModule,
    FormsModule
  ]
})
export class ColorPickerModule {}
